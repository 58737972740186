import React from "react";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const CustomToggleButtonGroup = ({ name, id, value, onChange }, context) => {
  const handleToggleChange = (e, updatedValue) => {
    onChange(updatedValue);
  };

  return (
    <ToggleButtonGroup
      id={id}
      name={name}
      color="primary"
      value={value}
      exclusive
      onChange={handleToggleChange}
      style={{ height: "30px" }}
    >
      <ToggleButton
        value="not yet"
        style={
          value == "not yet" ? { backgroundColor: "grey", color: "white" } : {}
        }
      >
        <strong>{context.t("Not yet initiated")}</strong>
      </ToggleButton>
      <ToggleButton
        value="partial progress"
        style={
          value == "partial progress"
            ? { backgroundColor: "yellow", color: "black" }
            : {}
        }
      >
        <strong>{context.t("Partial Progress")}</strong>
      </ToggleButton>
      <ToggleButton
        value="completed"
        style={
          value == "completed"
            ? { backgroundColor: "green", color: "white" }
            : {}
        }
      >
        <strong>{context.t("Completed/ongoing")}</strong>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CustomToggleButtonGroup;

CustomToggleButtonGroup.contextTypes = {
  t: PropTypes.func.isRequired,
};
