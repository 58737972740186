import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DonutImpact from "components/Charts/DonutChart.js";
import { makeStyles } from "@material-ui/core/styles";
import "./css/style1.css";
import HttpService from "services/HttpService.js";
import loginStore from "redux/stores/LoginStore.js";
import { Container } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch } from "react-redux";
import { fetchWorkPlanData } from "redux/actions/SpWorkplanFormAction.js";
import Utils from "services/utils.js";
import { Tooltip, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CustomSingleBar from "components/barchart/CustomSingleBar.js";
import IndicatorInfo from "components/Indicator/IndicatorInfo.js";
import axios from "axios";
import C3Chart from "react-c3js";

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
  topHeading: {
    borderRadius: "0",
    backgroundColor: "#999999",
    color: "#fff",
    fontWeight: "500",
    padding: "18px",
    width: "100%",
  },
  theadtext: {
    color: "black",
    fontWeight: "600",
    fontSize: "16px",
  },
  gaugeContainer: {
    textAlign: "center",
    justifyContent: "center",
    height: "180px",
    display: "flex",
  },
  gaugeBox: {
    minHeight: "10rem",
    justifyContent: "center",
    margin: "10px",
    boxShadow: "0px 1px 5px #d1d1d1",
  },
  notes: {
    width: "100%",
    minHeight: "150px",
    background: "whitesmoke",
    borderRadius: "11px",
    padding: "11px",
  },
  gaugeTableText: {
    fontSize: "13px",
    fontWeight: 500,
    marginTop: "-30px",
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    margin: 10,
  },
});

// TODO: Move to utils
const colorScheme = {
  POOR: '#a3199c',
  FAIR: '#fdc161',
  GOOD: '#a6dfdd',
  EXCELLENT: '#148696',
  NOT_YET: '#b7b7b7',
  PROGRESS: '#ffe599',
  COMPLETE: '#148696',
};

export default function SPProgressDashboard(props, context) {
  let _user = loginStore.getLoginUser();
  const dispatch = useDispatch();
  const workPlanItem = useSelector((state) => state.workplanReducer.item);
  const [chartData, setChartData] = useState([]);
  const [workPlanData, setWorkPlanData] = useState({});
  const [actualChartData, setActualChartData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [progressIndicatorData, setProgressIndicatorData] = useState([]);
  const [activeSuppliers, setActiveSuppliers] = useState({});
  const [wvChartData, setWvChartData] = useState({});
  const [supplierPerformance, setSupplierPerformance] = useState({});
  const [issuesData, setIssuesData] = useState({});
  const [reimbursementData, setReimbursementData] = useState({});

  const [openTooltips, setOpenTooltips] = useState({
    timeliness: false,
    quality: false,
    openness: false,
  });

  // TODO: FIND A BETTER WAY TO ORGANIZE THIS DATA
  const onePointOne =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "1.1");

  const onePointTwo =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "1.2" || item.description.includes("1.2"));

  const onePointThree =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "1.3" || item.description.includes("1.3"));

  const onePointFour =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "1.4" || item.description.includes("1.4"));

  const onePointFive =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "1.5" || item.description.includes("1.5"));

  const twoPointOne =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.1" || item.description.includes("2.1"));

  const twoPointTwo =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.2" || item.description.includes("2.2"));

  const twoPointThree =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.3" || item.description.includes("2.3"));

  const twoPointFour =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.4" || item.description.includes("2.4"));

  const twoPointFive =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.5" || item.description.includes("2.5"));

  const twoPointSix =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.6" || item.description.includes("2.6"));

  const twoPointSeven =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.7" || item.description.includes("2.7"));

  const twoPointEight =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.8" || item.description.includes("2.8"));

  const twoPointTen =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "2.10" || item.description.includes("2.10"));

  const threePointOne =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.1" || item.description.includes("3.1"));

  const threePointTwo =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.2" || item.description.includes("3.2"));

  const threePointThree =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.3" || item.description.includes("3.3"));

  const threePointFour =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.4" || item.description.includes("3.4"));

  const threePointFive =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.5" || item.description.includes("3.5"));

  const threePointSix =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.6" || item.description.includes("3.6"));

  const threePointSeven =
    activitiesData?.length > 0 &&
    activitiesData.filter((item) => item.groupName === "3.7" || item.description.includes("3.7"));

  // TODO: FIX THESE VALUES!!!!
  // const threePointEightA =
  //   activitiesData?.length > 0 &&
  //   activitiesData.filter((item) => item.groupName === "3.8-a" || item.description.includes("3.8-a"));

  // const threePointEightB =
  //   activitiesData?.length > 0 &&
  //   activitiesData.filter((item) => item.groupName === "3.8-b" || item.description.includes("3.8-b"));

  const threePointEightA = [{
    progress: "not_started",
    description: "3.8a Encourage and support suppliers in having management and leadership participate in Issara Academy trainings and understanding their role in ethical grievance mechanisms and remediation, as well as ongoing coaching and capacity building",
  }];
  const threePointEightB = [{
    progress: "not_started",
    description: "3.8b Conduct iterative analyses and targeted improvements of systems related to management of living/working conditions, including all human resources processes, grievance mechanisms, bonus and incentive systems within production, and dorm and canteen conditions",
  }];



  const handleTooltipOpen = (field) => {
    setOpenTooltips((prev) => ({ ...prev, [field]: true }));
    setTimeout(() => {
      setOpenTooltips((prev) => ({ ...prev, [field]: false }));
    }, 5000);
  };

  useEffect(() => {
    !workPlanItem.loading &&
      !workPlanItem.fetched &&
      dispatch(fetchWorkPlanData());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          suppliersResponse,
          wvChartResponse,
          supplierPerformanceResponse,
          issuesResponse,
          reimbursementResponse,
        ] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/suppliers/`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/wv-bar-chart/`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/supplier-performances/`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/issues/`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/worker-reimbursement/`
          ),
        ]);

        setActiveSuppliers(suppliersResponse.data);
        setWvChartData(wvChartResponse.data);
        setSupplierPerformance(supplierPerformanceResponse.data);
        setIssuesData(issuesResponse.data);
        setReimbursementData(reimbursementResponse.data);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (workPlanItem.fetched) {
      setWorkPlanData(workPlanItem?.data);
    }
  }, [workPlanItem]);

  useEffect(() => {
    if (workPlanData) {
      setProgressIndicatorData(workPlanData.progress_indicator);
      setActivitiesData(
        Utils.flattenSPAcitivitesDataArray(workPlanData.priority_activity)
      );
    }
  }, [workPlanData]);

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/activity_progress/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        setActivitiesData(Utils.flattenSPAcitivitesDataArray(response.results));
      },
      (err) => {
        console.log('Error fetching activity progress metrics:', err.message);
      }
    );
    setChartData([]);
    setActualChartData([]);
  }, [props.partner]);

  let newupdatearray = [];
  if (activitiesData?.length > 0) {
    activitiesData.map((item, index) => {
      if (item.value === true) {
        newupdatearray.push(item);
      }
    });
  }

  newupdatearray = newupdatearray.sort((a, b) => {
    if (a.priority_activity_id < b.priority_activity_id) {
      return -1;
    }
  });

  const classes = useStyles();
  const [internationalStandards, setInternationalStandards] = useState(false);
  const [communicatedToSupp, setCommunicatedToSupp] = useState(false);
  const [commercialProcess, setCommercialProcess] = useState(false);
  const [wcInternationalSnD, setWcInternationalSnD] = useState(false);
  const [wcCommunicatedToS, setWcCommunicatedToS] = useState(false);
  const [wcCommercialProcesses, setWcCommercialProcesses] = useState(false);
  const [supplierCRC, setSupplierCRC] = useState();
  const [strategicPartner, setStrategicPartner] = useState(null);

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        if (response.hasOwnProperty("Result")) {
        } else {
          setStrategicPartner(response.strategic_partner);
          setChartData(response);
          for (let i = 0; i < response.key_activities.length; i++) {
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "In line with international standards and definitions"
            ) {
              setInternationalStandards(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "Clearly communicated to suppliers, including effective date"
            ) {
              setCommunicatedToSupp(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "Integrated into commercial processes"
            ) {
              setCommercialProcess(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "In line with international standards and definitions"
            ) {
              setWcInternationalSnD(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "Clearly communicated to suppliers"
            ) {
              setWcCommunicatedToS(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "Integrated into commercial processes"
            ) {
              setWcCommercialProcesses(response.key_activities[i].value);
            }
          }
        }
      },
      (err) => {
        console.log('Error fetching SP workplan:', err.message);
      }
    );
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/all_cases/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        setActualChartData(response);
      },
      (err) => {
        console.log('Error fetching all cases:', err.message);
      }
    );
  }, [props.partner]);

  useEffect(() => {
    if (strategicPartner !== null) {
      HttpService.get(
        `${process.env.REACT_APP_API_URL}/strategicpartners/${
          _user.partner != null ? _user.partner : props.partner
        }/supplier_crc_score/`,
        (response) => {
          setSupplierCRC(response);
        },
        (err) => {
          console.log('Error fetching strategic partners:', err.message);
        }
      );
    }
  }, [strategicPartner]);

  const [progressOne, setProgressOne] = useState();
  const [progressTwo, setProgressTwo] = useState();

  useEffect(() => {
    // ========== AREA 2. LABOUR RECRUITMENT ER INDICATOR 1. dynamic bottom strings =================
    if (
      (internationalStandards && communicatedToSupp && !commercialProcess) ||
      (internationalStandards && !communicatedToSupp && !commercialProcess) ||
      (!internationalStandards && !communicatedToSupp && !commercialProcess) ||
      (internationalStandards && communicatedToSupp && commercialProcess)
    ) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
    if (
      (!internationalStandards && communicatedToSupp && commercialProcess) ||
      (!internationalStandards && communicatedToSupp && !commercialProcess)
    ) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }
    if (!internationalStandards && !communicatedToSupp && commercialProcess) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
        </>
      );
    }
    if (internationalStandards && !communicatedToSupp && commercialProcess) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
        </>
      );
    }
    if (!internationalStandards && communicatedToSupp && !commercialProcess) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }

    // ============ AREA 3. WORKING CONDITIONS WORK INDICATOR 1 dynamic bottom strings ============
    if (
      (wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses) ||
      (wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses) ||
      (!wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses) ||
      (wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses)
    ) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
    if (
      (!wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses) ||
      (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses)
    ) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }
    if (!wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
        </>
      );
    }
    if (wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
        </>
      );
    }
    if (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
  }, [
    internationalStandards,
    communicatedToSupp,
    commercialProcess,
    wcInternationalSnD,
    wcCommunicatedToS,
    wcCommercialProcesses,
  ]);

  function getTitle(title_text) {
    let title = "";
    if (title_text) {
      let area_title = title_text?.split("_");
      for (let i = 0; i < area_title.length; i++) {
        title =
          title + area_title[i][0].toUpperCase() + area_title[i].slice(1) + " ";
      }
    }

    return title;
  }

  return (
    <Container>
      {actualChartData.length === 0 ? (
        <LinearProgress />
      ) : (
        <Box sx={{ display: "flex" }}>
          <Grid container spacing={2}>
            <Grid container>
              <div className="workplan-box">
                <center>
                  <h2>
                    <strong>{context.t("Workplan Progress Dashboard")}</strong>
                  </h2>
                </center>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <center>
                      <h3>
                        {context.t("INDICATORS FOR AREA 1 – WORKER VOICE")}
                      </h3>
                    </center>
                    <h5>
                      <strong>{context.t("Worker Voice Indicator 1")}</strong>
                    </h5>
                  </Grid>
                  <Grid item xs={5}>
                    <Box ml={2}>
                      <h5>
                        <strong>
                          {context.t(
                            "Worker voice roll- out and ongoing engagement"
                          )}
                        </strong>
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Actual status")}</strong>
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "List of Active suppliers with/without worker voice"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box ml={2}>
                      <CustomSingleBar
                        sections={[
                          {
                            label: "Without Worker Voice",
                            color: "#E57373",
                            percentage: activeSuppliers.non_wv_percentage,
                            text: `${activeSuppliers.non_wv_percentage}%`,
                            tooltipData: activeSuppliers.suppliers_without_wv,
                            tooltipDataTitle: context.t("Suppliers List"),
                          },
                          {
                            label: "With Worker Voice",
                            color: "#81C784",
                            percentage: activeSuppliers.wv_percentage,
                            text: `${activeSuppliers.wv_percentage}%`,
                            tooltipData: activeSuppliers.suppliers_with_wv,
                            tooltipDataTitle: context.t("Suppliers List"),
                          },
                        ]}
                        marquee={true}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={5}></Grid>
                  <Grid item xs={7}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Company Targets/Notes")}</strong>
                      </h5>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "Percentage of tier-1 suppliers (export level) in your supply chain with Issara worker voice channels available to workers"
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={7}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "percentage_tier1_suppliers_with_issara_worker_voice_channels_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "Percentage of upstream (tier-2 and beyond) suppliers in your supply chain with Issara worker voice channels available to workers, based on supply chain information provided"
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={7}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "percentage_upstream_suppliers_with_issara_worker_voice_channels_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("Worker Voice Indicator 2")}</strong>
                    </h5>
                    <IndicatorInfo />
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      <h5>
                        <strong>
                          {context.t(
                            "Policy & procedures review: Ethical grievance mechanisms policy is in force that:"
                          )}
                        </strong>
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <h5>
                      <strong>{context.t("Actual status")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "Covers requirements and mechanisms for managing grievances"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "covers_requirements_mechanisms_managing_grievances"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t("Includes a clear non-retaliation policy")}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value == "clear_non_retaliation_policy"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "Undergoes periodic review to identify means for continuous improvement"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "periodic_review_continuous_improvement"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <center>
                      <h3>
                        {context.t("INDICATORS FOR AREA 2 – LABOR RECRUITMENT")}
                      </h3>
                    </center>
                    <h5>
                      <strong>{context.t("ER Indicator 1")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      <h5>
                        <strong>
                          {context.t(
                            "Policy & procedures review: Ethical recruitment / migrant recruitment laws and policies is in force that is:"
                          )}
                        </strong>
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <h5>
                      <strong>{context.t("Actual status")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "In line with international standards and definitions "
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "international_standards_definitions"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t("Clearly communicated to suppliers")}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value == "communicated_to_suppliers"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Grid>

                  <Grid item xs={5}>
                    <Box ml={2}>
                      {context.t(
                        "Integrated into commercial processes such as supplier scorecards and contracts"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value == "integrated_commercial_processes"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("ER Indicator 3")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>
                      <h5>
                        <strong>
                          {context.t(
                            "Policy & procedures review: Management Service Agreements:"
                          )}
                        </strong>
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Actual status")}</strong>
                      </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Company Targets/Notes")}</strong>
                      </h5>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>
                      {context.t(
                        "Review of MSAs between employers/suppliers and recruitment agencies"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.ER_3?.not_yet_initiated,
                          text: `${wvChartData?.ER_3?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.ER_3?.partial_progress,
                          text: `${wvChartData?.ER_3?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.ER_3?.completed_ongoing,
                          text: `${wvChartData?.ER_3?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "review_msas_employer_supplier_recruitment_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("ER Indicator 4")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>
                      {context.t(
                        "Communications: Disclosure of recruitment agencies used"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.ER_4?.not_yet_initiated,
                          text: `${wvChartData?.ER_4?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.ER_4?.partial_progress,
                          text: `${wvChartData?.ER_4?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.ER_4?.completed_ongoing,
                          text: `${wvChartData?.ER_4?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "communication_disclosure_recruitment_agencies_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("ER Indicator 6")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>{context.t("Recruitment fees surveys")}</Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.ER_6?.not_yet_initiated,
                          text: `${wvChartData?.ER_6?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.ER_6?.partial_progress,
                          text: `${wvChartData?.ER_6?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.ER_6?.completed_ongoing,
                          text: `${wvChartData?.ER_6?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value == "recruitment_survey_fees_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("ER Indicator 7")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>
                      {context.t(
                        "Institutional capacity building: Ethical recruitment trainings"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.ER_7?.not_yet_initiated,
                          text: `${wvChartData?.ER_7?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.ER_7?.partial_progress,
                          text: `${wvChartData?.ER_7?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.ER_7?.completed_ongoing,
                          text: `${wvChartData?.ER_7?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "institutional_capacity_building_ethical_recruitment_trainings_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <center>
                      <h3>
                        {context.t(
                          "INDICATORS FOR AREA 3 – WORKING CONDITOINS"
                        )}
                      </h3>
                    </center>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("WORK Indicator 1")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>{context.t("Worker satisfaction surveys")}</Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.WV_1?.not_yet_initiated,
                          text: `${wvChartData?.WV_1?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.WV_1?.partial_progress,
                          text: `${wvChartData?.WV_1?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.WV_1?.completed_ongoing,
                          text: `${wvChartData?.WV_1?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "worker_satisfaction_surveys_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("WORK Indicator 2")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>
                      {context.t(
                        "Institutional capacity building: Training of working level / technical staff"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.WV_2?.not_yet_initiated,
                          text: `${wvChartData?.WV_2?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.WV_2?.partial_progress,
                          text: `${wvChartData?.WV_2?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.WV_2?.completed_ongoing,
                          text: `${wvChartData?.WV_2?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "institutional_capacity_building_training_working_technical_staff_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("WORK Indicator 3")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={4}>
                    <Box ml={2}>
                      {context.t(
                        "Institutional capacity building: Training of management / leadership"
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSingleBar
                      sections={[
                        {
                          color: colorScheme.NOT_YET,
                          percentage: wvChartData?.WV_3?.not_yet_initiated,
                          text: `${wvChartData?.WV_3?.not_yet_initiated}%`,
                        },
                        {
                          color: colorScheme.PROGRESS,
                          percentage: wvChartData?.WV_3?.partial_progress,
                          text: `${wvChartData?.WV_3?.partial_progress}%`,
                        },
                        {
                          color: colorScheme.COMPLETE,
                          percentage: wvChartData?.WV_3?.completed_ongoing,
                          text: `${wvChartData?.WV_3?.completed_ongoing}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <p className={classes.notes}>
                        {progressIndicatorData?.length > 0 &&
                          progressIndicatorData.find(
                            (item) =>
                              item.str_value ==
                              "institutional_capacity_building_training_management_leadership_note"
                          )?.notes}
                      </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <center>
                      <h3>{context.t("IMPACT INDICATORS")}</h3>
                    </center>
                  </Grid>
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "IMPACT INDICATOR 1. Supplier average performance responding to worker-reported grievances and collaboration with Issara Institute"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  <Grid item xs={6}>
                    {Object.keys(supplierPerformance).length !== 0 ? <C3Chart
                      data={{
                        x: "x",
                        xFormat: "%Y-%m",
                        columns: [
                          ["x", ...supplierPerformance?.monthly_score?.dates],
                          [
                            "Quality of Response",
                            ...supplierPerformance?.monthly_score?.quality,
                          ],
                          [
                            "Timeliness of Response",
                            ...supplierPerformance?.monthly_score?.timeliness,
                          ],
                          [
                            "Openness to Reform",
                            ...supplierPerformance?.monthly_score?.openness,
                          ],
                        ],
                      }}
                      axis={{
                        x: {
                          type: "timeseries",
                          tick: {
                            fit: true,
                            rotate: -45,
                            format: "%Y-%m",
                            culling: {
                              max: 10,
                            },
                          },
                          height: 60,
                        },
                        y: {
                          max: 4.9,
                        },
                      }}
                      bar={{
                        width: {
                          ratio: 0.8,
                        },
                      }}
                    /> : <p>No data available</p>}
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} lg={12}>
                      <Box ml={2} mb={3}>
                        <strong>
                          • {context.t("Quality of remediation")}
                          <Tooltip
                            title={context.t(
                              "This is evaluated through worker satisfaction with the responses for recruitment and management, technical assessments of HR, production, and senior management."
                            )}
                            open={openTooltips.quality}
                            onOpen={() => handleTooltipOpen("quality")}
                            placement="right"
                          >
                            <IconButton
                              onMouseEnter={() => handleTooltipOpen("quality")}
                              size="small"
                              sx={{ marginLeft: "8px", padding: "0" }}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </strong>
                        <strong>
                          {supplierPerformance?.total_avg_score?._quality_score}
                        </strong>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Box ml={2} mb={3}>
                        <strong>
                          • {context.t("Timeliness of remediation")}
                          <Tooltip
                            title={context.t(
                              "This is measured by the duration of time taken to respond, revert on action plan, and resolve worker grievances."
                            )}
                            open={openTooltips.timeliness}
                            onOpen={() => handleTooltipOpen("timeliness")}
                            placement="right"
                          >
                            <IconButton
                              onMouseEnter={() =>
                                handleTooltipOpen("timeliness")
                              }
                              size="small"
                              sx={{ marginLeft: "8px", padding: "0" }}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </strong>
                        <strong>
                          {
                            supplierPerformance?.total_avg_score
                              ?._timeliness_score
                          }
                        </strong>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Box ml={2} mb={3}>
                        <strong>
                          • {context.t("Openness to reform")}
                          <Tooltip
                            title={context.t(
                              "This is assessed by the level of cooperation on worker voice channels and surveys, the business's attitude toward discussing risks and remediation, developing capacity and systems for identified risks, promoting worker voice in the industry and supply chain, and the treatment of workers."
                            )}
                            open={openTooltips.openness}
                            onOpen={() => handleTooltipOpen("openness")}
                            placement="right"
                          >
                            <IconButton
                              onMouseEnter={() => handleTooltipOpen("openness")}
                              size="small"
                              sx={{ marginLeft: "8px", padding: "0" }}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </strong>
                        <strong>
                          {
                            supplierPerformance?.total_avg_score
                              ?._openness_score
                          }
                        </strong>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={5}>
                    <h5>
                      <strong>
                        {context.t(
                          "IMPACT INDICATOR 2. % of worker-reported issues in the supply chain resolved in under 60 days"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  <Grid item xs={7}>
                    <h5>
                      <strong>{context.t("Company Target/Note")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <CustomSingleBar
                      sections={[
                        {
                          label: "Over 60 days",
                          color: "#E57373",
                          percentage:
                            issuesData?.issue_duration?.percentage_over_60,
                          text: `${issuesData?.issue_duration?.percentage_over_60}%`,
                        },
                        {
                          label: "Under 60 days",
                          color: "#81C784",
                          percentage:
                            issuesData?.issue_duration?.percentage_under_60,
                          text: `${issuesData?.issue_duration?.percentage_under_60}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <p className={classes.notes}>
                      {progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "impact_indicator_2_issue_resolution_60days_percent_note"
                        )?.notes}
                    </p>
                  </Grid>

                  <Grid item xs={5}>
                    <h5>
                      <strong>
                        {context.t(
                          "IMPACT INDICATOR 3. % of remediation efforts of specific issues resolved at a level of poor, fair, good, excellent"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  <Grid item xs={7}>
                    <h5>
                      <strong>{context.t("Company Target/Note")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <CustomSingleBar
                      sections={[
                        {
                          label: "Poor",
                          color: colorScheme.POOR,
                          percentage: issuesData?.issue_quality?.Poor,
                          text: `${issuesData?.issue_quality?.Poor}%`,
                        },
                        {
                          label: "Fair",
                          color: colorScheme.FAIR,
                          percentage: issuesData?.issue_quality?.Fair,
                          text: `${issuesData?.issue_quality?.Fair}%`,
                        },
                        {
                          label: "Good",
                          color: colorScheme.GOOD,
                          percentage: issuesData?.issue_quality?.Good,
                          text: `${issuesData?.issue_quality?.Good}%`,
                        },
                        {
                          label: "Excellent",
                          color: colorScheme.EXCELLENT,
                          percentage: issuesData?.issue_quality?.Excellent,
                          text: `${issuesData?.issue_quality?.Excellent}%`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <p className={classes.notes}>
                      {progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "impact_indicator_3_remediation_efforts_resolution_percent_note"
                        )?.notes}
                    </p>
                  </Grid>

                  <Grid item xs={5}>
                    <h5>
                      <strong>
                        {context.t(
                          "IMPACT INDICATOR 4. Number of eligible workers reimbursed for recruitment fees they have paid"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  <Grid item xs={7}>
                    <h5>
                      <strong>{context.t("Company Target/Note")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <CustomSingleBar
                      // sections={[
                      //   {
                      //     label:
                      //       "Eligible workers that have not been reimbursed",
                      //     color: "#E57373",
                      //     percentage: reimbursementData?.total_reimbursement || 0,
                      //     text: `${reimbursementData?.total_reimbursement || '0'}`,
                      //     number: `${reimbursementData?.total_reimbursement || 0}`,
                      //   },
                      //   {
                      //     label: "Eligible workers that have been reimbursed",
                      //     color: "#81C784",
                      //     percentage:
                      //       reimbursementData?.total_remaining_reimbursement || 0,
                      //     text: `${reimbursementData?.total_remaining_reimbursement || '0'}`,
                      //     number: `${reimbursementData?.total_remaining_reimbursement || 0}`,
                      //   },
                      // ]}
                      // TODO: REMOVE DUMMY DATA
                      sections={[
                        {
                          label:
                            "Eligible workers that have not been reimbursed",
                          color: "#E57373",
                          percentage: reimbursementData?.total_reimbursement || 1723,
                          text: `${reimbursementData?.total_reimbursement || '1723'}`,
                          number: `${reimbursementData?.total_reimbursement || 1723}`,
                        },
                        {
                          label: "Eligible workers that have been reimbursed",
                          color: "#81C784",
                          percentage:
                            reimbursementData?.total_remaining_reimbursement || 4717,
                          text: `${reimbursementData?.total_remaining_reimbursement || '4717'}`,
                          number: `${reimbursementData?.total_remaining_reimbursement || 4717}`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <p className={classes.notes}>
                      {progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "impact_indicator_4_eligible_workers_reimbursement_percent_note"
                        )?.notes}
                    </p>
                  </Grid>

                  <Grid item xs={5}>
                    <h5>
                      <strong>
                        {context.t(
                          "IMPACT INDICATOR 5. Amount of money owed versus amount of money reimbursed to workers for recruitment fees that they have paid"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  <Grid item xs={7}>
                    <h5>
                      <strong>{context.t("Company Target/Note")}</strong>
                    </h5>
                  </Grid>

                  <Grid item xs={5}>
                    <DonutImpact
                      type="donut"
                      sections={[
                        { label: "Reimbursed ($)", amount: 775350 },
                        {
                          label: "Agreed and in process for reimbursement ($)",
                          amount: 194901,
                        },
                        {
                          label: "Not agreed for reimbursement ($)",
                          amount: 505653,
                        },
                      ]}
                      colors={[colorScheme.EXCELLENT, colorScheme.GOOD, colorScheme.POOR ]}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <p className={classes.notes}>
                      {progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "impact_indicator_5_reimbursement_percent_note"
                        )?.notes}
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <center>
                      <h3>
                        <strong>
                          {context.t(
                            "PROGRESS ON PRIORITY WORKPLAN ACTIVITIES"
                          )}
                        </strong>
                      </h3>
                      <h3>
                        {context.t("ACTIVITIES FOR AREA 1 - WORKER VOICE")}
                      </h3>
                    </center>
                    <IndicatorInfo />
                    <h5>
                      <strong>
                        {context.t(
                          "Policies, Operational Systems, & Procedures"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "1.1. Worker voice / worker validation policy"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {onePointOne &&
                    onePointOne.reverse().map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}

                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t("1.2. Operational plans & rollout")}
                      </strong>
                    </h5>
                  </Grid>

                  {onePointTwo &&
                    onePointTwo.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("Effective Communications")}</strong>
                    </h5>
                    <h5>
                      <strong>
                        {context.t(
                          "1.3. Communications to workers (for Partner manufacturer/processor)"
                        )}
                      </strong>
                    </h5>
                  </Grid>

                  {onePointThree &&
                    onePointThree.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}

                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "1.4. Communications between Global Buyers & Suppliers"
                        )}
                      </strong>
                    </h5>
                  </Grid>

                  {onePointFour &&
                    onePointFour.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}

                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("System Strengthening")}</strong>
                    </h5>
                    <h5>
                      <strong>
                        {context.t(
                          "1.5. Capacity building: Ethical Grievance Mechanisms"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {onePointFive &&
                    onePointFive.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}

                  <Grid item xs={12}>
                    <center>
                      <h3>
                        {context.t("ACTIVITIES FOR AREA 2 - LABOR RECRUITMENT")}
                      </h3>
                    </center>
                    <h5>
                      <strong>
                        {context.t(
                          "Policies, Operational Systems, & Procedures"
                        )}
                      </strong>
                    </h5>
                  </Grid>

                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "2.1. Ethical recruitment policy / roadmap plan"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointOne &&
                    twoPointOne.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}

                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "2.2. System and Procedures for Ethical Recruitment"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointTwo &&
                    twoPointTwo.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("Effective Communications")}</strong>
                    </h5>
                    <h5>
                      <strong>
                        {context.t(
                          "2.3. Transparency in labor recruitment channels"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointThree &&
                    twoPointThree.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t("2.4 Communication to suppliers")}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointFour &&
                    twoPointFour.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "2.5. Communication of Recruitment Policies to Job Candidates & Worker"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointFive &&
                    twoPointFive.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("System Strengthening")}</strong>
                    </h5>
                    <h5>
                      <strong>{context.t("2.6 Recruitment surveys")}</strong>
                    </h5>
                  </Grid>
                  {twoPointSix &&
                    twoPointSix.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t("2.7 Recruitment fee repayment")}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointSeven &&
                    twoPointSeven.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "2.8 Capacity building: ethical recruitment trainings"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {twoPointEight &&
                    twoPointEight.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("2.10 Golden Dreams")}</strong>
                    </h5>
                  </Grid>
                  {twoPointTen &&
                    twoPointTen.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <center>
                      <h3>
                        {context.t(
                          "ACTIVITIES FOR AREA 3 - WORKING CONDITIONS"
                        )}
                      </h3>
                    </center>
                    <h5>
                      <strong>
                        {context.t(
                          "Policies, Operational Systems, & Procedures"
                        )}
                      </strong>
                    </h5>
                    <h5>
                      <strong>
                        {context.t(
                          "3.1. Establish sourcing policy / roadmap plan"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointOne &&
                    threePointOne.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "3.5. Independent worker satisfaction surveys"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointFive &&
                    threePointFive.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("Effective Communications")}</strong>
                    </h5>
                    <h5>
                      <strong>
                        {context.t("3.3 Communication to suppliers")}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointThree &&
                    threePointThree.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t("3.4 Communication to workers")}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointFour &&
                    threePointFour.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>{context.t("System Strengthening")}</strong>
                    </h5>
                    <h5>
                      <strong>
                        {context.t("3.2. Ongoing worker-driven remediation")}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointTwo &&
                    threePointTwo.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t("3.6. Capacity building: HRDD")}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointSix &&
                    threePointSix.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "3.7. Capacity building: interpreters & line supervisors"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointSeven &&
                    threePointSeven.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "3.8. Capacity building: management & leadership"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointEightA &&
                    threePointEightA.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <h5>
                      <strong>
                        {context.t(
                          "3.8. Capacity building: management & leadership"
                        )}
                      </strong>
                    </h5>
                  </Grid>
                  {threePointEightB &&
                    threePointEightB.map((item) => (
                      <Grid
                        container
                        spacing={3}
                        key={item.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <Grid item xs={7}>
                          <Box ml={7}>
                            <p>{item?.description}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <strong>
                            {Utils.getStatusColor(
                              context,
                              item.progress
                            ) ?? context.t("N/A")}
                          </strong>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}

SPProgressDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};
