import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { Modal, Grid, Paper, Typography, Button } from "@material-ui/core";

const CustomSingleBar = (props) => {
  const defaultHeight = 80;
  const { sections, height = defaultHeight, marquee = false } = props;
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);

  // Modal state
  const [open, setOpen] = useState(false);

  // Handle modal open/close
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);
    const width = svgRef.current.clientWidth;
    const barHeight = 30;
    const margin = { top: 40, right: 0, bottom: 0, left: 0 };

    const maxValue = sections.reduce(
      (acc, section) => acc + section.percentage,
      0
    );

    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, width - margin.left - margin.right]);

    svg.selectAll("*").remove();

    const labelSpacing =
      (width - margin.left - margin.right) / (sections.length + 1);

    sections.forEach((section, index) => {
      const xPosition = margin.left + labelSpacing * index;
      if (section?.label) {
        svg
          .append("rect")
          .attr("x", xPosition)
          .attr("y", margin.top / 4)
          .attr("width", 10)
          .attr("height", 10)
          .attr("fill", section.color);

        // Check if label has more than 3 words
        const wordCount = section.label.split(" ").length;

        // Define a container for the text that can clip overflow
        const textContainer = svg
          .append("g")
          .attr("transform", `translate(${xPosition + 15}, ${margin.top / 2})`)
          .attr("clip-path", `url(#clip-${index})`);

        textContainer
          .append("clipPath")
          .attr("id", `clip-${index}`)
          .append("rect")
          .attr("x", 0)
          .attr("y", -10)
          .attr("width", 149) // Width of the visible text area
          .attr("height", 20);

        const textElement = textContainer
          .append("text")
          .attr("x", 0)
          .attr("y", 0)
          .attr("text-anchor", "start")
          .attr("font-size", "12px")
          .text(section.label);

        if (marquee || wordCount > 3) {
          // Apply marquee-like effect using D3 transitions
          const textWidth = textElement.node().getBBox().width;

          function animateText() {
            textElement
              .transition()
              .duration(5000)
              .attr("x", -textWidth) // Scroll to the left by its width
              .on("end", function() {
                d3.select(this).attr("x", 80); // Reset to the right side
                animateText(); // Repeat animation
              });
          }

          animateText(); // Start the animation
        }
      }
    });

    let currentX = margin.left;
    sections?.length > 0 &&
      sections.forEach(
        ({
          label,
          color,
          percentage,
          number,
          text,
          tooltipData,
          tooltipDataTitle,
        }) => {
          const sectionWidth = xScale(percentage);
          const textColor = getTextColor(color);

          svg
            .append("rect")
            .attr("x", currentX)
            .attr("y", (height - barHeight) / 2)
            .attr("width", sectionWidth)
            .attr("height", barHeight)
            .attr("fill", color)
            .on("mouseover", function() {
              if (tooltipData?.length > 0) {
                const coordinates = d3.mouse(this);
                tooltip
                  .style("opacity", 1)
                  .html(
                    `<strong>${label}</strong><br/>
                      ${
                        number
                          ? "Total: " + number
                          : "Percentage: " + percentage + "%"
                      }<br/>
                      <strong>${tooltipDataTitle}:</strong></br>${tooltipData.join(
                      "<br/>"
                    )}`
                  )
                  .style("left", `${coordinates[0]}px`)
                  .style("top", `${coordinates[1] - 50}px`);
              }
            })
            .on("mouseout", function() {
              if (tooltipData?.length > 0) {
                tooltip.style("opacity", 0);
              }
            })
            .style("cursor", "pointer")
            .on("click", handleOpen); // Open modal on bar click

          svg
            .append("text")
            .attr("x", currentX + sectionWidth / 2)
            .attr("y", height / 2)
            .attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .attr("fill", textColor)
            .text(text);

          currentX += sectionWidth;
        }
      );
  }, [sections]);

  const getTextColor = (color) => {
    const luminance =
      (d3.color(color).r * 0.299 +
        d3.color(color).g * 0.587 +
        d3.color(color).b * 0.114) /
      255;
    return luminance > 0.5 ? "black" : "white";
  };

  return (
    <div style={{ width: "100%", padding: 0, margin: 0, position: "relative" }}>
      <svg
        ref={svgRef}
        width="100%"
        height={height}
        viewBox={`0 0 ${svgRef.current?.clientWidth || 600} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        style={{ margin: 0, padding: 0 }}
      />
      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          color: "white",
          padding: "5px",
          borderRadius: "4px",
          pointerEvents: "none",
          opacity: 0,
          whiteSpace: "nowrap",
          zIndex: 10,
        }}
      />

      {/* Modal for showing section data */}
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "white",
            padding: "20px",
            outline: "none",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Section Data
          </Typography>
          <Grid container spacing={2}>
            {sections.map((section, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: section?.color,
                    color: getTextColor(section?.color),
                    fontWeight: "bold",
                  }}
                >
                  {section?.label && (
                    <Typography variant="subtitle1">
                      <strong>{section.label}</strong>
                    </Typography>
                  )}
                  {section?.number && (
                    <div>
                      <strong>Total: {section.number}</strong>
                    </div>
                  )}
                  {!section?.number && section?.percentage && (
                    <div>
                      <strong>Percentage: {section.percentage}%</strong>
                    </div>
                  )}
                  {section?.tooltipData && (
                    <div>
                      <hr></hr>
                      <ul>
                        <strong>{section?.tooltipDataTitle}</strong>
                        {section.tooltipData.map((item, index) => (
                          <Typography key={index} style={{ display: "block" }}>
                            <li>{item}</li>
                          </Typography>
                        ))}
                      </ul>
                    </div>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomSingleBar;
