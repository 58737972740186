import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

// TODO: Move to utils
const colorScheme = {
  NOT_YET: '#b7b7b7',
  PROGRESS: '#ffe599',
  COMPLETE: '#148696',
};

function IndicatorInfo(props, context) {
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      padding={2}
      border={1}
      borderColor="grey.30"
      borderRadius={10}
    >
      <Box
        display="flex"
        alignItems="center"
        marginRight={2}
        padding={1}
        border={1}
        borderColor="grey.500"
        borderRadius={4}
      >
        <Box
          width={20}
          height={20}
          bgcolor={colorScheme.NOT_YET}
          marginRight={1}
          borderRadius="50%"
          border={"1px solid black"}
        />
        {context.t("Grey = Not yet initiated")}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        marginRight={2}
        padding={1}
        border={1}
        borderColor="grey.500"
        borderRadius={4}
      >
        <Box
          width={20}
          height={20}
          bgcolor={colorScheme.PROGRESS}
          marginRight={1}
          borderRadius="50%"
          border={"1px solid black"}
        />
        {context.t("Yellow = Partial progress")}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        padding={1}
        border={1}
        borderColor="grey.500"
        borderRadius={4}
      >
        <Box
          width={20}
          height={20}
          bgcolor={colorScheme.COMPLETE}
          marginRight={1}
          borderRadius="50%"
          border={"1px solid black"}
        />
        {context.t("Green = Completed/ongoing")}
      </Box>
    </Box>
  );
}

export default IndicatorInfo;

IndicatorInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};
