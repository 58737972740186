import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "../../CustomInput/CustomInput";
import PrevTextDataComponent from "./PrevTextDataComponent";
import "./tabStyles.css";

const EnsuringAnEthicalInvestigation = ({
  context,
  data,
  onDataChange,
  handleOnClick,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    let key_name = name.replace("_describe", "");
    let updatedValue;

    if (name.includes("describe")) {
      updatedValue = {
        [key_name]: {
          value: data[key_name]?.value || "",
          description: value,
        },
      };
    } else {
      updatedValue = {
        [name]: {
          value: value,
          description:
            name === "ethical_investigation_notes"
              ? undefined
              : data[name]?.description || "",
        },
      };
    }

    onDataChange(updatedValue);
  };

  return (
    <GridContainer>
      <GridItem lg={12}>
        <FormControl component="fieldset">
          {/* Question 1 */}
          <FormLabel component="legend">
            <span className="question">
              {context.t(
                "Ethical. Have you reviewed all possible individuals to be involved in the investigation, including interpreters, and ensured that all are fully committed to 'do no harm' and maintaining worker safeguards at all times?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="ethical_reviewed"
            value={data.ethical_reviewed.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.ethical_reviewed.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="ethical_reviewed_describe"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.ethical_reviewed.description}
              />
            )}
          </RadioGroup>

          {/* Question 2 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Professional. Have you ensured that responsible persons in the grievance process are knowledgeable, qualified, and trained to serve impartially in the grievance process?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="professional_ensured"
            value={data.professional_ensured.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.professional_ensured.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="professional_ensured_describe"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.professional_ensured.description}
              />
            )}
          </RadioGroup>

          {/* Question 3 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Impartial. Have you ensured that responsible persons in the grievance process have no biases and can refrain from making snap judgments when hearing the perspectives of workers versus other worksite staff?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="impartial_ensured"
            value={data.impartial_ensured.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.impartial_ensured.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="impartial_ensured_describe"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.impartial_ensured.description}
              />
            )}
          </RadioGroup>

          {/* Question 4 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Inclusive and Accessible. Have you identified all the language, gender, and literacy considerations for effective communication with the worker, and have you communicated with the worker the range of ways they can get in touch with you?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="inclusive_and_accessible"
            value={data.inclusive_and_accessible.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.inclusive_and_accessible.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="inclusive_and_accessible_describe"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.inclusive_and_accessible.description}
              />
            )}
          </RadioGroup>

          {/* Question 5 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Timely and Effective. Have you established responsible timelines and goals for investigation and remediation that take into account both the interests of the business and the worker?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="timely_and_effective"
            value={data.timely_and_effective.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.timely_and_effective.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="timely_and_effective_describe"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.timely_and_effective.description}
              />
            )}
          </RadioGroup>

          <FormGroup className="next_question">
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              labelText={context.t("Additional Notes")}
              name="ethical_investigation_notes"
              inputProps={{
                onChange: (e) => handleChange(e),
              }}
              value={
                data.ethical_investigation_notes
                  ? data.ethical_investigation_notes.value
                  : ""
              }
            />

            {/* Prev Data */}
            <PrevTextDataComponent
              context={context}
              data={
                data.list_of_text_fields
                  ? data.list_of_text_fields.ethical_investigation_notes
                  : []
              }
              text_header_1={"Note: "}
              text_header_2={""}
              is_notes={true}
            />
          </FormGroup>
        </FormControl>
      </GridItem>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <Button color="rose" onClick={handleOnClick}>
            {context.t("Save as Draft")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

EnsuringAnEthicalInvestigation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EnsuringAnEthicalInvestigation;
