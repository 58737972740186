import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import "./tabStyles.css";

const Introduction = ({ handleOnClick, context }) => {
  const ethical_grievance_mechanisms_components = [
    context.t("Ethical"),
    context.t("Professional"),
    context.t("Inclusive"),
    context.t("Accessible"),
    context.t("Safe"),
    context.t("Impartial"),
    context.t("Timely action"),
    context.t("Effective"),
  ];

  const downloadLink =
    "https://www.issarainstitute.org/_files/ugd/5bf36e_7c51ccbcf3fe4519885d7e98c6402a98.pdf";

  return (
    <GridContainer>
      <GridItem xs={12}>
        <p className="intro_text">
          {context.t(
            "The ILM interactive grievance management tool allows you to receive and review ongoing worker feedback and worker-reported issues at your site, and respond in a timely and ethical manner. Functioning ethical grievance mechanisms must always have two things: (1) Safeguarded workers, and (2) Responsive, respectful employers and recruiters. To review more on the 8 components of Ethical Grievance Mechanisms, please download the one-page primer here "
          )}
          <a href={downloadLink} target="_blank" rel="noopener noreferrer">
            {context.t("PDF")}
          </a>
          .
        </p>
        <br />
        <p className="intro_text">
          {context.t(
            "Reminder! The 8 components of Ethical Grievance Mechanisms: "
          )}
        </p>
        <ol>
          {ethical_grievance_mechanisms_components.map((component, index) => (
            <li className="intro_text" key={component}>
              {component}
            </li>
          ))}
        </ol>
      </GridItem>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <Button color="rose" onClick={handleOnClick}>
            {context.t("Next")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

Introduction.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Introduction;
