import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import "./css/style1.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { fetchWorkPlanData } from "redux/actions/SpWorkplanFormAction";
import { useDispatch } from "react-redux";
import ActivitiesReview from "./ActivitiesReview";
import Utils from "services/utils";
import { makeStyles } from "@material-ui/core/styles";
import IndicatorInfo from "components/Indicator/IndicatorInfo";

const useStyles = makeStyles((theme) => ({
  notes: {
    marginTop: "5px",
    width: "100%",
    minHeight: "150px",
    background: "whitesmoke",
    borderRadius: "11px",
    padding: "11px",
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function FinalWorkplan(props, context) {
  const dispatch = useDispatch();
  const workPlanItem = useSelector((state) => state.workplanReducer.item);
  const [workPlanData, setWorkPlanData] = useState({});
  const [progressIndicatorData, setProgressIndicatorData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    !workPlanItem.loading &&
      !workPlanItem.fetched &&
      dispatch(fetchWorkPlanData());
  }, []);

  useEffect(() => {
    if (workPlanItem.fetched) {
      setWorkPlanData(workPlanItem?.data);
    }
  }, [workPlanItem]);

  useEffect(() => {
    if (workPlanData) {
      setProgressIndicatorData(workPlanData.progress_indicator);
      setActivitiesData(workPlanData.priority_activity);
    }
  }, [workPlanData]);

  return (
    <>
      <Grid>
        <Grid>
          <h5
            style={{
              textAlign: "center",
              background: "#e0e0e0",
              color: "black",
              padding: "20px",
              fontWeight: "bold",
            }}
          >
            {context.t("Workplan Reviews")}
          </h5>
        </Grid>
        {workPlanItem.fetching ? (
          <LinearProgress />
        ) : (
          <>
            <Grid className="finalworkplan" key={workPlanData.id}>
              {workPlanData &&
              workPlanData.documents !== undefined &&
              Array.isArray(workPlanData.documents) &&
              workPlanData.documents.length > 0 &&
              workPlanData.documents[0].workplan &&
              workPlanData.documents[0].workplan.strategic_partners &&
              workPlanData.documents[0].workplan.strategic_partners.name ? (
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "0px 10px",
                  }}
                >
                  {workPlanData.documents[0].workplan.strategic_partners.name}
                  {context.t("Work Plan")}
                </h3>
              ) : (
                ""
              )}
              <hr style={{ padding: "1px", background: "black" }}></hr>

              <Grid style={{ padding: "0px 10px" }}>
                <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                  {context.t("Introduction")}
                </h3>
                <Grid container>
                  <Grid item xs={6}>
                    <h5>{context.t("Name of the focal point:")}</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{workPlanData.fp_name}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <h5>{context.t("Created at:")}</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{workPlanData.created_at}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <h5>
                      {context.t("Suggested date for mid-year check-in:")}
                    </h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{workPlanData.suggested_date}</p>
                  </Grid>
                </Grid>
              </Grid>
              <hr
                style={{
                  padding: "1px",
                  background: "black",
                  marginTop: "30px",
                }}
              ></hr>

              {/* Documents sections */}
              <Grid style={{ padding: "0px 10px" }}>
                <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                  {context.t("Documents")}
                </h3>
                <p>
                  {context.t(
                    "Please check that all foundational documents and materials that have been shared with Issara. If not, you can upload them here:"
                  )}
                </p>
                {workPlanData &&
                workPlanData.documents !== undefined &&
                Array.isArray(workPlanData.documents) &&
                workPlanData.documents.length > 0 &&
                (!workPlanData.documents ||
                  workPlanData.documents === undefined ||
                  workPlanData.documents[0] === undefined)
                  ? ""
                  : Array.isArray(workPlanData.documents) &&
                    workPlanData.documents.length > 0 &&
                    workPlanData.documents.map((docs, index) => {
                      return (
                        <>
                          <Grid item xs={6}>
                            <h5>
                              {context.t("Document Type:")}
                              {docs.document_type}
                            </h5>
                          </Grid>
                          <Grid item xs={6}>
                            <p>
                              {context.t("File url:")}
                              <span style={{ cursor: "pointer" }}>
                                <a href={docs.document.file_path} target="_new">
                                  {docs.document.name}
                                </a>
                              </span>
                            </p>
                          </Grid>
                        </>
                      );
                    })}
              </Grid>
              {/* Main goals and priority section */}
              {workPlanData &&
                workPlanData.main_goals !== undefined &&
                Array.isArray(workPlanData.main_goals) &&
                workPlanData.main_goals.length > 0 && (
                  <Grid style={{ padding: "0px 10px" }}>
                    <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                      {context.t("Main Goals and Priority Partnership Areas")}
                    </h3>
                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t(
                        "What are the company's main goals in responsible sourcing and ethical recruitment over the next 3 years?"
                      )}
                    </h5>
                    <p>
                      {workPlanData.main_goals &&
                      workPlanData.main_goals[0] &&
                      workPlanData.main_goals[0].ethical_recruitment
                        ? workPlanData.main_goals[0].ethical_recruitment
                        : ""}
                    </p>
                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t(
                        "What are the company's main priority areas of partnership with Issara Institute?"
                      )}
                    </h5>
                    <p>
                      {workPlanData.main_goals &&
                      workPlanData.main_goals[0] &&
                      workPlanData.main_goals[0].partnership_areas
                        ? workPlanData.main_goals[0].partnership_areas
                        : ""}
                    </p>
                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t(
                        "How worker voice via Inclusive Labor Monitoring (ILM) will be actioned and escalated at Partner:"
                      )}
                    </h5>
                    <p>
                      {workPlanData.main_goals &&
                      workPlanData.main_goals[0] &&
                      workPlanData.main_goals[0].worker_voice_escalation
                        ? workPlanData.main_goals[0].worker_voice_escalation
                        : ""}
                    </p>
                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t(
                        "Priority suppliers, countries, and/or regions for continued focus or expansion:"
                      )}
                    </h5>
                    <p>
                      {workPlanData.main_goals &&
                      workPlanData.main_goals[0] &&
                      workPlanData.main_goals[0]
                        .priority_suppliers_countries_regions
                        ? workPlanData.main_goals[0]
                            .priority_suppliers_countries_regions
                        : ""}
                    </p>
                  </Grid>
                )}

              <hr
                style={{
                  padding: "1px",
                  background: "black",
                  marginTop: "30px",
                }}
              ></hr>

              <Grid style={{ padding: "0px 10px" }}>
                <IndicatorInfo />
                <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                  {context.t("Progress Indicators")}
                </h3>

                <Grid>
                  <h4
                    style={{
                      fontWeight: "bold",
                      padding: "30px 0px 20px 0px",
                    }}
                  >
                    {context.t(
                      "INDICATORS FOR AREA 1. WORKER VOICE: ANNUAL TARGETS"
                    )}
                  </h4>
                  <Grid container spacing={5}>
                    <Grid item xs={2}>
                      <strong>{context.t("Worker Voice Indicator 1")}</strong>
                    </Grid>
                    <Grid item xs={5} style={{ margin: 0, paddingLeft: 4 }}>
                      <strong>
                        {context.t(
                          "Worker voice roll-out and ongoing engagement"
                        )}
                      </strong>
                    </Grid>
                    <Grid item xs={5}>
                      <strong>{context.t("Notes")}</strong>
                    </Grid>

                    {workPlanData.progress_indicator &&
                    Array.isArray(workPlanData.progress_indicator) &&
                    workPlanData.progress_indicator.length > 0
                      ? workPlanData.progress_indicator.map((pi, i) => {
                          if (pi.area == "worker_voice" && pi.notes !== null) {
                            return (
                              <Grid container key={i} spacing={5}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                  <p>{pi.txt_value}</p>
                                </Grid>
                                <Grid item xs={5}>
                                  <p className={classes.notes}>{pi.notes}</p>
                                </Grid>
                              </Grid>
                            );
                          }
                          return null;
                        })
                      : null}

                    <Grid item xs={2}>
                      <strong>{context.t("Worker Voice Indicator 2")}</strong>
                    </Grid>
                    <Grid item xs={5} style={{ margin: 0, paddingLeft: 4 }}>
                      <strong>
                        {context.t(
                          "Policy & procedures review: Ethical grievance mechanisms policy is in force that:"
                        )}
                      </strong>
                    </Grid>
                    <Grid item xs={5}>
                      <strong>{context.t("Statuses")}</strong>
                    </Grid>

                    {workPlanData.progress_indicator &&
                    Array.isArray(workPlanData.progress_indicator) &&
                    workPlanData.progress_indicator.length > 0
                      ? workPlanData.progress_indicator.map((pi, i) => {
                          if (pi.area == "worker_voice" && pi.notes === null) {
                            return (
                              <Grid container key={i} spacing={5}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                  <p>{pi.txt_value}</p>
                                </Grid>
                                <Grid item xs={5}>
                                  <strong>
                                    {Utils.getStatusColor(
                                      context,
                                      pi.progress_status
                                    )}
                                  </strong>
                                </Grid>
                              </Grid>
                            );
                          }
                          return null;
                        })
                      : null}
                  </Grid>
                </Grid>
                <Grid>
                  <h4
                    style={{
                      fontWeight: "bold",
                      padding: "30px 0px 20px 0px",
                    }}
                  >
                    {context.t(
                      "INDICATORS FOR AREA 2. LABOR RECRUITMENT: ANNUAL TARGETS"
                    )}
                  </h4>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <strong>{context.t("ER Indicator 1")}</strong>
                  </Grid>
                  <Grid item xs={10}>
                    <strong>
                      {context.t(
                        "Policy & procedures review: Ethical recruitment / migrant recruitment laws and policies are in force that are:"
                      )}
                    </strong>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    {context.t(
                      "In line with international standards and definitions"
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "international_standards_definitions"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                  </Grid>

                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    {context.t("Clearly communicated to suppliers")}
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) => pgd.str_value === "communicated_to_suppliers"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                  </Grid>

                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    {context.t(
                      "Integrated into commercial processes such as supplier scorecards and contracts"
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value === "integrated_commercial_processes"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                  </Grid>

                  <Grid item xs={2}>
                    <strong>{context.t("ER Indicator 3")}</strong>
                  </Grid>
                  <Grid item xs={5}>
                    <strong>
                      {context.t(
                        "Review of MSAs between employer/suppliers and recruitment agencies"
                      )}
                    </strong>
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "review_msas_employer_supplier_recruitment"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "review_msas_employer_supplier_recruitment_note"
                        );
                        return pg ? (
                          <div>
                            <p className={classes.notes}>{pg.notes}</p>
                          </div>
                        ) : null;
                      })()}
                  </Grid>

                  <Grid item xs={2}>
                    <strong>{context.t("ER Indicator 4")}</strong>
                  </Grid>
                  <Grid item xs={5}>
                    <strong>
                      {context.t(
                        "Communications: disclosure of recruitment agencies used"
                      )}
                    </strong>
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "communication_disclosure_recruitment_agencies"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "communication_disclosure_recruitment_agencies_note"
                        );
                        return pg ? (
                          <div>
                            <p className={classes.notes}>{pg.notes}</p>
                          </div>
                        ) : null;
                      })()}
                  </Grid>

                  <Grid item xs={2}>
                    <strong>{context.t("ER Indicator 6")}</strong>
                  </Grid>
                  <Grid item xs={5}>
                    <strong>{context.t("Recruitment survey fees")}</strong>
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) => pgd.str_value === "recruitment_survey_fees"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value === "recruitment_survey_fees_note"
                        );
                        return pg ? (
                          <div>
                            <p className={classes.notes}>{pg.notes}</p>
                          </div>
                        ) : null;
                      })()}
                  </Grid>

                  <Grid item xs={2}>
                    <strong>{context.t("ER Indicator 7")}</strong>
                  </Grid>
                  <Grid item xs={5}>
                    <strong>
                      {context.t(
                        "Institutional capacity building: ethical recruitment trainings"
                      )}
                    </strong>
                  </Grid>
                  <Grid item xs={5}>
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "institutional_capacity_building_training_staff"
                        );
                        return pg ? (
                          <>
                            <strong>
                              {Utils.getStatusColor(
                                context,
                                pg.progress_status
                              )}
                            </strong>
                          </>
                        ) : null;
                      })()}
                    {progressIndicatorData?.length > 0 &&
                      (() => {
                        const pg = progressIndicatorData.find(
                          (pgd) =>
                            pgd.str_value ===
                            "institutional_capacity_building_ethical_recruitment_trainings_note"
                        );
                        return pg ? (
                          <div>
                            <p className={classes.notes}>{pg.notes}</p>
                          </div>
                        ) : null;
                      })()}
                  </Grid>
                </Grid>

                <Grid>
                  <h4
                    style={{
                      fontWeight: "bold",
                      padding: "30px 0px 20px 0px",
                    }}
                  >
                    {context.t(
                      "INDICATORS FOR AREA 3. WORKING CONDITIONS: ANNUAL TARGETS"
                    )}
                  </h4>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <strong>{context.t("WORK Indicator 1")}</strong>
                    </Grid>
                    <Grid item xs={5}>
                      {context.t("Worker satisfaction surveys")}
                    </Grid>
                    <Grid item xs={5}>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value === "worker_satisfaction_surveys"
                          );
                          return pg ? (
                            <>
                              <strong>
                                {Utils.getStatusColor(
                                  context,
                                  pg.progress_status
                                )}
                              </strong>
                            </>
                          ) : null;
                        })()}
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "worker_satisfaction_surveys_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>

                    <Grid item xs={2}>
                      <strong>{context.t("WORK Indicator 2")}</strong>
                    </Grid>
                    <Grid item xs={5}>
                      {context.t(
                        "Institutional capacity building: training of working level/technical staff"
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "institutional_capacity_building_training_working_technical_staff"
                          );
                          return pg ? (
                            <>
                              <strong>
                                {Utils.getStatusColor(
                                  context,
                                  pg.progress_status
                                )}
                              </strong>
                            </>
                          ) : null;
                        })()}
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "institutional_capacity_building_training_working_technical_staff_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>

                    <Grid item xs={2}>
                      <strong>{context.t("WORK Indicator 3")}</strong>
                    </Grid>
                    <Grid item xs={5}>
                      {context.t(
                        "Institutional capacity building: training of management/leadership"
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "institutional_capacity_building_training_management_leadership"
                          );
                          return pg ? (
                            <>
                              <strong>
                                {Utils.getStatusColor(
                                  context,
                                  pg.progress_status
                                )}
                              </strong>
                            </>
                          ) : null;
                        })()}
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "institutional_capacity_building_training_management_leadership_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>

                    <Grid item xs={12}>
                      <h5>
                        {context.t(
                          "IMPACT INDICATOR 1. Average performance scores or supply chain businesses (Scale of 1-5, where 1 is poor and 5 is excellent):"
                        )}
                      </h5>
                      <ul>
                        <li>
                          <Grid container>
                            <Grid item xs={3}>
                              {context.t("Quality of remediation")}
                            </Grid>
                            <Grid item xs={9}>
                              {progressIndicatorData?.length > 0 &&
                                (() => {
                                  const pg = progressIndicatorData.find(
                                    (pgd) =>
                                      pgd.str_value === "quality_remediation"
                                  );
                                  return pg ? (
                                    <>
                                      <p>{pg.numeric_value}</p>
                                    </>
                                  ) : null;
                                })()}
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container>
                            <Grid item xs={3}>
                              {context.t("Timeliness of remediation")}
                            </Grid>
                            <Grid item xs={9}>
                              {progressIndicatorData?.length > 0 &&
                                (() => {
                                  const pg = progressIndicatorData.find(
                                    (pgd) =>
                                      pgd.str_value === "timeliness_remediation"
                                  );
                                  return pg ? (
                                    <>
                                      <p>{pg.numeric_value}</p>
                                    </>
                                  ) : null;
                                })()}
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container>
                            <Grid item xs={3}>
                              {context.t("Openness to reform")}
                            </Grid>
                            <Grid item xs={9}>
                              {progressIndicatorData?.length > 0 &&
                                (() => {
                                  const pg = progressIndicatorData.find(
                                    (pgd) =>
                                      pgd.str_value === "openness_to_reform"
                                  );
                                  return pg ? (
                                    <>
                                      <p>{pg.numeric_value}</p>
                                    </>
                                  ) : null;
                                })()}
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                    </Grid>

                    <Grid item xs={12}>
                      <h5>
                        {context.t(
                          "IMPACT INDICATOR 2. % of worker-reported issues in the supply chain resolved in under 60 days"
                        )}
                      </h5>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "impact_indicator_2_issue_resolution_60days_percent_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>

                    <Grid item xs={12}>
                      <h5>
                        {context.t(
                          "IMPACT INDICATOR 3. % of remediation efforts of specific issues resolved at a level of 'fair' or better (Fair, Good, or Excellent)"
                        )}
                      </h5>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "impact_indicator_3_remediation_efforts_resolution_percent_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>

                    <Grid item xs={12}>
                      <h5>
                        {context.t(
                          "IMPACT INDICATOR 4. % of eligible workers reimbursed for recruitment fees they have paid"
                        )}
                      </h5>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "impact_indicator_4_eligible_workers_reimbursement_percent_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>

                    <Grid item xs={12}>
                      <h5>
                        {context.t(
                          "IMPACT INDICATOR 5. % Amount of money owed versus amount of money reimbursed to workers for recruitment fees that they have paid"
                        )}
                      </h5>
                      {progressIndicatorData?.length > 0 &&
                        (() => {
                          const pg = progressIndicatorData.find(
                            (pgd) =>
                              pgd.str_value ===
                              "impact_indicator_5_reimbursement_percent_note"
                          );
                          return pg ? (
                            <div>
                              <p>
                                <p className={classes.notes}>{pg.notes}</p>
                              </p>
                            </div>
                          ) : null;
                        })()}
                    </Grid>
                  </Grid>
                  <Grid>
                    <h4
                      style={{
                        fontWeight: "bold",
                        padding: "30px 0px 20px 0px",
                      }}
                    >
                      {context.t("Notes and remark")}
                    </h4>
                    {workPlanData &&
                    workPlanData.notes_remark !== undefined &&
                    Array.isArray(workPlanData.notes_remark) &&
                    workPlanData.notes_remark.length > 0
                      ? workPlanData.notes_remark.map((notes, i) => {
                          if (notes.area === "progress indicators") {
                            return (
                              <p className={classes.notes}>{notes.txt_value}</p>
                            );
                          }
                        })
                      : null}
                  </Grid>
                </Grid>
              </Grid>

              <hr
                style={{
                  padding: "1px",
                  background: "black",
                  marginTop: "30px",
                }}
              ></hr>
              <ActivitiesReview activitiesData={activitiesData} />
              <hr />
              <h4
                style={{
                  fontWeight: "bold",
                  padding: "30px 0px 20px 0px",
                }}
              >
                {context.t("Notes and remarks")}
              </h4>
              {workPlanData &&
              workPlanData.notes_remark !== undefined &&
              Array.isArray(workPlanData.notes_remark) &&
              workPlanData.notes_remark.length > 0
                ? workPlanData.notes_remark.map((notes, i) => {
                    if (notes.area === "workplan activity") {
                      return (
                        <p className={classes.notes}> {notes.txt_value} </p>
                      );
                    }
                  })
                : null}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

FinalWorkplan.contextTypes = {
  t: PropTypes.func.isRequired,
};
