import React, { useState } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import "./tabStyles.css";

const Trainings = ({
  context,
  data,
  issara_trainings,
  onDataChange,
  handlePreview,
}) => {
  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      onDataChange({
        trainings: [...data.trainings, name],
      });
    } else {
      onDataChange({
        trainings: data.trainings.filter((training) => training !== name),
      });
    }
  };

  return (
    <GridContainer>
      <GridItem lg={12}>
        <FormControl sx={{ m: 3 }} component="fieldset">
          <FormLabel component="legend">
            <span className="question">
              {context.t(
                "Please review the list of trainings below and indicate if you would like to request any of them from Issara. "
              )}
            </span>
          </FormLabel>
          <FormGroup>
            <Box sx={{ display: "flex" }}>
              <GridContainer>
                {issara_trainings.map((training, idx) => (
                  <GridItem key={training} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.trainings.includes(training)}
                          onChange={handleChange}
                          name={training}
                        />
                      }
                      label={
                        <span className="answer_checkbox">{training}</span>
                      }
                    />
                  </GridItem>
                ))}
              </GridContainer>
            </Box>
          </FormGroup>
        </FormControl>
      </GridItem>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <Button color="rose" onClick={handlePreview}>
            {context.t("Preview & Submit")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

Trainings.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Trainings;
