import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Utils from "services/utils";
import IndicatorInfo from "components/Indicator/IndicatorInfo";

export default function ActivitiesReview(props, context) {
  const activitiesData = props.activitiesData;
  const [data, setData] = useState([]);

  const flattenDataArray = (dataArray) => {
    return dataArray.map((data) => {
      const { priority_activity, ...rest } = data;
      const {
        id: priority_activity_id,
        group,
        ...activityRest
      } = priority_activity;
      const { name: groupName, description: groupDescription } = group;

      return {
        ...rest,
        priority_activity_id,
        ...activityRest,
        groupName,
        groupDescription,
      };
    });
  };

  useEffect(() => {
    if (activitiesData && activitiesData?.length > 0) {
      setData(flattenDataArray(activitiesData));
    }
  }, [activitiesData]);

  const onePointOne =
    data?.length > 0 && data.filter((item) => item.groupName === "1.1");

  const onePointTwo =
    data?.length > 0 && data.filter((item) => item.groupName === "1.2");

  const onePointThree =
    data?.length > 0 && data.filter((item) => item.groupName === "1.3");

  const onePointFour =
    data?.length > 0 && data.filter((item) => item.groupName === "1.4");

  const onePointFive =
    data?.length > 0 && data.filter((item) => item.groupName === "1.5");

  const twoPointOne =
    data?.length > 0 && data.filter((item) => item.groupName === "2.1");

  const twoPointTwo =
    data?.length > 0 && data.filter((item) => item.groupName === "2.2");

  const twoPointThree =
    data?.length > 0 && data.filter((item) => item.groupName === "2.3");

  const twoPointFour =
    data?.length > 0 && data.filter((item) => item.groupName === "2.4");

  const twoPointFive =
    data?.length > 0 && data.filter((item) => item.groupName === "2.5");

  const twoPointSix =
    data?.length > 0 && data.filter((item) => item.groupName === "2.6");

  const twoPointSeven =
    data?.length > 0 && data.filter((item) => item.groupName === "2.7");

  const twoPointEight =
    data?.length > 0 && data.filter((item) => item.groupName === "2.8");

  const twoPointTen =
    data?.length > 0 && data.filter((item) => item.groupName === "2.10");

  const threePointOne =
    data?.length > 0 && data.filter((item) => item.groupName === "3.1");

  const threePointTwo =
    data?.length > 0 && data.filter((item) => item.groupName === "3.2");

  const threePointThree =
    data?.length > 0 && data.filter((item) => item.groupName === "3.3");

  const threePointFour =
    data?.length > 0 && data.filter((item) => item.groupName === "3.4");

  const threePointFive =
    data?.length > 0 && data.filter((item) => item.groupName === "3.5");

  const threePointSix =
    data?.length > 0 && data.filter((item) => item.groupName === "3.6");

  const threePointSeven =
    data?.length > 0 && data.filter((item) => item.groupName === "3.7");

  const threePointEightA =
    data?.length > 0 && data.filter((item) => item.groupName === "3.8-a");

  const threePointEightB =
    data?.length > 0 && data.filter((item) => item.groupName === "3.8-b");

  return (
    <Grid style={{ padding: "0px 10px" }}>
      <IndicatorInfo />
      <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
        {context.t("Priority Workplan Activities")}
      </h3>
      <p>
        {context.t(
          "The priority activities for your company that you indicate here will appear on your SP Progress Dashboard."
        )}
      </p>
      <h5
        style={{
          padding: "5px 0px",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {context.t("ACTIVITIES FOR AREA 1 - WORKER VOICE")}
      </h5>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>{context.t("Policies, Operational Systems, & Procedures")}</h5>
          <h5>{context.t("1.1. Worker voice / worker validation policy")}</h5>
        </Grid>

        {onePointOne &&
          onePointOne.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("1.2. Operational plans & rollout")}</h5>
        </Grid>

        {onePointTwo &&
          onePointTwo.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("Effective Communications")}</h5>
          <h5>
            {context.t(
              "1.3. Communications to workers (for Partner manufacturer/processor)"
            )}
          </h5>
        </Grid>

        {onePointThree &&
          onePointThree.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t("1.4. Communications between Global Buyers & Suppliers")}
          </h5>
        </Grid>

        {onePointFour &&
          onePointFour.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("System Strengthening")}</h5>
          <h5>
            {context.t("1.5. Capacity building: Ethical Grievance Mechanisms")}
          </h5>
        </Grid>

        {onePointFive &&
          onePointFive.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}
      </Grid>
      {/* end of container */}
      <h5
        style={{
          padding: "5px 0px",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {context.t("ACTIVITIES FOR AREA 2 - LABOR RECRUITMENT")}
      </h5>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>{context.t("Policies, Operational Systems, & Procedures")}</h5>
          <h5>{context.t("2.1. Ethical recruitment policy / roadmap plan")}</h5>
        </Grid>
        {twoPointOne &&
          twoPointOne.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t("2.2. System and Procedures for Ethical Recruitment")}
          </h5>
        </Grid>
        {twoPointTwo &&
          twoPointTwo.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("Effective Communications")}</h5>
          <h5>
            {context.t("2.3. Transparency in labor recruitment channels")}
          </h5>
        </Grid>
        {twoPointThree &&
          twoPointThree.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("2.4 Communication to suppliers")}</h5>
        </Grid>
        {twoPointFour &&
          twoPointFour.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t(
              "2.5. Communication of Recruitment Policies to Job Candidates & Workers"
            )}
          </h5>
        </Grid>
        {twoPointFive &&
          twoPointFive.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("System Strengthening")}</h5>
          <h5>{context.t("2.6 Recruitment surveys")}</h5>
        </Grid>

        {twoPointSix &&
          twoPointSix.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("2.7 Recruitment fee repayment")}</h5>
        </Grid>

        {twoPointSeven &&
          twoPointSeven.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t("2.8 Capacity building: ethical recruitment trainings")}
          </h5>
        </Grid>

        {twoPointEight &&
          twoPointEight.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("2.10 Golden Dreams")}</h5>
        </Grid>

        {twoPointTen &&
          twoPointTen.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}
      </Grid>

      <h5
        style={{
          padding: "5px 0px",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {context.t("ACTIVITIES FOR AREA 3 - WORKING CONDITIONS")}
      </h5>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>{context.t("Policies, Operational Systems, & Procedures")}</h5>
          <h5>{context.t("3.1. Establish sourcing policy / roadmap plan")}</h5>
        </Grid>
        {threePointOne &&
          threePointOne.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}
        <Grid item xs={12}>
          <h5>{context.t("3.5. Independent worker satisfaction surveys")}</h5>
        </Grid>
        {threePointFive &&
          threePointFive.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("Effective Communications")}</h5>
          <h5>{context.t("3.3 Communication to suppliers")}</h5>
        </Grid>

        {threePointThree &&
          threePointThree.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("3.4 Communication to workers")}</h5>
        </Grid>

        {threePointFour &&
          threePointFour.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("System Strengthening")}</h5>
          <h5>{context.t("3.2. Ongoing worker-driven remediation")}</h5>
        </Grid>

        {threePointTwo &&
          threePointTwo.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>{context.t("3.6. Capacity building: HRDD")}</h5>
        </Grid>
        {threePointSix &&
          threePointSix.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t(
              "3.7. Capacity building: interpreters & line supervisors"
            )}
          </h5>
        </Grid>
        {threePointSeven &&
          threePointSeven.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t("3.8. Capacity building: management & leadership")}
          </h5>
        </Grid>
        {threePointEightA &&
          threePointEightA.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}

        <Grid item xs={12}>
          <h5>
            {context.t("3.8. Capacity building: management & leadership")}
          </h5>
        </Grid>
        {threePointEightB &&
          threePointEightB.map((item) => (
            <Grid
              container
              spacing={3}
              key={item.id}
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={7}>
                <Box ml={7}>
                  <p>{item.description}</p>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <strong>
                  {Utils.getStatusColor(context, item.priority_status) ??
                    context.t("N/A")}
                </strong>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

ActivitiesReview.contextTypes = {
  t: PropTypes.func.isRequired,
};
