import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import SupplyChainFilter from "components/ilmdb/SupplyChainFilter.js";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoogleMapReact from "google-map-react";
import loginStore from "../../redux/stores/LoginStore";
import { fetchSuppliers } from "../../redux/actions/SupplierActions";
import {
  fetchStrategicPartners,
  fetchSupplyChains,
} from "../../redux/actions/StrategicPartnerActions";
import { fetchCountries } from "../../redux/actions/LocaleActions";
import Utils from "../../services/utils";
import NewsUpdateList from "./NewsUpdateList";
import PartnerMessageBoardList from "./PartnerMessageBoardList";
import SharedFileList from "./SharedFileList";
import Tooltip from "@material-ui/core/Tooltip";
import { fetchSharedFiles } from "../../redux/actions/StrategicPartnerActions";
import PartnerReferenceDocList from "./PartnerReferenceDocList";

const customStyle = {
  ...styles,
  button: {
    padding: 0,
    margin: 0,
  },
  newInteractionGridItem: {
    // padding: '0 6px !important',
  },
  buttonBar: {
    marginLeft: "-15px !important",
  },
  gallery: {
    height: "100%",
    width: "100%",
    position: "relative",
  },

  galleryImg: {
    /* CSS Hack will make it width 100% and height 100% */
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    /* Maintain aspect ratio */
    maxHeight: "100%",
    maxWidth: "100%",
  },
  galleryContainer: {
    width: "40px",
    height: "40px",
  },
  galleryContainerBigger: {
    width: "100px",
    height: "100px",
  },
};

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AnyReactComponent = ({ text }) => (
  <div>
    <HtmlTooltip title={text} interactive>
      <AccountCircleIcon style={{ fontSize: "18px", color: "red" }} />
    </HtmlTooltip>
  </div>
);

function SuppliersMapPanel(props) {
  const suppliers = props.suppliers ? props.suppliers : [];
  const suppliersMap = props.suppliersMap ? props.suppliersMap : {};

  const fullSuppliers = [];

  const defaultProps = {
    center: {
      lng: 100.5018,
      lat: 13.7563,
    },
    zoom: 6,
  };
  let user = loginStore.getLoginUser();
  suppliers.forEach((supplier) => {
    if (user.groups.includes("global_partner")) {
      var supplierObj = suppliersMap[supplier.supplier];
    } else {
      var supplierObj = suppliersMap[supplier];
    }
    console.log(supplierObj);
    if (!supplierObj && !supplierObj) {
      return null;
    } else if (
      supplierObj.lng &&
      supplierObj.lat &&
      Utils.isNumeric(supplierObj.lng) &&
      Utils.isNumeric(supplierObj.lat)
    ) {
      fullSuppliers.push(supplierObj);
    }
  });

  // Google Maps For Selected Supply Chain
  return (
    <div style={{ height: props.height, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {fullSuppliers.map((item) => {
          let textStr = (
            <div>
              {item.name}
              <br />
              {item.total_num_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_workers +
                    props.context.t("total workers")}
                </div>
              ) : null}
              {item.total_num_thai_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_thai_workers +
                    props.context.t("Thai workers")}
                </div>
              ) : null}
              {item.total_num_cambodian_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_cambodian_workers +
                    props.context.t("Cambodian workers")}
                </div>
              ) : null}
              {item.total_num_myanmar_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_myanmar_workers +
                    props.context.t("Myanmar workers")}
                </div>
              ) : null}
              {item.total_num_lao_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_lao_workers +
                    props.context.t("Lao workers")}
                </div>
              ) : null}
              {item.total_num_vietnamese_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_vietnamese_workers +
                    props.context.t("Vietanmese workers")}
                </div>
              ) : null}
            </div>
          );

          return (
            <AnyReactComponent
              key={Utils.giveMeGuid()}
              lat={item.lat}
              lng={item.lng}
              text={textStr}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

export default function PartnerLandingPage(props, context) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const supplyChains = useSelector((state) => state.supplyChainReducer.items);
  const supplyChainsMap = useSelector(
    (state) => state.supplyChainReducer.itemsMap
  );
  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const suppliersMap = useSelector((state) => state.suppliersReducer.itemsMap);

  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartners
  );
  const strategicPartnersMap = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartnersMap
  );

  const sharedFilesMap = useSelector(
    (state) => state.sharedFilesReducer.sharedFilesMap
  );

  // FILTERS
  const [filters, setFilters] = useState({
    suppliers: null,
    supplyChain: null,
    startTime: null,
    endTime: null,
    industry: null,
    subindustry: null,
    disableSupplyChainSelect: false,
    partner: null,
    search: "",
  });

  const [modal, setModal] = useState({
    open: false,
    id: null,
    type: null,
  });

  useEffect(() => {
    dispatch(fetchSharedFiles());
    dispatch(fetchSupplyChains());
    dispatch(fetchCountries());
    dispatch(fetchStrategicPartners());
    dispatch(fetchSuppliers()); // Done for child controls/modal
  }, []);

  useEffect(() => {
    if (
      strategicPartners &&
      strategicPartners.forEach !== undefined &&
      strategicPartners.length > 0 &&
      supplyChains &&
      supplyChains.forEach !== undefined &&
      supplyChains.length > 0
    ) {
      let partnerData = Utils.findPartnerAndSupplyChain(
        loginStore.getLoginUser(),
        supplyChains,
        strategicPartners
      );

      setFilters({
        ...filters,
        partner: partnerData.partnerFound,
        disableSupplyChainSelect: partnerData.foundSupplyChain !== null,
        supplyChain: partnerData.foundSupplyChain,
        suppliers:
          partnerData.foundSupplyChain && partnerData.foundSupplyChain.suppliers
            ? (() => {
                let array = [];
                partnerData.foundSupplyChain.suppliers.forEach((supplierId) => {
                  // array.push("" + supplierId);
                  array.push(supplierId);
                });
                return array;
              })()
            : null,
      });
    }
  }, [suppliers, strategicPartners, supplyChains]);

  // const accountgrid = (<div>
  //   <Timeline key={utils.giveMeGuid()} stories={[
  //     {
  //       badgeColor: "success",
  //       badgeIcon: HomeIcon,
  //       title: "Partner Joined",
  //       inverted: false,
  //       titleColor: "success",
  //       body: (
  //         <div>
  //           <p style={{ fontWeight: 500, margin: 0 }}>Partner Joined Issara</p>
  //           <p>Welcome to issara !!!</p>
  //         </div>
  //       ),
  //       footerTitle: (new Date()).toLocaleString()
  //     },
  //     {
  //       badgeColor: "success",
  //       badgeIcon: SupervisorAccountIcon,
  //       title: "Account Created",
  //       inverted: true,
  //       titleColor: "success",
  //       body: (
  //         <div>
  //           <p style={{ fontWeight: 500, margin: 0 }}>Account Created</p>
  //           <p>Login is now valid. Activation of Supply Chain Data.</p>
  //         </div>
  //       ),
  //       footerTitle: (new Date()).toLocaleString()
  //     }
  //   ]} />
  // </div>);

  if (!suppliers) {
    return (
      <GridContainer>
        <CircularProgress />
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      {(loginStore.isGlobalPartner() === false ||
        (loginStore.isGlobalPartner() === true &&
          (filters.supplyChain === null ||
            filters.supplyChain === undefined))) &&
      (suppliers === undefined ||
        suppliers === null ||
        suppliers.length < 1) ? (
        <GridContainer>
          <CircularProgress />
        </GridContainer>
      ) : /* Im doing this so I can make it show later if I wanted too */ filters.disableSupplyChainSelect ===
        true ? null : (
        <Card>
          <CardBody>
            <SupplyChainFilter
              key={Utils.giveMeGuid()}
              supplyChainValue={filters.supplyChain}
              startTime={filters.startTime}
              endTime={filters.endTime}
              industry={filters.industry}
              subindustry={filters.subindustry}
              suppliers={filters.suppliers}
              disableSupplyChainSelect={filters.disableSupplyChainSelect}
              onUpdateSummary={(selectedData) => {
                let suppliersMap = selectedData.suppliersMap;
                setFilters({
                  ...filters,
                  startTime: selectedData.startTime,
                  endTime: selectedData.endTime,
                  industry: selectedData.industry,
                  subindustry: selectedData.subindustry,
                  supplyChain: supplyChainsMap[selectedData.supplyChain],
                  suppliers: (() => {
                    let array = [];
                    for (const [key, value] of Object.entries(suppliersMap)) {
                      array.push(key);
                    }
                    return array;
                  })(),
                });
              }}
            />
          </CardBody>
        </Card>
      )}

      <GridContainer>
        {filters === null ||
        filters === undefined ||
        filters.supplyChain === null ||
        filters.supplyChain === undefined ||
        filters.supplyChain.strategic_partner === undefined ||
        !strategicPartnersMap[filters.supplyChain.strategic_partner] ? (
          <div />
        ) : (
          (() => {
            let partner =
              strategicPartnersMap[filters.supplyChain.strategic_partner];
            return (
              <PartnerMessageBoardList
                key={Utils.giveMeGuid()}
                partnerID={partner.id}
              />
            );
          })()
        )}

        <PartnerReferenceDocList
          key={Utils.giveMeGuid()}
          partnerId={filters?.supplyChain?.strategic_partner}
        />

        <NewsUpdateList key={Utils.giveMeGuid()} />

        <SharedFileList />
      </GridContainer>

      {!filters.suppliers ||
      filters.suppliers.length < 1 ||
      !suppliersMap ||
      Object.keys(suppliersMap).length < 1 ? null : (
        <GridContainer>
          <SuppliersMapPanel
            context={context}
            height={"70vh"}
            width={"100%"}
            suppliers={filters.suppliers}
            suppliersMap={suppliersMap}
          />
        </GridContainer>
      )}
    </GridContainer>
  );
}

PartnerLandingPage.contextTypes = {
  t: PropTypes.func.isRequired,
};
