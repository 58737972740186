import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import loginStore from "../../redux/stores/LoginStore";
import { fetchSuppliers } from "../../redux/actions/SupplierActions";
import {
  fetchStrategicPartners,
  fetchSupplyChains,
} from "../../redux/actions/StrategicPartnerActions";
import { fetchCountries } from "../../redux/actions/LocaleActions";
import Utils from "../../services/utils";
import Tooltip from "@material-ui/core/Tooltip";
import { fetchSharedFiles } from "../../redux/actions/StrategicPartnerActions";
import IssaraInstitute from "assets/img/issara_institute.png";

const customStyle = {
  ...styles,
  button: {
    padding: 0,
    margin: 0,
  },
  newInteractionGridItem: {
    // padding: '0 6px !important',
  },
  buttonBar: {
    marginLeft: "-15px !important",
  },
  gallery: {
    height: "100%",
    width: "100%",
    position: "relative",
  },

  galleryImg: {
    /* CSS Hack will make it width 100% and height 100% */
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    /* Maintain aspect ratio */
    maxHeight: "100%",
    maxWidth: "100%",
  },
  galleryContainer: {
    width: "40px",
    height: "40px",
  },
  galleryContainerBigger: {
    width: "100px",
    height: "100px",
  },
};

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function PartnerWelcomePage(props, context) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const supplyChains = useSelector((state) => state.supplyChainReducer.items);
  const suppliers = useSelector((state) => state.suppliersReducer.items);

  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartners
  );
  const strategicPartnersMap = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartnersMap
  );

  const sharedFilesMap = useSelector(
    (state) => state.sharedFilesReducer.sharedFilesMap
  );

  // FILTERS
  const [filters, setFilters] = useState({
    suppliers: null,
    supplyChain: null,
    startTime: null,
    endTime: null,
    industry: null,
    subindustry: null,
    disableSupplyChainSelect: false,
    partner: null,
    search: "",
  });

  useEffect(() => {
    dispatch(fetchSharedFiles());
    dispatch(fetchSupplyChains());
    dispatch(fetchCountries());
    dispatch(fetchStrategicPartners());
    dispatch(fetchSuppliers()); // Done for child controls/modal
  }, []);

  useEffect(() => {
    if (
      strategicPartners &&
      strategicPartners.forEach !== undefined &&
      strategicPartners.length > 0 &&
      supplyChains &&
      supplyChains.forEach !== undefined &&
      supplyChains.length > 0
    ) {
      let partnerData = Utils.findPartnerAndSupplyChain(
        loginStore.getLoginUser(),
        supplyChains,
        strategicPartners
      );

      setFilters({
        ...filters,
        partner: partnerData.partnerFound,
        disableSupplyChainSelect: partnerData.foundSupplyChain !== null,
        supplyChain: partnerData.foundSupplyChain,
        suppliers:
          partnerData.foundSupplyChain && partnerData.foundSupplyChain.suppliers
            ? (() => {
                let array = [];
                partnerData.foundSupplyChain.suppliers.forEach((supplierId) => {
                  // array.push("" + supplierId);
                  array.push(supplierId);
                });
                return array;
              })()
            : null,
      });
    }
  }, [suppliers, strategicPartners, supplyChains]);

  if (!suppliers) {
    return (
      <GridContainer>
        <CircularProgress />
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <div style={{ width: "100%", verticalAlign: "middle" }}>
        <Box
          component="span"
          display="block"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <GridContainer>
            <GridItem xs={1} md={1} lg={1}>
              <div className={classes.galleryContainer}>
                <div className={classes.gallery}>
                  <a target="__blank" href="https://www.issarainstitute.org">
                    <img className={classes.galleryImg} src={IssaraInstitute} />
                  </a>
                </div>
              </div>
            </GridItem>

            <GridItem xs={1} md={1} lg={1}>
              {filters === null ||
              filters === undefined ||
              filters.supplyChain === null ||
              filters.supplyChain === undefined ||
              filters.supplyChain.strategic_partner === undefined ||
              !strategicPartnersMap[filters.supplyChain.strategic_partner] ? (
                <div />
              ) : (
                (() => {
                  let partner =
                    strategicPartnersMap[filters.supplyChain.strategic_partner];
                  let image = sharedFilesMap[partner.imageicon]
                    ? sharedFilesMap[partner.imageicon]
                    : "";

                  return (
                    <div className={classes.galleryContainerBigger}>
                      <div className={classes.gallery}>
                        <a
                          target="__blank"
                          href={partner.website ? partner.website : ""}
                        >
                          <img
                            className={classes.galleryImg}
                            src={image.file_path}
                          />
                        </a>
                      </div>
                    </div>
                  );
                })()
              )}
            </GridItem>
          </GridContainer>
          <GridItem xs={12} md={12} lg={12}>
            <GridItem>
              <h4>{context.t("WELCOME!")}</h4>
              <div>
                <p>
                  {context.t(
                    "Welcome to Inclusive Labor Monitoring (ILM)! ILM aims to provide businesses with robust tools to strengthen their human rights due diligence and engagement with workers in their workplaces and supply chains. In ILM you will find tools to help monitor, plan, benchmark, remediate, and improve labor issues, systems, and business performance in global supply chains."
                  )}
                </p>

                <p>
                  {context.t(
                    "Confidentiality: The information in ILM is confidential to each user and is tailored to your own unique company or organization. All information provided via in ILM is governed by your company’s or organization's Partner agreement with Issara Institute. The information may not be shared externally."
                  )}
                </p>
                <p>
                  {context.t(
                    "Features: Issara will continue to advance features within the ILM, and we welcome your feedback and input to make ILM Suppliers as useful and impactful as possible! We are excited about the breadth of information, data analysis, and transparency that ILM can provide."
                  )}
                </p>

                <p>
                  {context.t(
                    "We look forward to our ongoing collaboration with you to support ethical labor recruitment, improved working conditions, and worker empowerment. If you have any questions, please contact your Issara Institute ILM focal point,"
                  )}
                  <a href="mailto: mark@issarainstitute.org">
                    <b>&nbsp;{context.t("Mark Taylor")}.</b>
                  </a>
                </p>
              </div>
            </GridItem>
          </GridItem>
        </Box>
      </div>
    </GridContainer>
  );
}

PartnerWelcomePage.contextTypes = {
  t: PropTypes.func.isRequired,
};
