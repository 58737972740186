import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Datetime from "react-datetime";
import Pagination from "components/Pagination/Pagination2.js";

import ReactTable from "react-table-6";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import CustomInput from "components/CustomInput/CustomInput.js";

import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js";
import ReferralOptionDropdown from "components/ilmdb/ReferralOptionDropdown.js";

import SupplierKPIModal from "./SupplierKPIModal";

import Utils from "../../services/utils.js";
import SupplierKpiDetailsSubPanel from "./SupplierKpiDetailsSubPanel.js";

import loginStore from "../../redux/stores/LoginStore";

// style
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  SupplyChainData,
  fetchSupplierKpiUpdates,
} from "../../redux/actions/SupplierActions.js";
import { fetchRecruiters } from "../../redux/actions/RecruiterActions.js";

import { fetchAllCalls } from "../../redux/actions/CallActions.js";

import ReactExport from "react-export-excel";
import { CircularProgress } from "@material-ui/core";
import CardText from "components/Card/CardText";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let excelFile = false;
const customStyle = {
  ...customCheckboxRadioSwitch,
  checkRoot: {
    padding: 0,
  },
  labelRoot: {
    margin: 0,
  },
  button: {
    padding: 0,
    margin: 0,
  },
  center: {
    textAlign: "center",
  },
};

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function downloadSupplierKpiList(data, context) {
  if (excelFile === true) {
    return (
      <GridContainer>
        <GridItem>
          <ExcelFile
            filename={"supplier_kpis"}
            element={<Button>{context.t("Download As XLS File")}</Button>}
          >
            <ExcelSheet data={data} name="supplier_kpis">
              <ExcelColumn label="id" value="id" />
              <ExcelColumn label="supplier_name" value="supplier_name" />
              <ExcelColumn label="category" value="category" />
              <ExcelColumn label="description" value="description" />
              <ExcelColumn label="level" value="level" />
              <ExcelColumn
                label="remediation_notes"
                value="remediation_notes"
              />
              <ExcelColumn label="opened_at" value="opened_at" />

              <ExcelColumn label="closed_at" value="closed_at" />
              <ExcelColumn
                label="Quality_of_the_closure"
                value="closed_quality"
              />
              <ExcelColumn label="days_open" value="days_open" />

              <ExcelColumn label="affected_workers" value="affected_workers" />
              <ExcelColumn label="status_name" value="status_name" />
              <ExcelColumn label="call_count" value="call_count" />
              <ExcelColumn
                label="source_recruiter_count"
                value="source_recruiter_count"
              />
              <ExcelColumn
                label="destination_recruiter_count"
                value="destination_recruiter_count"
              />
              <ExcelColumn
                label="total_update_count"
                value="total_update_count"
              />
            </ExcelSheet>
          </ExcelFile>
        </GridItem>
      </GridContainer>
    );
  } else {
    return (
      <GridContainer>
        <GridItem>
          <ExcelFile
            filename={"supplier_kpis"}
            element={<Button>{context.t("Download As XLS File")}</Button>}
          >
            <ExcelSheet data={data} name="supplier_kpis">
              <ExcelColumn label="id" value="id" />
              <ExcelColumn label="supplier_name" value="supplier_name" />
              <ExcelColumn label="category" value="category" />
              <ExcelColumn label="description" value="description" />
              <ExcelColumn label="level" value="level" />
              <ExcelColumn
                label="remediation_notes"
                value="remediation_notes"
              />
              <ExcelColumn label="opened_at" value="opened_at" />

              <ExcelColumn label="affected_workers" value="affected_workers" />
              <ExcelColumn label="status_name" value="status_name" />
              <ExcelColumn label="call_count" value="call_count" />
              <ExcelColumn
                label="source_recruiter_count"
                value="source_recruiter_count"
              />
              <ExcelColumn
                label="destination_recruiter_count"
                value="destination_recruiter_count"
              />
              <ExcelColumn
                label="total_update_count"
                value="total_update_count"
              />
            </ExcelSheet>
          </ExcelFile>
        </GridItem>
      </GridContainer>
    );
  }
}

export default function SupplierKPIsList(props, context) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { csoDisplay } = props;

  const supplierKpiSearchColumns = [
    "id",
    "affected_workers",
    "calls",
    "category",
    "closed_at",
    "closed_notes",
    "description",
    "level",
    "opened_at",
    "overview_date",
    "remediation_action",
    "remediation_aligned",
    "remediation_business_steps_remaining",
    "remediation_business_steps_taken",
    "remediation_documents_deadline",
    "remediation_documents_owed",
    "remediation_documents_provided",
    "remediation_issara_recommendation",
    "remediation_notes",
    "remediation_owed_baht",
    "remediation_owed_kyat",
    "remediation_owed_ringitt",
    "remediation_owed_usd",
    "remediation_paid_baht",
    "remediation_paid_kyat",
    "remediation_paid_ringitt",
    "remediation_paid_usd",
    "remediation_payment_deadline",
    "remediation_progress",
    "remediation_results",
    "remediation_validation",
    "remediation_workers_paid",
    "retaliation",
    "status",
    "status_name",
    "supplier",
    "supplier_kpi",
    "supplier_name",
    "systems_change_aligned",
    "systems_change_business_steps_remaining",
    "systems_change_business_steps_taken",
    "systems_change_deadline",
    "systems_change_issara_recommendation",
    "systems_change_progress",
    "call_count",
    "source_recruiter_count",
    "destination_recruiter_count",
    "total_update_count",
  ];

  const recruitersMap = useSelector(
    (state) => state.recruitersReducer.recruitersMap
  );
  const callsMap = useSelector(
    (state) => state.workerVoiceCaseCallsReducer.callsMap
  );

  const supplierKPIs = useSelector(
    (state) => state.suppliersReducer.supplierKPIs
  );
  const fetchingSupplierKPIs = useSelector(
    (state) => state.suppliersReducer.fetchingSupplierKPIs
  );

  const fetchingSupplierKpiUpdates = useSelector(
    (state) => state.suppliersReducer.fetchingSupplierKpiUpdates
  );

  const supplierKpiUpdates = useSelector(
    (state) => state.suppliersReducer.supplierKpiUpdates
  );

  const [
    searchFilteredSupplierKPIsArray,
    setSearchFilteredSupplierKPIsArray,
  ] = useState([]);
  const [
    searchFilteredSupplierKPIsHistoryArray,
    setSearchFilteredSupplierKPIsHistoryArray,
  ] = useState([]);
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [supplyChainChecked, setSupplyChainChecked] = useState(false);
  const [nonSupplyChainChecked, setNonSupplyChainChecked] = useState(false);
  const [filterByDay, setFilterByDay] = useState(false);
  const [days, setDays] = useState("");
  const [filterByDayValue, setFilterByDayValue] = useState(0);
  const [organizationId, setOrganizationId] = useState("");
  const _user = loginStore.getLoginUser();

  const handleDaysChange = (event, option) => {
    if (option == null) {
      setDays("");
      setFilterByDay(false);
      setFilterByDayValue(0);
    } else {
      setDays(option);
      setFilterByDay(true);
      setFilterByDayValue(option.value);
    }
  };
  const [modal, setModal] = useState({
    open: false,
    id: null,
  });

  const colClosedAt = {
    Header: context.t("Closed At"),
    accessor: "closed_at",
    width: 100,
  };

  const colClosure = {
    Header: context.t("Closure Date"),
    accessor: "closure_date",
    width: 120,
  };
  const open_days = {
    Header: context.t("Open days"),
    accessor: "xl",
    width: 120,
  };

  const colQuality_of_the_closure = {
    Header: context.t("Quality of the closure"),
    accessor: "Quality_of_the_closure",
    width: 120,
  };

  const supplierKpiUpdateHistoryInitCols = [
    {
      Header: context.t("id"),
      accessor: "id",
      width: 60,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">{props.value}</div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Supplier Kpi ID"),
      accessor: "supplier_kpi",
      width: 180,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {context.t(
              "This is the parent Supplier KPI ID.  This is ID in the Supplkier KPI List table above"
            )}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Supplier"),
      accessor: "supplier_name",
      width: 120,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">{props.value}</div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Category"),
      accessor: "category",
      width: 150,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {Utils.shortenString(props.value, 60)}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Description"),
      accessor: "description",
      width: 220,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {Utils.shortenString(props.value, 60)}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Level"),
      accessor: "level",
      width: 60,
    },
    {
      Header: context.t("Opened At"),
      accessor: "opened_at",
      width: 100,
    },
    {
      Header: context.t("# Affected"),
      accessor: "affected_workers",
      width: 100,
    },
    {
      Header: context.t("Status"),
      accessor: "status_name",
      width: 90,
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: "supplier_kpi",
      width: 60,
      Cell: (props) => viewButton(props.value),
    },
  ];

  const supplierKpiInitCols = [
    {
      Header: "",
      sortable: false,
      accessor: "id",
      width: 40,
      Cell: (props) => checkbox(props.value),
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: "center",
          },
        };
      },
    },
    {
      Header: context.t("id"),
      accessor: "id",
      width: 60,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">{props.value}</div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Supplier"),
      accessor: "supplier_name",
      width: 120,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">{props.value}</div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Country"),
      accessor: "country",
      width: 120,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {props.value}
            <br />
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Category"),
      accessor: "category",
      width: 150,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {Utils.shortenString(props.value, 60)}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Description"),
      accessor: "description",
      width: 220,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {Utils.shortenString(props.value, 60)}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Level"),
      accessor: "level",
      width: 60,
    },
    {
      Header: context.t("Opened At"),
      accessor: "opened_at",
      width: 100,
    },
    {
      Header: context.t("Affected"),
      accessor: "affected_workers",
      width: 90,
    },
    {
      Header: context.t("Status"),
      accessor: "status_name",
      width: 90,
    },
    {
      Header: context.t("Updates"),
      accessor: "total_update_count",
      width: 90,
    },
    {
      Header: context.t("Call #"),
      accessor: "call_count",
      width: 90,
    },
    {
      Header: context.t("days active"),
      accessor: "days_open",
      width: 105,
    },
    {
      Header: context.t("Calls"),
      accessor: "calls",
      width: 290,
      Cell: (props) => {
        return (
          <div>
            {props.value
              ? props.value.map((item, index) => {
                  return (
                    <span key={index} style={{ paddingLeft: "4px" }}>
                      {"("}
                      <a
                        title={context.t("Call Id: {item}", { item: item })}
                        // title={"Call Id: " + item}
                        href={"/admin/new-call?id=" + item}
                        target="__blank"
                      >
                        {item}
                      </a>
                      {")"}
                    </span>
                  );
                })
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: "id",
      width: 60,
      Cell: (props) => viewButton(props.value),
    },
  ];
  const xlm = [
    {
      Header: "",
      sortable: false,
      accessor: "id",
      width: 40,
      Cell: (props) => checkbox(props.value),
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: "center",
          },
        };
      },
    },
    {
      Header: context.t("id"),
      accessor: "id",
      width: 60,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">{props.value}</div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Supplier"),
      accessor: "supplier_name",
      width: 120,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">{props.value}</div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Category"),
      accessor: "category",
      width: 150,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {Utils.shortenString(props.value, 60)}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Description"),
      accessor: "description",
      width: 220,
      Cell: (props) => (
        <HtmlTooltip title={props.value} interactive>
          <div className="cell-overflow">
            {Utils.shortenString(props.value, 60)}
          </div>
        </HtmlTooltip>
      ),
    },
    {
      Header: context.t("Level"),
      accessor: "level",
      width: 60,
    },
    {
      Header: context.t("Opened At"),
      accessor: "opened_at",
      width: 100,
    },
    {
      Header: context.t("Affected"),
      accessor: "affected_workers",
      width: 90,
    },
    {
      Header: context.t("Status"),
      accessor: "status_name",
      width: 90,
    },
    {
      Header: context.t("Updates"),
      accessor: "total_update_count",
      width: 90,
    },
    {
      Header: context.t("Call #"),
      accessor: "call_count",
      width: 90,
    },
    {
      Header: context.t("Calls"),
      accessor: "calls",
      width: 290,
      Cell: (props) => {
        return (
          <div>
            {props.value
              ? props.value.map((item) => {
                  return (
                    <span style={{ paddingLeft: "4px" }}>
                      {"("}
                      <a
                        title={"Call Id: " + item}
                        href={"/admin/new-call?id=" + item}
                        target="__blank"
                      >
                        {item}
                      </a>
                      {")"}
                    </span>
                  );
                })
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: "id",
      width: 60,
      Cell: (props) => viewButton(props.value),
    },
  ];
  // FILTERS
  const [filters, setFilters] = useState({
    suppliers: null,
    startTime: null,
    endTime: null,
    showClosed: false,
    search: "",
    searchhistory: "",
    supplierKpiUpdateHistoryColumns: supplierKpiUpdateHistoryInitCols,
    supplierKpiColumns: xlm,
  });

  excelFile = filters.showClosed;

  const updateSupplierKpiUpdateHistoryColumns = () => {
    if (filters.showClosed === true) {
      return xlm;
    } else {
      let xl = xlm;
      xl.splice(7, 0, colClosedAt);
      xl.splice(8, 0, colClosure);
      return xl;
    }
  };

  const updateSupplierKpiColumns = () => {
    if (filters.showClosed === false) {
      return supplierKpiInitCols;
    } else {
      let xl = supplierKpiInitCols;
      xl.splice(7, 0, colClosedAt);
      xl.splice(8, 0, colClosure);
      xl.splice(9, 0, colQuality_of_the_closure);

      return xl;
    }
  };

  const filterSupplierKPIUpdates = () => {
    const filteredSupplierKPIsHistoryArray = [];

    Object.keys(supplierKpiUpdates).forEach((key) => {
      const item = supplierKpiUpdates[key];

      // Filter by supplier
      if (filters.suppliers) {
        if (filters.suppliers.includes(item.supplier) === false) {
          return; // skip this business response as no suppliers matched
        }
      }

      // Filter by startTime
      if (filters.startTime) {
        if (
          new Date(item.opened_at).getTime() < filters.startTime._d.getTime()
        ) {
          return; // skip this business response
        }
      }

      // Filter by endTime
      if (filters.endTime) {
        if (filters.endTime._d.getTime() < new Date(item.opened_at).getTime()) {
          return; // skip this business response
        }
      }

      // Show only open KPIs
      if (
        filters.showClosed === false &&
        (item.status === 3 || item.status === 4)
      ) {
        return; // skip this business response
      } else if (
        filters.showClosed === true &&
        (item.status !== 3 && item.status !== 4)
      ) {
        return; // skip this business response
      }

      filteredSupplierKPIsHistoryArray.push({
        ...item,
        status_name: Utils.getSupplierKpiStatusFromId(item.status),
        call_count:
          item.calls !== undefined &&
          item.calls !== null &&
          item.calls.length !== undefined
            ? item.calls.length
            : 0,
        source_recruiter_count:
          item.source_recruiters !== undefined &&
          item.source_recruiters !== null &&
          item.source_recruiters.length !== undefined
            ? item.source_recruiters.length
            : 0,
        destination_recruiter_count:
          item.destination_recruiters !== undefined &&
          item.destination_recruiters !== null &&
          item.destination_recruiters.length !== undefined
            ? item.destination_recruiters.length
            : 0,
        total_update_count:
          item.updates !== undefined &&
          item.updates !== null &&
          item.updates.length !== undefined
            ? item.updates.length
            : 0,
      });
    });

    if (
      filters.searchhistory !== undefined &&
      filters.searchhistory.length > 3
    ) {
      let filteredSearchData = Utils.findStringInObjectFields(
        filteredSupplierKPIsHistoryArray,
        filters.searchhistory,
        supplierKpiSearchColumns
      );
      setSearchFilteredSupplierKPIsHistoryArray(filteredSearchData);
    } else {
      setSearchFilteredSupplierKPIsHistoryArray(
        filteredSupplierKPIsHistoryArray
      );
    }
  };

  const filterSupplierKPIs = () => {
    const filteredSupplierKPIsArray = [];

    Object.keys(supplierKPIs).forEach((key) => {
      const item = supplierKPIs[key];
      // // Filter by supplier
      if (filters.suppliers) {
        if (filters.suppliers.includes(item.supplier) === false) {
          return; // skip this business response as no suppliers matched
        }
      }

      // // Filter by startTime
      if (filters.startTime) {
        if (
          new Date(item.opened_at).getTime() < filters.startTime._d.getTime()
        ) {
          return; // skip this business response
        }
      }

      // // Filter by endTime
      if (filters.endTime) {
        if (filters.endTime._d.getTime() < new Date(item.opened_at).getTime()) {
          return; // skip this business response
        }
      }

      // // Show only open KPIs
      if (
        filters.showClosed === false &&
        (item.status === 3 || item.status === 4)
      ) {
        return; // skip this business response
      } else if (
        filters.showClosed === true &&
        (item.status !== 3 && item.status !== 4)
      ) {
        return; // skip this business response
      }

      filteredSupplierKPIsArray.push({
        ...item,
        status_name: Utils.getSupplierKpiStatusFromId(item.status),
        call_count:
          item.calls !== undefined &&
          item.calls !== null &&
          item.calls.length !== undefined
            ? item.calls.length
            : 0,
        source_recruiter_count:
          item.source_recruiters !== undefined &&
          item.source_recruiters !== null &&
          item.source_recruiters.length !== undefined
            ? item.source_recruiters.length
            : 0,
        destination_recruiter_count:
          item.destination_recruiters !== undefined &&
          item.destination_recruiters !== null &&
          item.destination_recruiters.length !== undefined
            ? item.destination_recruiters.length
            : 0,
        total_update_count:
          item.updates !== undefined &&
          item.updates !== null &&
          item.updates.length !== undefined
            ? item.updates.length
            : 0,
      });
    });

    if (filters.search !== undefined && filters.search.length > 3) {
      let filteredSearchData = Utils.findStringInObjectFields(
        filteredSupplierKPIsArray,
        filters.search,
        supplierKpiSearchColumns
      );
      setSearchFilteredSupplierKPIsArray(filteredSearchData);
    } else {
      setSearchFilteredSupplierKPIsArray(filteredSupplierKPIsArray);
    }
  };

  const handleSelectKPI = (kpi_id) => {
    const newSelectedKPIs = [...selectedKPIs];
    const currentIndex = selectedKPIs.indexOf(kpi_id);

    if (currentIndex === -1) {
      newSelectedKPIs.push(kpi_id);
    } else {
      newSelectedKPIs.splice(currentIndex, 1);
    }
    setSelectedKPIs(newSelectedKPIs);
  };

  const handleViewButtonClick = (id) => {
    setModal({
      open: true,
      id: id,
    });
  };

  const checkbox = (kpi_id) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            onClick={() => handleSelectKPI(kpi_id)}
            checked={selectedKPIs.includes(kpi_id)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        classes={{
          // label: classes.label + ' ' + classes.customLabel,
          root: classes.labelRoot,
        }}
      />
    );
  };

  const viewButton = (kpi_id) => {
    return (
      <Button
        title={context.t("View:") + kpi_id}
        simple
        color="info"
        value={kpi_id}
        className={classes.button}
        onClick={(e) => handleViewButtonClick(e.currentTarget.value)}
      >
        <VisibilityIcon />
      </Button>
    );
  };

  useEffect(() => {
    dispatch(fetchSupplierKpiUpdates());
    dispatch(fetchRecruiters());
    dispatch(fetchAllCalls());
  }, []);

  useEffect(() => {
    if (organizationId !== "") {
      dispatch(
        fetchSupplierKpiUpdates(organizationId && organizationId)
      );
      dispatch(
        SupplyChainData(
          supplyChainChecked,
          nonSupplyChainChecked,
          filterByDay,
          filterByDayValue,
          loginStore.isCSO() ? null: organizationId,
          loginStore.isCSO() ? _user?.id: null
        )
      );
    }
  }, [organizationId]);

  useEffect(() => {
    filterSupplierKPIs();
    filterSupplierKPIUpdates();
  }, [supplierKpiUpdates, filters, supplierKPIs]);

  useEffect(() => {
    dispatch(
      SupplyChainData(
        supplyChainChecked,
        nonSupplyChainChecked,
        filterByDay,
        filterByDayValue,
        loginStore.isCSO() ? null: organizationId,
        loginStore.isCSO() ? _user?.id: null
      )
    );
  }, [
    supplyChainChecked,
    nonSupplyChainChecked,
    filterByDay,
    filterByDayValue,
  ]);

  const handleClickUpdateKPIs = () => {
    const ids = selectedKPIs.toString();
    props.history.push(`/admin/supplier-kpi-bulk-update?ids=${ids}`);
  };

  const handleChange = (event) => {
    if (event.target.name == "checkedsupply") {
      setSupplyChainChecked(!supplyChainChecked);
    } else if (event.target.name == "checkednonsupply") {
      setNonSupplyChainChecked(!nonSupplyChainChecked);
    }
  };

  const ActiveDaysOptions = [
    { label: context.t("less than 15 days"), value: "0-15" },
    { label: context.t("16-30"), value: "16-30" },
    { label: context.t("31-45"), value: "31-45" },
    { label: context.t("46-60"), value: "46-60" },
    { label: context.t("61-90"), value: "61-90" },
    { label: context.t("90+"), value: "90+" },
  ];

  return (
    <>
      {fetchingSupplierKPIs || fetchingSupplierKpiUpdates ? (
        <div>
          <CircularProgress />
        </div>
      ) : null}
      {csoDisplay === true && (
        <div>
          <GridContainer>
            <GridItem sm={6} lg={6}>
              <ReferralOptionDropdown
                multipleselect={true}
                value={organizationId}
                onSelect={(e) => {
                  let organizationId = e.target.value ? e.target.value : "";
                  setOrganizationId(organizationId);
                }}
              />
            </GridItem>
          </GridContainer>
        </div>
      )}

      <GridContainer>
        <SupplierKPIModal
          open={modal.open}
          value={modal.id}
          onClose={() => setModal({ ...modal, open: false })}
          historical={modal.historical}
          onHistorySelect={(historicalModalData) =>
            setModal({ ...modal, ...historicalModalData })
          }
        />

        <GridItem xs={12}>
          {csoDisplay !== true && (
            <Card style={{ marginTop: 0 }}>
              <CardBody>
                <GridContainer justifyContent="center">
                  <GridItem xs={6} sm={4} lg={4} xl={4}>
                    <SuppliersDropdown
                      label={context.t("Suppliers")}
                      multipleselect={true}
                      value={filters.suppliers}
                      onSelect={(supplier) =>
                        setFilters({ ...filters, suppliers: supplier })
                      }
                    />
                  </GridItem>
                  <GridItem xs={6} sm={2} lg={2} xl={1}>
                    <InputLabel className={classes.label}>
                      {context.t("Between")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        locale="en"
                        value={filters.startTime}
                        timeFormat={false}
                        inputProps={{ placeholder: context.t("Start") }}
                        onChange={(date) =>
                          typeof date === "object" &&
                          setFilters({ ...filters, startTime: date })
                        }
                        dateFormat="YYYY-MM"
                        closeOnSelect={true}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6} sm={2} lg={2} xl={1}>
                    <InputLabel className={classes.label}>
                      {context.t("And")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        locale="en"
                        value={filters.endTime}
                        timeFormat={false}
                        inputProps={{ placeholder: context.t("End") }}
                        onChange={(date) =>
                          typeof date === "object" &&
                          setFilters({ ...filters, endTime: date })
                        }
                        dateFormat="YYYY-MM"
                        closeOnSelect={true}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={2} lg={2} xl={2}>
                    <FormControl>
                      <Button
                        color="rose"
                        onClick={() => {
                          setFilters({
                            ...filters,
                            suppliers: null,
                            startTime: null,
                            endTime: null,
                          });
                          dispatch(
                            SupplyChainData(
                              supplyChainChecked,
                              nonSupplyChainChecked,
                              filterByDay,
                              filterByDayValue,
                              loginStore.isCSO() ? null: organizationId,
                              loginStore.isCSO() && _user?.id
                            )
                          );
                          dispatch(
                            fetchSupplierKpiUpdates(
                              organizationId && organizationId
                            )
                          );
                        }}
                      >
                        {context.t("Reset all")}
                      </Button>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          )}
        </GridItem>

        <GridItem xs={12}>
          <Card style={{ marginTop: 0 }}>
            <CardText style={{ color: "white" }}>
              {context.t("Filter by Days Active")}
            </CardText>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      options={ActiveDaysOptions}
                      onChange={(event, option) =>
                        handleDaysChange(event, option)
                      }
                      getOptionLabel={(option) => option.label || ""}
                      value={days}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={context.t("Active Days")}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        {loginStore.isIssaraManagement() === true && (
          <GridItem>
            {searchFilteredSupplierKPIsArray !== undefined &&
            searchFilteredSupplierKPIsArray.length !== undefined &&
            searchFilteredSupplierKPIsArray.length > 0
              ? downloadSupplierKpiList(
                  searchFilteredSupplierKPIsArray,
                  context
                )
              : null}
          </GridItem>
        )}
        <GridContainer justifyContent="flex-end">
          {loginStore.isIssaraStaff() === true ? (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={supplyChainChecked}
                    onChange={handleChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                    name="checkedsupply"
                    // color="primary"
                  />
                }
                label={
                  <div style={{ marginLeft: "8px" }}>
                    {context.t("Supply Chain")}
                  </div>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={nonSupplyChainChecked}
                    onChange={handleChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                    name="checkednonsupply"
                    // color="primary"
                  />
                }
                label={
                  <div style={{ marginLeft: "8px" }}>
                    {context.t("Non-Supply Chain")}
                  </div>
                }
              />
            </div>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={filters.showClosed}
                onClick={() => {
                  setFilters({
                    ...filters,
                    showClosed: !filters.showClosed,
                    supplierKpiUpdateHistoryColumns: updateSupplierKpiUpdateHistoryColumns(),
                  }); //supplierKpiColumns: updateSupplierKpiColumns()  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={
              <div style={{ marginLeft: "8px" }}>
                {context.t("Show Closed")}
              </div>
            }
          />
        </GridContainer>

        {/* Current Supplier KPI List*/}
        <GridItem xs={12}>
          <h4 className={classes.center}>{context.t("Supplier KPIs")}</h4>

          <Card style={{ marginTop: 0 }}>
            <CardHeader>
              <h4>
                {context.t("Search Results")} ({context.t("Found")}-
                {searchFilteredSupplierKPIsArray !== undefined &&
                searchFilteredSupplierKPIsArray.length !== undefined
                  ? searchFilteredSupplierKPIsArray.length
                  : 0}
                )
              </h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} lg={12}>
                <HtmlTooltip
                  title={context.t(
                    "You can enter a text or number thats used in the object.  Call IDs Also work. (You'll get the supplier KPIs that have that Call ID in the drop down panel or info view"
                  )}
                  interactive
                >
                  <div className="cell-overflow">
                    <FormControl fullWidth>
                      <CustomInput
                        id={"search_text"}
                        labelText={context.t(
                          "Search (Fuzzy Search Across all fields)"
                        )}
                        isTextArea={false}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={filters.search}
                        inputProps={{
                          onChange: (e) => {
                            setFilters({
                              ...filters,
                              search:
                                e.target.value === undefined
                                  ? ""
                                  : e.target.value,
                            });
                          },
                        }}
                      />
                    </FormControl>
                  </div>
                </HtmlTooltip>
              </GridItem>
              <GridItem>
                <br />
              </GridItem>

              <GridItem xs={12} sm={12} lg={12}>
                <Card>
                  <CardBody>
                    <ReactTable
                      PaginationComponent={Pagination}
                      data={searchFilteredSupplierKPIsArray}
                      defaultFilterMethod={(filter, row) =>
                        Utils.findStringInObjectFields(
                          [row._original],
                          filter.value,
                          [filter.id]
                        ).length > 0
                      }
                      filterable={true}
                      collapseOnDataChange={false}
                      SubComponent={(row) => (
                        <SupplierKpiDetailsSubPanel
                          supplierKPI={supplierKPIs[row.original.id]}
                          recruitersMap={recruitersMap}
                          callsMap={callsMap}
                          supplierKpiUpdates={supplierKpiUpdates}
                        />
                      )}
                      defaultSorted={[
                        {
                          id:
                            filters.showClosed === false
                              ? "opened_at"
                              : "closed_at",
                          desc: true,
                        },
                      ]}
                      columns={updateSupplierKpiColumns()}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      loading={fetchingSupplierKPIs === true}
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </CardBody>
          </Card>
          {selectedKPIs.length > 0 && (
            <FormControl>
              <Button color="rose" onClick={handleClickUpdateKPIs}>
                {context.t("Update selected KPIs")}
              </Button>
            </FormControl>
          )}
        </GridItem>

        {/* Current Supplier KPI HISTORY List*/}
        {/* TODO: FIX CASE VISIBILITY ISSUE AND RE-ENABLE /*}
        {/*<GridItem xs={12}>
          <h4 className={classes.center}>
            {context.t("Supplier KPI Updated History")}
          </h4>
          <Card style={{ marginTop: 0 }}>
            <CardHeader>
              <h4>
                {context.t("Search Results")} ({context.t("Found")}-
                {searchFilteredSupplierKPIsHistoryArray !== undefined &&
                searchFilteredSupplierKPIsHistoryArray.length !== undefined
                  ? searchFilteredSupplierKPIsHistoryArray.length
                  : 0}
                )
              </h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} lg={12}>
                <FormControl fullWidth>
                  <CustomInput
                    id={"search_text"}
                    labelText={context.t(
                      "Search (Fuzzy Search Across all fields)"
                    )}
                    isTextArea={false}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={filters.searchhistory}
                    inputProps={{
                      onChange: (e) => {
                        setFilters({
                          ...filters,
                          searchhistory:
                            e.target.value === undefined ? "" : e.target.value,
                        });
                      },
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <br />
              </GridItem>

              <GridItem xs={12} sm={12} lg={12}>
                <Card>
                  <CardBody>
                    <ReactTable
                      PaginationComponent={Pagination}
                      data={searchFilteredSupplierKPIsHistoryArray}
                      defaultFilterMethod={(filter, row) =>
                        Utils.findStringInObjectFields(
                          [row._original],
                          filter.value,
                          [filter.id]
                        ).length > 0
                      }
                      filterable={true}
                      collapseOnDataChange={false}
                      SubComponent={(row) => (
                        <SupplierKpiDetailsSubPanel
                          supplierKPI={supplierKPIs[row.original.supplier_kpi]}
                          recruitersMap={recruitersMap}
                          callsMap={callsMap}
                          supplierKpiUpdates={supplierKpiUpdates}
                        />
                      )}
                      defaultSorted={[
                        {
                          id: "opened_at",
                          desc: true,
                        },
                      ]}
                      columns={filters.supplierKpiUpdateHistoryColumns}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      loading={fetchingSupplierKPIs === true}
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </CardBody>
          </Card>
          {selectedKPIs.length > 0 && (
            <FormControl>
              <Button color="rose" onClick={handleClickUpdateKPIs}>
                {context.t("Update selected KPIs")}
              </Button>
            </FormControl>
          )}
        </GridItem>*/}
      </GridContainer>
    </>
  );
}

SupplierKPIsList.contextTypes = {
  t: PropTypes.func.isRequired,
};
