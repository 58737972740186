import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from "moment";
import Box from "@material-ui/core/Box";
import loginStore from "../../redux/stores/LoginStore";
import GrievanceForm from "./GrievanceForm";
const GrievanceComponent = (props) => {
  const data = props.data;
  const context = props.context;
  const getDaysActive = props.getDaysActive;
  const getStatusColor = props.getStatusColor;

  const user = loginStore.getLoginUser();
  return (
    <GridContainer spacing={1}>
      <GridItem xs={4} md={4}>
        <Box>
          <GridItem>
            <p style={{ margin: 0 }}>
              <b>{context.t("KPI ID :")}</b>
              {data.id || context.t("-")}
            </p>
          </GridItem>
          <GridItem>
            <p style={{ margin: 0 }}>
              <b>{context.t("KPI :")}</b>
              {data.description || context.t("-")}
            </p>
          </GridItem>
          <GridItem>
            <p style={{ margin: 0 }}>
              <b>{context.t("Opened since: ")}</b>
              {(data.opened_at && moment(data.opened_at).format("LL")) ||
                context.t("-")}
            </p>
          </GridItem>
          <GridItem>
            {user.groups.includes("Supplier") ? (
              data.affected_workers == null ||
              (data.affected_workers <= 10 &&
                data.affected_workers !== undefined) ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("1-10")}
                </p>
              ) : data.affected_workers > 10 &&
                data.affected_workers <= 50 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("11-50")}
                </p>
              ) : data.affected_workers > 50 &&
                data.affected_workers <= 100 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("51-100")}
                </p>
              ) : data.affected_workers > 100 &&
                data.affected_workers <= 500 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("101-500")}
                </p>
              ) : data.affected_workers > 500 &&
                data.affected_workers <= 1000 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b>{" "}
                  {context.t("501-1000")}
                </p>
              ) : data.affected_workers > 1000 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("1000+")}
                </p>
              ) : null
            ) : (
              <p style={{ margin: 0 }}>
                <b>{context.t("Affected workers: ")}</b>
                {data.affected_workers !== undefined &&
                data.affected_workers !== null
                  ? data.affected_workers
                  : context.t("-")}
              </p>
            )}
          </GridItem>
          {user.groups.includes("Supplier") && !data.closed_at && (
            <>
              <GridItem xs={12}>
                <p style={{ margin: 0 }}>
                  <b>{context.t("Level: ")}</b>
                  {data.level}
                </p>
              </GridItem>

              <GridItem xs={12}>
                <p style={{ margin: 0 }}>
                  <b>{context.t("Day active: ")}</b>
                  {getDaysActive(data.opened_at)}
                </p>
              </GridItem>

              {data.remediation_notes && (
                <>
                  <GridItem
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p style={{ margin: 0, marginRight: "8px" }}>
                      <b>{context.t("Status: ")}</b>
                    </p>
                    <div
                      className="statusIndicator"
                      style={{
                        margin: "left",
                        backgroundColor: getStatusColor(
                          getDaysActive(data.opened_at),
                          data.status
                        ),
                      }}
                    ></div>
                  </GridItem>

                  <GridItem xs={12}>
                    <p style={{ margin: 0, textAlign: "justify" }}>
                      <b>{context.t("Issues: ")}</b> {data.remediation_notes}
                    </p>
                  </GridItem>
                </>
              )}
            </>
          )}
        </Box>
      </GridItem>
      <GridItem xs={8} md={8}>
        <GrievanceForm context={context} kpi_id={data.id} />
      </GridItem>
    </GridContainer>
  );
};

GrievanceComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GrievanceComponent;
