import React, { useState, useEffect, useRef } from "react";

import ComponentToPrint from "./ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import Utils from "../../services/utils";
import Pagination from "components/Pagination/Pagination2.js";
import PropTypes from "prop-types";

// react plugin for creating charts

import C3Chart from "react-c3js";
import "c3/c3.css";
import * as d3 from "d3";
import Tooltip from "@material-ui/core/Tooltip";

import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Edit from "@material-ui/icons/Edit";

import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table-6";

import HttpService from "../../services/HttpService";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import ReferralOptionDropdown from "../../components/ilmdb/ReferralOptionDropdown.js";
import NationalitiesDropdown from "../../components/ilmdb/NationalitiesDropdown";
import { fetchRecruiters } from "../../redux/actions/RecruiterActions";
import { fetchSuppliers } from "../../redux/actions/SupplierActions";
import { fetchFieldworkActivities } from "../../redux/actions/TeamActivityActions";

import FieldworkActivityModal from "./FieldworkActivityModal";

import moment from "moment";
import ReactExport from "react-export-excel";
import loginStore from "../../redux/stores/LoginStore";
// import { getMomentLocale } from "languages/i18n/utilities/utilities";
import { fetchNationalities } from "redux/actions/LocaleActions";
import SuppliersPaginateDropdown from "components/Async/SuppliersPaginateDropdown";

var _user = loginStore.getLoginUser();
if (_user) {
  var _group = _user.groups.includes("CSO");
}

// TODO: REMOVE AFTER DEMO
const isDemo = _user?.username == "csodemo@issarainstitute.org";
// console.log("current User: ", _user.username)
// console.log("USER:", _user)
// console.log("isDemo: ", isDemo)
const demoStats = {
  total: 350,
  men: 165,
  women: 180,
  unknown: 5,
  ethnicities: [
    { "name": "Rakhine", "amount": 70 },
    { "name": "Burman", "amount": 90 },
    { "name": "Dawei", "amount": 20 },
    { "name": "Mon", "amount": 60 },
    { "name": "Chin", "amount": 50 },
    { "name": "Shan", "amount": 15 },
    { "name": "Kachin", "amount": 25 },
    { "name": "Kayin", "amount": 20 }
  ],
}

require("chartist-plugin-legend");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const customStyles = {
  ...styles,
  largeStatContainer: {
    paddingTop: "6.3rem",
    paddingBottom: "6.3rem",
    margin: "10px 0 0 0",
    zIndex: 1,
  },
  largeStatNumber: {
    textAlign: "center",
    fontSize: "3.5rem",
    fontWeight: 500,
    minHeight: "2.5rem",
  },
  largeStatName: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: 400,
  },
  smallStatContainer: {
    paddingTop: "1.8rem",
    paddingBottom: "1rem",
    margin: "0 0 1rem 0",
    zIndex: 1,
  },
  smallStatNumber: {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: 600,
    minHeight: "1.65rem",
  },
  smallStatName: {
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: 400,
  },
  outreachTable: {
    padding: "1rem 1rem 1rem 1rem",
    margin: "10px 0 1rem 0",
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  button: {
    padding: 0,
    margin: 0,
    cursor: "pointer;",
  },
  gaugeContainer: {
    textAlign: "center",
    width: "190px",
    height: "190px",
  },
  chartcontainerholder: {
    minWidth: "850px",
  },
};

const useStyles = makeStyles(customStyles);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

/*
 "id": 345,
            "fieldwork_date": "2020-08-23",
            "created_by": 43,
            "fieldwork_type_name": "O&E9. Worker empowerment session",
            "province_name": "Phetchaburi",
            "nationality_name": "Cambodia",
            "location": "Khao Yoi, Phetchaburi, Thailand",
            "outreach_target": 15,
            "fieldwork_notes": "The Cambodian team estimated to reach 15 workers. We could meet around 14 workers to conduct FGD for Khmer GD name, to meet with potential ambassadors at Petchaburi in order to re-engaged for potential ambassadors to provide training, identify the volunteer potential ambassadors to do testimony video on impact of engagement with ISSARA. The workers were interested and were very happy for participation in each activities. We did meet with its expected.",
            "created_at": "2020-10-02",
            "fieldwork_type": 9,
            "nationality": 2,
            "country": 8,
            "province": 64,
            "district": 463,
            "suppliers": [
                1110
            ],
            "recruiters": []
*/
class DownloadFieldWork extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dataset: props.dataset,
    };
  }

  render() {
    return (
      <GridContainer>
        <GridItem>
          <ExcelFile
            filename={"field_work"}
            element={<Button>{this.context.t("Download As XLS File")}</Button>}
          >
            <ExcelSheet data={this.state.dataset} name="field_work">
              <ExcelColumn label="id" value="id" />

              <ExcelColumn
                label="fieldwork_type_name"
                value="fieldwork_type_name"
              />
              <ExcelColumn label="province_name" value="province_name" />
              <ExcelColumn label="nationality_name" value="nationality_name" />
              <ExcelColumn label="location" value="location" />
              <ExcelColumn label="outreach_target" value="outreach_target" />
              <ExcelColumn label="fieldwork_notes" value="fieldwork_notes" />
              <ExcelColumn label="outreach_target" value="outreach_target" />
              <ExcelColumn label="fieldwork_type" value="fieldwork_type" />
              <ExcelColumn label="nationality" value="nationality" />
              <ExcelColumn label="country" value="country" />
              <ExcelColumn label="province" value="province" />
              <ExcelColumn label="district" value="district" />
              <ExcelColumn label="supplier_names" value="supplier_names" />
              <ExcelColumn label="recruiter_names" value="recruiter_names" />
            </ExcelSheet>
          </ExcelFile>
        </GridItem>
      </GridContainer>
    );
  }
}

/**
 *
 * @param {*} classes
 * @param {*} context
 * @param {*} modal
 * @param {*} setModal
 * @param {*} csoDisplay
 * @param {*} filters
 * @param {*} setFilters
 * @returns
 */
function IssaraActivityDashboardFilterPanel(
  classes,
  context,
  modal,
  setModal,
  csoDisplay,
  filters,
  setFilters
) {
  return (
    <>
      <GridContainer className={classes.marginBottom}>
        <FieldworkActivityModal
          open={modal.open}
          value={modal.id}
          onClose={() => setModal({ ...modal, open: false })}
        />
        {csoDisplay !== true && (
          <GridItem sm={6} lg={6}>
            <SuppliersPaginateDropdown
              isMulti={true}
              value={filters.suppliers}
              onSelect={(suppliers) =>
                setFilters({
                  ...filters,
                  suppliers:
                    suppliers && suppliers.length < 1 ? null : suppliers,
                })
              }
            />
          </GridItem>
        )}

        {csoDisplay === true && (
          <GridContainer className={classes.marginBottom}>
            <GridItem sm={6} lg={6}>
              <ReferralOptionDropdown
                multipleselect={true}
                onSelect={(e) => {
                  let organizationId = e.target.value;

                  if (typeof organizationId === "string") {
                    organizationId = organizationId.trim();
                    if (organizationId === "" || isNaN(organizationId)) {
                      organizationId = null;
                    }
                  } else if (isNaN(organizationId)) {
                    organizationId = null;
                  }

                  setFilters({
                    ...filters,
                    organizationId: organizationId,
                  });
                }}
              />
            </GridItem>
          </GridContainer>
        )}
      </GridContainer>

      <GridContainer>
        {csoDisplay === true && (
          <GridItem sm={6} lg={6}>
            <NationalitiesDropdown
              multipleselect={true}
              label={context.t("Nationalities")}
              value={filters.nationalities}
              onSelect={(nationalities) => {
                setFilters({
                  ...filters,
                  nationalities:
                    nationalities && nationalities.length < 1
                      ? null
                      : nationalities,
                });

                alert(
                  context.t("Filter by Nationalities is not yet implemented")
                );
              }}
            />
          </GridItem>
        )}

        <GridItem xs={6} sm={2} lg={2} xl={1}>
          <InputLabel className={classes.label}>
            {context.t("Between")}
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              locale="en"
              // "Set the locale to 'en' for now because data filtering won't work properly otherwise."
              // locale={getMomentLocale(lang)}
              value={filters.startTime}
              timeFormat={false}
              inputProps={{ placeholder: context.t("Start") }}
              onChange={(date) => {
                let newDate =
                  !date || date === "" ? moment("2018-01-01") : date;
                setFilters({ ...filters, startTime: newDate });
              }}
              closeOnSelect={true}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={6} sm={2} lg={2} xl={1}>
          <InputLabel className={classes.label}>{context.t("And")}</InputLabel>
          <FormControl fullWidth>
            <Datetime
              locale="en"
              // locale={getMomentLocale(lang)}
              value={filters.endTime}
              timeFormat={false}
              inputProps={{ placeholder: context.t("End") }}
              onChange={(date) => {
                let newDate = !date || date === "" ? moment() : date;
                setFilters({ ...filters, endTime: newDate });
              }}
              closeOnSelect={true}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={2} lg={2} xl={2}>
          <FormControl justify="center">
            <Button
              color="rose"
              onClick={() =>
                setFilters({
                  suppliers: null,
                  src_recruiters: null,
                  dest_recruiters: null,
                  startTime: moment("2018-01-01"),
                  endTime: moment(),
                })
              }
            >
              {context.t("Reset all")}
            </Button>
          </FormControl>
        </GridItem>
      </GridContainer>
    </>
  );
}

/**
 *
 * @param {*} classes
 * @param {*} context
 * @param {*} fetchingMonthlyWorkerVoice
 * @param {*} montlyWorkerVoiceFiltered
 * @param {*} colorsGenerator
 * @param {*} fetchingInteractions
 * @param {*} workerInteractions
 * @param {*} ermatrics
 * @param {*} totalWorkerCalls
 * @param {*} fetchingFieldworkActivities
 * @param {*} filteredFieldworkActivities
 * @param {*} totalFieldVisits
 * @param {*} totalCount
 * @param {*} totalSumList
 * @returns
 */
function IssaraActivityDashboardDisplayPanel(
  classes,
  context,
  fetchingMonthlyWorkerVoice,
  montlyWorkerVoiceFiltered,
  colorsGenerator,
  fetchingInteractions,
  workerInteractions,
  ermatrics,
  totalWorkerCalls,
  fetchingFieldworkActivities,
  filteredFieldworkActivities,
  totalFieldVisits,
  totalCount,
  totalSumList
) {

  // TODO: REMOVE AFTER DEMO
  const getDemoDemographics = () => {
    return demoStats.ethnicities.map((item) => {
      return (
        <GridItem xs={6} sm={4} md={4} lg={4}>
          <Card className={classes.smallStatContainer}>
            <CardHeader>
              <div
                className={`${classes.cardTitle} ${classes.smallStatNumber}`}
              >
                {item.amount}
              </div>
              <p
                className={`${classes.cardCategory} ${classes.smallStatName}`}
              >
                {item.name}
              </p>
            </CardHeader>
          </Card>
        </GridItem>
      );
    });
  }

  return (
    <>
      <GridContainer>
        {
          <GridItem xs={12}>
            <Card className={classes.chartcontainerholder}>
              <CardHeader>
                {fetchingMonthlyWorkerVoice ? (
                  <CircularProgress />
                ) : !montlyWorkerVoiceFiltered ||
                  montlyWorkerVoiceFiltered.length < 2 ? (
                  <div>{context.t("No Data found")}</div>
                ) : (
                  <C3Chart
                    data={{
                      x: "x",
                      columns: [
                        [
                          "x",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.month_year
                          ),
                        ],
                        [
                          "Burmese Facebook messenger",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.mm_fb_total
                          ),
                        ],
                        [
                          "Burmese Viber in Thailand",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.mm_viber_total
                          ),
                        ],
                        [
                          "Burmese hotline in Thailand",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.mm_hotline_total
                          ),
                        ],
                        [
                          "Burmese LINE messages",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.mm_line_total
                          ),
                        ],
                        [
                          "Burmese hotline in Myanmar",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.yangon_hotline_total
                          ),
                        ],
                        [
                          "Khmer hotline",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.cb_hotline_total
                          ),
                        ],
                        [
                          "Khmer Facebook messenger",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.cb_fb_total
                          ),
                        ],
                        [
                          "Khmer LINE",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.cb_line_total
                          ),
                        ],
                        [
                          "Thai Hotline",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.thai_hotline_total
                          ),
                        ],
                        [
                          "Thai Facebook",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.thai_fb_total
                          ),
                        ],
                        [
                          "Thai LINE",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.thai_line_total
                          ),
                        ],
                        [
                          "Indonesian Whatsapp Total",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.my_indonesian_whatsapp_total
                          ),
                        ],
                        [
                          "Indonesian Hotline Total",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.my_indonesia_hotline_total
                          ),
                        ],
                        [
                          "Nepalese Whatsapp Total",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.my_nepal_whatsapp_total
                          ),
                        ],
                        [
                          "Nepalese Hotline Total",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.nepal_hotline_total
                          ),
                        ],
                        [
                          "Nepalese Facebook Total",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.nepal_facebook_total
                          ),
                        ],
                        [
                          "Burmese Whatsapp Total",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.my_burmese_whatsapp_total
                          ),
                        ],
                        [
                          "Burmese Viber in Malaysia",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.my_burmese_viber_total
                          ),
                        ],
                        [
                          "Burmese Tiktok messages",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.burmese_titok_messages
                          ),
                        ],
                        [
                          "Nepalese Tiktok messages",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.nepalese_tiktok_messages
                          ),
                        ],
                        [
                          "Bangladeshi Facebook Messenger",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.bangladeshi_facebook_messenger
                          ),
                        ],
                        [
                          "Bangladeshi Hotline",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.bangladeshi_hotline
                          ),
                        ],
                        [
                          "Bangladeshi WhatsApp",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.bangladeshi_whatsapp
                          ),
                        ],
                        [
                          "Bangladeshi Tiktok messages",
                          ...montlyWorkerVoiceFiltered.map(
                            (item) => item.bangladeshi_tiktok_messages
                          ),
                        ],
                      ],

                      types: {
                        "Burmese hotline in Thailand": "bar",
                        "Burmese hotline in Myanmar": "bar",
                        "Burmese Facebook messenger": "bar",
                        "Burmese LINE messages": "bar",
                        "Burmese Viber in Thailand": "bar",
                        "Khmer hotline": "bar",
                        "Khmer Facebook messenger": "bar",
                        "Khmer LINE": "bar",
                        "Thai Hotline": "bar",
                        "Thai Facebook": "bar",
                        "Indonesian Whatsapp Total": "bar",
                        "Indonesian Hotline Total": "bar",
                        "Nepalese Whatsapp Total": "bar",
                        "Nepalese Hotline Total": "bar",
                        "Nepalese Facebook Total": "bar",
                        "Burmese Whatsapp Total": "bar",
                        "Burmese Viber in Malaysia": "bar",
                        "Burmese Tiktok messages": "bar",
                        "Nepalese Tiktok messages": "bar",
                        "Bangladeshi Facebook Messenger": "bar",
                        "Bangladeshi Hotline": "bar",
                        "Bangladeshi WhatsApp": "bar",
                        "Bangladeshi Tiktok messages": "bar",
                      },

                      groups: [
                        [
                          "Burmese Facebook messenger",
                          "Burmese Viber in Thailand",
                          "Burmese hotline in Thailand",
                          "Burmese LINE messages",
                          "Burmese hotline in Myanmar",
                          "Khmer hotline",
                          "Khmer Facebook messenger",
                          "Khmer LINE",
                          "Thai Hotline",
                          "Thai Facebook",
                          "Indonesian Whatsapp Total",
                          "Indonesian Hotline Total",
                          "Nepalese Whatsapp Total",
                          "Nepalese Hotline Total",
                          "Nepalese Facebook Total",
                          "Burmese Whatsapp Total",
                          "Burmese Viber in Malaysia",
                          "Burmese Tiktok messages",
                          "Nepalese Tiktok messages",
                          "Bangladeshi Facebook Messenger",
                          "Bangladeshi Hotline",
                          "Bangladeshi WhatsApp",
                          "Bangladeshi Tiktok messages",
                        ],
                      ],

                      colors: {
                        "Burmese Facebook messenger": colorsGenerator(0),
                        "Burmese Viber in Thailand": colorsGenerator(1),
                        "Burmese hotline in Thailand": colorsGenerator(2),
                        "Burmese LINE messages": colorsGenerator(3),
                        "Burmese hotline in Myanmar": colorsGenerator(4),
                        "Khmer hotline": colorsGenerator(5),
                        "Khmer Facebook messenger": colorsGenerator(6),
                        "Khmer LINE": colorsGenerator(7),
                        "Thai Hotline": colorsGenerator(8),
                        "Thai Facebook": colorsGenerator(9),

                        "Indonesian Whatsapp Total": colorsGenerator(10),
                        "Indonesian Hotline Total": colorsGenerator(11),
                        "Nepalese Whatsapp Total": colorsGenerator(12),
                        "Nepalese Hotline Total": colorsGenerator(13),
                        "Nepalese Facebook Total": colorsGenerator(14),
                        "Burmese Whatsapp Total": colorsGenerator(15),
                        "Burmese Viber in Malaysia": colorsGenerator(16),
                        "Burmese Tiktok messages": colorsGenerator(17),
                        "Nepalese Tiktok messages": colorsGenerator(18),
                        "Bangladeshi Facebook Messenger": colorsGenerator(19),
                        "Bangladeshi Hotline": colorsGenerator(20),
                        "Bangladeshi WhatsApp": colorsGenerator(21),
                        "Bangladeshi Tiktok messages": colorsGenerator(22),
                      },
                    }}
                    grid={{
                      x: {
                        show: true,
                        tick: {
                          fit: false,
                          show: false,
                          //count: 29,
                          rotate: -75,
                          multiline: false,
                        },
                      },
                      y: {
                        show: true,
                      },
                    }}
                    bar={{
                      width: 10, // this makes bar width 100px
                    }}
                    legend={{
                      position: "bottom",
                    }}
                    point={{
                      show: false,
                    }}
                    tooltip={{
                      show: true,
                      contents: function(
                        d,
                        defaultTitleFormat,
                        defaultValueFormat,
                        color
                      ) {
                        var $$ = this,
                          config = $$.config,
                          titleFormat =
                            config.tooltip_format_title || defaultTitleFormat,
                          nameFormat =
                            config.tooltip_format_name ||
                            function(name) {
                              return name;
                            },
                          valueFormat =
                            config.tooltip_format_value || defaultValueFormat,
                          text,
                          i,
                          title,
                          value,
                          name,
                          bgcolor;
                        for (i = 0; i < d.length; i++) {
                          if (
                            d[i] === null ||
                            d[i] === undefined ||
                            d[i].value === null ||
                            d[i].value === undefined ||
                            d[i].value < 1
                          ) {
                            continue;
                          }

                          if (!text) {
                            title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                            text =
                              "<table class='" +
                              $$.CLASS.tooltip +
                              "'>" +
                              (title || title === 0
                                ? "<tr><th colspan='2'>" + title + "</th></tr>"
                                : "");
                          }
                          name = nameFormat(d[i].name);
                          value = valueFormat(
                            d[i].value,
                            d[i].ratio,
                            d[i].id,
                            d[i].index
                          );
                          bgcolor = $$.levelColor
                            ? $$.levelColor(d[i].value)
                            : color(d[i].id);
                          text +=
                            "<tr class='" +
                            $$.CLASS.tooltipName +
                            "-" +
                            d[i].id +
                            "'>";
                          text +=
                            "<td class='name'><span style='background-color:" +
                            bgcolor +
                            "'></span>" +
                            name +
                            "</td>";
                          text += "<td class='value'>" + value + "</td>";
                          text += "</tr>";
                        }
                        return text + "</table>";
                      },
                    }}
                    axis={{
                      x: {
                        type: "timeseries",
                        tick: {
                          format: "%Y-%m-%d",
                        },
                      },
                    }}
                  />
                )}
              </CardHeader>
            </Card>
          </GridItem>
        }
      </GridContainer>

      <h3 className={classes.center}> {context.t("Worker Metrics")} </h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} lg={3}>
          <Card className={classes.largeStatContainer}>
            <CardHeader>
              <div
                className={`${classes.cardTitle} ${classes.largeStatNumber}`}
              >
                {fetchingInteractions ? (
                  <CircularProgress />
                ) : (
                  isDemo ? demoStats.total : workerInteractions.caseDataSummary.total
                )}
              </div>
              <p className={`${classes.cardCategory} ${classes.largeStatName}`}>
                {context.t("Total Worker Cases")}
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={9}>
          <GridContainer>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <CardHeader>
                  <div
                    className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                  >
                    {fetchingInteractions ? (
                      <CircularProgress size={25} />
                    ) : (
                      isDemo ? demoStats.men: workerInteractions.caseDataSummary.men
                    )}
                  </div>
                  <p
                    className={`${classes.cardCategory} ${classes.smallStatName}`}
                  >
                    {context.t("Men")}
                  </p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <CardHeader>
                  <div
                    className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                  >
                    {fetchingInteractions ? (
                      <CircularProgress size={25} />
                    ) : (
                      isDemo ? demoStats.women :workerInteractions.caseDataSummary.women
                    )}
                  </div>
                  <p
                    className={`${classes.cardCategory} ${classes.smallStatName}`}
                  >
                    {context.t("Women")}
                  </p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <CardHeader>
                  <div
                    className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                  >
                    {fetchingInteractions ? (
                      <CircularProgress size={25} />
                    ) : (
                      isDemo ? demoStats.unknown : workerInteractions.caseDataSummary.total -
                      (workerInteractions.caseDataSummary.women +
                        workerInteractions.caseDataSummary.men)
                    )}
                  </div>
                  <p
                    className={`${classes.cardCategory} ${classes.smallStatName}`}
                  >
                    {context.t("Unknown")}
                  </p>
                </CardHeader>
              </Card>
            </GridItem>

            {fetchingInteractions ? (
              <CircularProgress size={25} />
            ) : (
              isDemo ? getDemoDemographics() : workerInteractions.ethnicityByCallCount.map((item) => {
                return (
                  <GridItem xs={6} sm={4} md={4} lg={4}>
                    <Card className={classes.smallStatContainer}>
                      <CardHeader>
                        <div
                          className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                        >
                          {item.client_ethnicity__count}
                        </div>
                        <p
                          className={`${classes.cardCategory} ${classes.smallStatName}`}
                        >
                          {item.client_ethnicity__name}
                        </p>
                      </CardHeader>
                    </Card>
                  </GridItem>
                );
              })
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>

      <h3 className={classes.center}>
        {context.t("Worker Voice and Ethical Recruitment metrics")}
      </h3>
      {ermatrics.length == 0 ? (
        <CircularProgress size={35} />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.workers_voice_channels == null
                    ? 0
                    : ermatrics.workers_voice_channels}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Workers Connected To Worker Voice Channels")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.suppliers_trained == null
                    ? 0
                    : ermatrics.suppliers_trained}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Suppliers Trained")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.workers_pre_departure_mm == null
                    ? 0
                    : ermatrics.workers_pre_departure_mm}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Workers Reached In Pre-Departure MM")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.workers_receiving_remedition == null
                    ? 0
                    : ermatrics.workers_receiving_remedition}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Workers Receiving Remedition")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.workers_trained == null
                    ? 0
                    : ermatrics.workers_trained}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Trained Workers Leaders/Ambassadors")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.recruitment_agencies_trained == null
                    ? 0
                    : ermatrics.recruitment_agencies_trained}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Recruitment Agencies Trained")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.workers_pre_departure_ne == null
                    ? 0
                    : ermatrics.workers_pre_departure_ne}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Workers Reached In Pre-Departure NE")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.remediation_for_recruitment_fees_and_cases == null
                    ? 0
                    : ermatrics.remediation_for_recruitment_fees_and_cases}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t("Remediation For Recruitment Fees and Cases")}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card className={classes.smallStatContainer}>
              <CardHeader>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {ermatrics.recruitment_related_fees_detected_and_intercepted ==
                  null
                    ? 0
                    : ermatrics.recruitment_related_fees_detected_and_intercepted}
                </div>
                <p
                  className={`${classes.cardCategory} ${classes.smallStatName}`}
                >
                  {context.t(
                    "Recruitment-Related Fees Detected and Intercepted"
                  )}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      )}

      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.chartcontainerholder}>
            <CardHeader>
              {!totalWorkerCalls || !totalWorkerCalls.x ? (
                <CircularProgress />
              ) : totalWorkerCalls.x.length < 2 ? (
                <div>{context.t("No Data Found")}</div>
              ) : (
                <C3Chart
                  key={Utils.giveMeGuid()}
                  data={{
                    x: "x",
                    columns: [totalWorkerCalls.x, totalWorkerCalls.y],
                    types: {
                      worker_calls_count_by_month: "area-spline",
                    },
                  }}
                  grid={{
                    x: {
                      show: true,
                      tick: {
                        fit: true,
                        show: false,
                        rotate: -75,
                        multiline: false,
                      },
                    },
                    y: {
                      show: true,
                    },
                  }}
                  legend={{
                    position: "bottom",
                  }}
                  point={{
                    show: false,
                  }}
                  axis={{
                    x: {
                      type: "timeseries",
                      tick: {
                        format: "%Y-%m",
                      },
                    },
                  }}
                />
              )}
            </CardHeader>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.largeStatContainer}>
            <CardHeader>
              <div
                className={`${classes.cardTitle} ${classes.largeStatNumber}`}
              >
                {fetchingFieldworkActivities ? (
                  <CircularProgress size={25} />
                ) : (
                  filteredFieldworkActivities.length
                )}
              </div>
              <p className={`${classes.cardCategory} ${classes.largeStatName}`}>
                {context.t("Total field visits")}
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card className={classes.chartcontainerholder}>
            <CardHeader>
              {!totalFieldVisits ? (
                <CircularProgress />
              ) : totalFieldVisits.x.length < 2 ? (
                <div>{context.t("No Data found")}</div>
              ) : (
                <C3Chart
                  key={Utils.giveMeGuid()}
                  data={{
                    x: "x",
                    columns: [totalFieldVisits.x, totalFieldVisits.y],
                    types: {
                      field_visit_count_by_month: "area-spline",
                    },
                  }}
                  grid={{
                    x: {
                      show: true,
                      tick: {
                        fit: true,
                        show: false,
                        rotate: -75,
                        multiline: false,
                      },
                    },
                    y: {
                      show: true,
                    },
                  }}
                  legend={{
                    position: "bottom",
                  }}
                  point={{
                    show: false,
                  }}
                  axis={{
                    x: {
                      type: "timeseries",
                      tick: {
                        format: "%Y-%m",
                      },
                    },
                  }}
                />
              )}
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.largeStatContainer}>
            <CardHeader>
              <div
                className={`${classes.cardTitle} ${classes.largeStatNumber}`}
              >
                {totalCount}
              </div>
              <p className={`${classes.cardCategory} ${classes.largeStatName}`}>
                {context.t("Total Workers Reached")}
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card className={classes.chartcontainerholder}>
            <CardHeader>
              {!totalSumList ? (
                <CircularProgress />
              ) : totalSumList.x.length < 2 ? (
                <div>{context.t("No Data Found")}</div>
              ) : (
                <C3Chart
                  key={Utils.giveMeGuid()}
                  data={{
                    x: "x",
                    columns: [totalSumList.x, totalSumList.y],
                    types: {
                      field_total_workers_reached_by_month: "area-spline",
                    },
                  }}
                  grid={{
                    x: {
                      show: true,
                      tick: {
                        fit: true,
                        show: false,
                        rotate: -75,
                        multiline: false,
                      },
                    },
                    y: {
                      show: true,
                    },
                  }}
                  legend={{
                    position: "bottom",
                  }}
                  point={{
                    show: false,
                  }}
                  axis={{
                    x: {
                      type: "timeseries",
                      tick: {
                        format: "%Y-%m",
                      },
                    },
                  }}
                />
              )}
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

/**
 *
 * @param {*} currentUser
 * @param {*} filteredFieldworkActivities
 * @param {*} context
 * @param {*} filters
 * @param {*} setFilters
 * @param {*} classes
 * @param {*} fetchingSuppliers
 * @param {*} fetchingRecruiters
 * @param {*} fetchingFieldworkActivities
 * @param {*} viewEditButton
 * @param {*} viewFieldworkActivityButton
 * @returns
 */
function IssaraActivityDashboardTablePanel(
  currentUser,
  filteredFieldworkActivities,
  context,
  filters,
  setFilters,
  classes,
  fetchingSuppliers,
  fetchingRecruiters,
  fetchingFieldworkActivities,
  viewEditButton,
  viewFieldworkActivityButton
) {
  return (
    <GridContainer>
      {currentUser &&
        currentUser.groups &&
        currentUser.groups.includes("Issara Management") && (
          <GridItem xs={12} md={12} lg={12}>
            {filteredFieldworkActivities !== undefined &&
            filteredFieldworkActivities.length !== undefined &&
            filteredFieldworkActivities.length > 0 ? (
              <DownloadFieldWork
                key={Utils.giveMeGuid()}
                dataset={filteredFieldworkActivities}
              />
            ) : null}
          </GridItem>
        )}

      <GridItem xs={12}>
        <h4>
          {context.t("Search Results")}({context.t("Found")}-
          {filteredFieldworkActivities ? filteredFieldworkActivities.length : 0}
          )
        </h4>
      </GridItem>

      <GridItem xs={12} sm={12} lg={12}>
        <FormControl fullWidth>
          <CustomInput
            id={"search_text"}
            labelText={context.t("Search (Fuzzy Search Across all fields)")}
            isTextArea={false}
            formControlProps={{
              fullWidth: true,
            }}
            value={filters.search}
            inputProps={{
              onChange: (e) => {
                setFilters({
                  ...filters,
                  search: e.target.value === undefined ? "" : e.target.value,
                });
              },
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <br />
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card className={classes.outreachTable}>
          {
            <ReactTable
              PaginationComponent={Pagination}
              defaultFilterMethod={(filter, row) =>
                Utils.findStringInObjectFields([row._original], filter.value, [
                  filter.id,
                ]).length > 0
              }
              filterable={true}
              data={filteredFieldworkActivities}
              key={Utils.giveMeGuid()}
              defaultPageSize={5}
              className="-striped -highlight"
              loading={
                fetchingSuppliers ||
                fetchingRecruiters ||
                fetchingFieldworkActivities
              }
              defaultSorted={[
                {
                  id: "fieldwork_date",
                  desc: true,
                },
              ]}
              columns={[
                {
                  Header: context.t("ID"),
                  accessor: "id",
                  maxWidth: 90,
                  sortable: true,
                },
                {
                  Header: context.t("Date"),
                  accessor: "fieldwork_date",
                  maxWidth: 100,
                  sortable: true,
                },
                {
                  Header: context.t("Organization"),
                  accessor: "organization_name",
                  maxWidth: 250,
                  sortable: true,
                },
                {
                  Header: context.t("Type"),
                  accessor: "fieldwork_type_name",
                  sortable: true,
                  maxWidth: 200,
                  Cell: (props) => (
                    <HtmlTooltip title={props.value} interactive>
                      <div className="cell-overflow">{props.value}</div>
                    </HtmlTooltip>
                  ),
                },
                {
                  Header: context.t("Suppliers"),
                  accessor: "supplier_names",
                  sortable: true,
                  maxWidth: 200,
                  Cell: (props) => (
                    <HtmlTooltip title={props.value} interactive>
                      <div className="cell-overflow">{props.value}</div>
                    </HtmlTooltip>
                  ),
                },
                {
                  Header: context.t("Recruiters"),
                  accessor: "recruiter_names",
                  sortable: true,
                  maxWidth: 200,
                  Cell: (props) => (
                    <HtmlTooltip title={props.value} interactive>
                      <div className="cell-overflow">{props.value}</div>
                    </HtmlTooltip>
                  ),
                },
                {
                  Header: context.t("Location"),
                  accessor: "location",
                  sortable: true,
                  width: 120,
                  Cell: (props) => (
                    <HtmlTooltip title={props.value} interactive>
                      <div className="cell-overflow">{props.value}</div>
                    </HtmlTooltip>
                  ),
                },
                {
                  Header: context.t("Target"),
                  accessor: "outreach_target",
                  width: 80,
                },
                {
                  Header: context.t("Notes"),
                  accessor: "notes",
                  sortable: true,
                  Cell: (props) => (
                    <HtmlTooltip title={props.value} interactive>
                      <div className="cell-overflow">
                        {Utils.shortenString(props.value, 80)}
                      </div>
                    </HtmlTooltip>
                  ),
                },
                {
                  Header: context.t("Links"),
                  accessor: "links",
                  sortable: true,
                  Cell: (props) => (
                    <HtmlTooltip title={props.value} interactive>
                      <div className="cell-overflow">
                        {Utils.shortenString(props.value, 80)}
                      </div>
                    </HtmlTooltip>
                  ),
                },
                {
                  Header: context.t("Total Worker"),
                  accessor: "total_workers_reached",
                  sortable: true,
                },
                {
                  Header: "",
                  sortable: false,
                  accessor: "id",
                  width: 30,
                  Cell: (props) => {
                    if (_group === true) {
                      if (_user.id == props.original["created_by"]) {
                        return viewEditButton(props.value);
                      } else {
                        return null;
                      }
                    } else {
                      return viewEditButton(props.value);
                    }
                  },
                },
                {
                  Header: "",
                  sortable: false,
                  accessor: "id",
                  width: 30,
                  Cell: (props) => viewFieldworkActivityButton(props.value),
                },
                {
                  Header: "",
                  sortable: false,
                  width: 20,
                },
              ]}
            />
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default function IssaraActivityDashboard(props, context) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { csoDisplay } = props;

  const [
    filteredFieldworkActivities,
    setFilteredFieldworkActivities,
  ] = useState([]);
  const [fetchingInteractions, setFetchingInteractions] = useState(true);
  const [monthlyWorkerVoice, setMonthlyWorkerVoice] = useState([]);
  const [ermatrics, setERMatrics] = useState([]);
  const fieldworkActivities = useSelector(
    (state) => state.teamActivityReducer.fieldworkActivities
  );
  const lang = useSelector((state) => state.i18nState.lang);
  const fetchingFieldworkActivities = useSelector(
    (state) => state.teamActivityReducer.fetchingFieldworkActivities
  );
  const [workerInteractions, setWorkerInteractions] = useState({});
  const [fetchingMonthlyWorkerVoice, setFetchingMonthlyWorkerVoice] = useState(
    true
  );

  const recruiters = useSelector((state) => state.recruitersReducer.recruiters);
  const fetchingRecruiters = useSelector(
    (state) => state.recruitersReducer.fetchingRecruiters
  );
  const suppliers = useSelector(
    (state) => state.suppliersReducer.suppliers_items
  );
  const fetchingSuppliers = useSelector(
    (state) => state.suppliersReducer.fetchingSuppliers
  );

  const nationalities = useSelector(
    (state) => state.nationalitiesReducer.items
  );

  const [totalWorkerCalls, setTotalWorkerCalls] = useState({
    x: ["x"],
    y: [context.t("worker_calls_count_by_month")],
  });

  const [totalFieldVisits, setTotalFieldVisits] = useState({
    x: ["x"],
    y: [context.t("field_visit_count_by_month")],
  });

  const [totalFieldWorkersReached, setTotalFieldWorkersReached] = useState(0);
  const [totalWorkersReached, setTotalWorkersReached] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [
    totalFieldWorkersReachedList,
    setTotalFieldWorkersReachedList,
  ] = useState({ x: ["x"], y: ["field_workers_reached_by_month"] });
  const [totalWorkersList, setTotalWorkersList] = useState({
    x: ["x"],
    y: [context.t("field_total_workers_reached_by_month")],
  });
  const [totalSumList, setTotalSumList] = useState({
    x: ["x"],
    y: [context.t("field_total_workers_reached_by_month")],
  });

  const colorsGenerator = d3
    .scaleSequential(d3.interpolateRainbow)
    .domain([0, 11]);

  // FILTERS
  const [filters, setFilters] = useState({
    suppliers: null,
    nationalities: null,
    src_recruiters: null,
    dest_recruiters: null,
    startTime: moment("2018-01-01"),
    endTime: moment(),
    search: "",
    organizationId: null,
  });

  const [modal, setModal] = useState({
    open: false,
    id: null,
  });

  const fetchMetrics = () => {
    dispatch(fetchFieldworkActivities(filters.organizationId));

    let url1 = filters.organizationId
      ? `${process.env.REACT_APP_API_URL}/er-metrics-count/?organization_id=${
          filters.organizationId
        }&start_timestamp=${(filters.startTime && filters.startTime.unix()) ||
          ""}&end_timestamp=${(filters.endTime && filters.endTime.unix()) ||
          ""}`
      : `${
          process.env.REACT_APP_API_URL
        }/er-metrics-count/?start_timestamp=${(filters.startTime &&
          filters.startTime.unix()) ||
          ""}&end_timestamp=${(filters.endTime && filters.endTime.unix()) ||
          ""}`;

    HttpService.get(
      url1,
      (response) => {
        setERMatrics(response.time);
      },
      (err) => {
        console.log(err);
      }
    );

    let url2 = filters.organizationId
      ? `${process.env.REACT_APP_API_URL}/er-metrics-count/?organization_id=${
          filters.organizationId
        }&start_timestamp=${(filters.startTime && filters.startTime.unix()) ||
          ""}&end_timestamp=${(filters.endTime && filters.endTime.unix()) ||
          ""}`
      : `${
          process.env.REACT_APP_API_URL
        }/er-metrics-count/?start_timestamp=${(filters.startTime &&
          filters.startTime.unix()) ||
          ""}&end_timestamp=${(filters.endTime && filters.endTime.unix()) ||
          ""}`;

    HttpService.get(
      url2,
      (response) => {
        setERMatrics(response.time);
      },
      (err) => {
        console.log(err);
      }
    );

    setFetchingInteractions(true);

    let url3 = filters.organizationId
      ? `${
          process.env.REACT_APP_API_URL
        }/metrics/interactions?organization_id=${
          filters.organizationId
        }&start=${(filters.startTime && filters.startTime.unix()) ||
          ""}&end=${(filters.endTime && filters.endTime.unix()) ||
          ""}&supplierIds=${
          filters.suppliers ? "" + filters.suppliers : ""
        }&dest_recruiter_Ids=${
          filters.dest_recruiter_Ids ? "" + filters.dest_recruiter_Ids : ""
        }&src_recruiterIds=${
          filters.src_recruiters ? "" + filters.src_recruiters : ""
        }`
      : `${
          process.env.REACT_APP_API_URL
        }/metrics/interactions?start=${(filters.startTime &&
          filters.startTime.unix()) ||
          ""}&end=${(filters.endTime && filters.endTime.unix()) ||
          ""}&supplierIds=${
          filters.suppliers ? "" + filters.suppliers : ""
        }&dest_recruiter_Ids=${
          filters.dest_recruiter_Ids ? "" + filters.dest_recruiter_Ids : ""
        }&src_recruiterIds=${
          filters.src_recruiters ? "" + filters.src_recruiters : ""
        }`;

    HttpService.get(
      url3,
      (interactions) => {
        setWorkerInteractions(interactions);
        setFetchingInteractions(false);
      },
      (err) => {
        console.log(err.message);
      }
    );

    setFetchingMonthlyWorkerVoice(true);

    let url4 = filters.organizationId
      ? `${
          process.env.REACT_APP_API_URL
        }/metrics/monthly-worker-voice?organization_id=${
          filters.organizationId
        }&start=${(filters.startTime && filters.startTime.unix()) ||
          ""}&end=${(filters.endTime && filters.endTime.unix()) || ""}`
      : `${
          process.env.REACT_APP_API_URL
        }/metrics/monthly-worker-voice?start=${(filters.startTime &&
          filters.startTime.unix()) ||
          ""}&end=${(filters.endTime && filters.endTime.unix()) || ""}`;

    HttpService.get(
      url4,
      (response) => {
        setMonthlyWorkerVoice(response);
        setFetchingMonthlyWorkerVoice(false);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const filterWorkerVoiceCaseCallsMetrics = () => {
    // FILTER FIELDWORK ACTIVITIES

    const totalWorkerCallsNew = {
      x: ["x"],
      y: ["worker_calls_count_by_month"],
    };

    workerInteractions &&
      workerInteractions.caseCountByYearMonth &&
      workerInteractions.caseCountByYearMonth.forEach((item) => {
        totalWorkerCallsNew.x.push(item.month_year + "-01");
        totalWorkerCallsNew.y.push(item.call_count);
      });

    setTotalWorkerCalls(totalWorkerCallsNew);
  };

  const filterFieldActivityMetrics = () => {
    // FILTER FIELDWORK ACTIVITIES
    let fieldworkActivitiesToBeFiltered = ((mapObject) => {
      let asArray = [];
      Object.keys({ ...mapObject }).map((key) => {
        asArray.push(mapObject[key]);
      });
      return asArray;
    })(fieldworkActivities);

    const fieldworkActivitiesFiltered = [];
    const mapDateFieldfield_visit_count_by_month = new Map();
    const mapDateFieldfield_workers_reached_by_month = new Map();
    const mapDateFieldfield_total_workers_reached_by_month = new Map();
    const mapDateFieldfield_total_sum_reached_by_month = new Map();

    const totalFieldVisitsNew = { x: ["x"], y: ["field_visit_count_by_month"] };
    const totalFieldWorkersReachedListNew = {
      x: ["x"],
      y: ["field_workers_reached_by_month"],
    };
    const totalWorkersListNew = {
      x: ["x"],
      y: ["field_total_workers_reached_by_month"],
    };
    const totalSumListNew = {
      x: ["x"],
      y: ["field_total_workers_reached_by_month"],
    };
    let totalFieldWorkersReachedNew = 0;
    let totalWorkersReachedNew = 0;
    let totalSum = 0;

    if (filters.search !== undefined && filters.search.length > 1) {
      fieldworkActivitiesToBeFiltered = Utils.findStringInObjectFields(
        fieldworkActivitiesToBeFiltered,
        filters.search,
        [
          "id",
          "fieldwork_date",
          "fieldwork_type_name",
          "supplier_names",
          "recruiter_names",
          "location",
          "outreach_target",
          "fieldwork_notes",
        ]
      );
    }

    fieldworkActivitiesToBeFiltered.forEach((fieldworkActivity) => {
      let fieldWorkDate = new Date(fieldworkActivity.fieldwork_date);
      let monthKey =
        "" +
        fieldWorkDate.getFullYear() +
        "-" +
        (fieldWorkDate.getMonth() + 1 < 10
          ? "0" + (fieldWorkDate.getMonth() + 1)
          : fieldWorkDate.getMonth() + 1) +
        "-01";

      let pastSupplierTest =
        !filters.suppliers ||
        (() => {
          let found = false;
          filters.suppliers.forEach((supplierId) => {
            if (found === false) {
              found = fieldworkActivity.suppliers.includes(supplierId);
            }
          });

          return found;
        })() === true;

      let pastRecruiterTest =
        !filters.recruiters ||
        (() => {
          let found = false;
          filters.recruiters.forEach((recruiterId) => {
            if (found === false) {
              found = fieldworkActivity.recruiters.includes(recruiterId);
            }
          });

          return found;
        })() === true;

      let afterTimePastTest =
        !filters.startTime ||
        fieldWorkDate.getTime() >= new Date(filters.startTime).getTime();
      let beforeTimePastTest =
        !filters.endTime ||
        fieldWorkDate.getTime() <= new Date(filters.endTime).getTime();

      if (
        pastSupplierTest === true &&
        pastRecruiterTest &&
        afterTimePastTest === true &&
        beforeTimePastTest === true
      ) {
        fieldworkActivity.supplier_names = ((suppliers_values) => {
          const names = suppliers_values
            .map((item) => {
              const supplier = suppliers.find((s) => s.id === item);
              return supplier?.name;
            })
            .filter(Boolean)
            .join(", ");

          return names || "-";
        })(fieldworkActivity.suppliers);

        fieldworkActivity.recruiter_names = ((recruiters_values) => {
          let names = [];
          if (recruiters && recruiters.length > 0) {
            const filteredRecruiters = recruiters_values.map((item) => {
              return recruiters.find((element) => {
                return element.id === item;
              });
            });
            names = filteredRecruiters.map((item) => {
              return item && item.name;
            });
          }
          return names.join(", ") || "-";
        })(fieldworkActivity.recruiters);

        fieldworkActivitiesFiltered.push(fieldworkActivity);

        if (
          fieldworkActivity.fieldwork_date !== null &&
          fieldworkActivity.fieldwork_date !== undefined &&
          fieldworkActivity.fieldwork_date !== ""
        ) {
          if (mapDateFieldfield_visit_count_by_month.has(monthKey) === false) {
            mapDateFieldfield_visit_count_by_month.set(monthKey, 1);
          } else {
            mapDateFieldfield_visit_count_by_month.set(
              monthKey,
              mapDateFieldfield_visit_count_by_month.get(monthKey) + 1
            );
          }

          let targetNumber = fieldworkActivity.outreach_target
            ? fieldworkActivity.outreach_target
            : 0;
          totalFieldWorkersReachedNew =
            totalFieldWorkersReachedNew + targetNumber;
          // if (mapDateFieldfield_workers_reached_by_month.has(monthKey) === false) {
          //   mapDateFieldfield_workers_reached_by_month.set(monthKey, targetNumber);
          // } else {
          //   mapDateFieldfield_workers_reached_by_month.set(monthKey, mapDateFieldfield_workers_reached_by_month.get(monthKey) + targetNumber);
          // }

          let targetWorkerNumber = fieldworkActivity.total_workers_reached
            ? fieldworkActivity.total_workers_reached
            : 0;
          totalWorkersReachedNew = totalWorkersReachedNew + targetWorkerNumber;
          // if (mapDateFieldfield_total_workers_reached_by_month.has(monthKey) === false) {
          //   mapDateFieldfield_total_workers_reached_by_month.set(monthKey, targetWorkerNumber);
          // } else {
          //   mapDateFieldfield_total_workers_reached_by_month.set(monthKey, mapDateFieldfield_total_workers_reached_by_month.get(monthKey) + targetWorkerNumber);
          // }

          let total = targetNumber + targetWorkerNumber;
          totalSum = totalSum + total;
          if (
            mapDateFieldfield_total_sum_reached_by_month.has(monthKey) === false
          ) {
            mapDateFieldfield_total_sum_reached_by_month.set(monthKey, total);
          } else {
            mapDateFieldfield_total_sum_reached_by_month.set(
              monthKey,
              mapDateFieldfield_total_sum_reached_by_month.get(monthKey) + total
            );
          }
        }
      }
    });

    let iterator1 = mapDateFieldfield_visit_count_by_month.keys();
    for (
      var next = iterator1.next();
      !next || !next.done;
      next = iterator1.next()
    ) {
      let key = next.value;
      let value = mapDateFieldfield_visit_count_by_month.get(key);

      totalFieldVisitsNew.x.push(key);
      totalFieldVisitsNew.y.push(value);
    }

    iterator1 = mapDateFieldfield_workers_reached_by_month.keys();
    for (
      var next = iterator1.next();
      !next || !next.done;
      next = iterator1.next()
    ) {
      let key = next.value;
      let value = mapDateFieldfield_workers_reached_by_month.get(key);

      totalFieldWorkersReachedListNew.x.push(key);
      totalFieldWorkersReachedListNew.y.push(value);
    }

    iterator1 = mapDateFieldfield_total_workers_reached_by_month.keys();
    for (
      var next = iterator1.next();
      !next || !next.done;
      next = iterator1.next()
    ) {
      let key = next.value;
      let value = mapDateFieldfield_total_workers_reached_by_month.get(key);

      totalWorkersListNew.x.push(key);
      totalWorkersListNew.y.push(value);
    }

    iterator1 = mapDateFieldfield_total_sum_reached_by_month.keys();
    for (
      var next = iterator1.next();
      !next || !next.done;
      next = iterator1.next()
    ) {
      let key = next.value;
      let value = mapDateFieldfield_total_sum_reached_by_month.get(key);

      totalSumListNew.x.push(key);
      totalSumListNew.y.push(value);
    }

    setTotalFieldVisits(totalFieldVisitsNew);
    setTotalFieldWorkersReached(totalFieldWorkersReachedNew);
    setTotalWorkersReached(totalWorkersReachedNew);
    setTotalCount(totalSum);
    setTotalFieldWorkersReachedList(totalFieldWorkersReachedListNew);
    setTotalWorkersList(totalWorkersListNew);
    setTotalSumList(totalSumListNew);
    setFilteredFieldworkActivities(fieldworkActivitiesFiltered);
  };

  useEffect(() => {
    if (suppliers === null || suppliers === undefined || suppliers.length < 1) {
      dispatch(fetchSuppliers());
    }

    if (
      recruiters === null ||
      recruiters === undefined ||
      recruiters.length < 1
    ) {
      dispatch(fetchRecruiters());
    }

    if (
      nationalities === null ||
      nationalities === undefined ||
      nationalities.length < 1
    ) {
      dispatch(fetchNationalities());
    }
  }, []);

  useEffect(() => {
    fetchMetrics();
  }, [filters]);

  useEffect(() => {
    filterFieldActivityMetrics();
    filterWorkerVoiceCaseCallsMetrics();
  }, [fieldworkActivities]);

  const redirectToEditActivity = (id) => {
    props.history.push(`/admin/fieldwork-activity?id=${id}`);
  };

  const viewEditButton = (id) => {
    return (
      <div>
        <Button
          title={"Edit: " + id}
          simple
          color="success"
          value={id}
          className={classes.button}
          onClick={(e) => redirectToEditActivity(e.currentTarget.value)}
        >
          <Edit style={{ cursor: "pointer" }} />
        </Button>
      </div>
    );
  };

  const viewFieldworkActivityButton = (id) => {
    return (
      <div>
        <Button
          simple
          color="info"
          className={classes.button}
          onClick={(e) => setModal({ open: true, id: id })}
        >
          <VisibilityIcon />
        </Button>
      </div>
    );
  };

  const montlyWorkerVoiceFiltered = new Array();

  monthlyWorkerVoice.forEach((item) => {
    if (item.month_year) {
      montlyWorkerVoiceFiltered.push(item);
    }
  });

  const componentRef = useRef(this);
  const currentUser = loginStore.getLoginUser();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (
    fieldworkActivities === null ||
    fieldworkActivities === undefined ||
    (suppliers === null ||
      suppliers === undefined ||
      Object.keys(suppliers).length < 1) ||
    (nationalities === null ||
      nationalities === undefined ||
      Object.keys(nationalities).length < 1)
  ) {
    return <CircularProgress />;
  }

  let filterPanel = IssaraActivityDashboardFilterPanel(
    classes,
    context,
    modal,
    setModal,
    csoDisplay,
    filters,
    setFilters
  );

  let displayNode = IssaraActivityDashboardDisplayPanel(
    classes,
    context,
    fetchingMonthlyWorkerVoice,
    montlyWorkerVoiceFiltered,
    colorsGenerator,
    fetchingInteractions,
    workerInteractions,
    ermatrics,
    totalWorkerCalls,
    fetchingFieldworkActivities,
    filteredFieldworkActivities,
    totalFieldVisits,
    totalCount,
    totalSumList
  );

  let tableNode = IssaraActivityDashboardTablePanel(
    currentUser,
    filteredFieldworkActivities,
    context,
    filters,
    setFilters,
    classes,
    fetchingSuppliers,
    fetchingRecruiters,
    fetchingFieldworkActivities,
    viewEditButton,
    viewFieldworkActivityButton
  );

  return (
    <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint
          key={Utils.giveMeGuid()}
          ref={componentRef}
          render={() => {
            return displayNode;
          }}
        />
      </div>

      {filterPanel}

      <Button onClick={handlePrint}>{context.t("Print Report")}</Button>

      {displayNode}

      {tableNode}
    </div>
  );
}

DownloadFieldWork.contextTypes = {
  t: PropTypes.func.isRequired,
};

IssaraActivityDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};
