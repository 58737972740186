import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import "./tabStyles.css";

const PrevTextDataComponent = ({
  context,
  data,
  text_header_1,
  text_header_2,
  is_notes,
}) => {
  const prev_data = is_notes ? data : data.remediations;
  if (prev_data && prev_data.length !== 0) {
    return (
      <div className="prev_data">
        {prev_data.map((notes, index) => (
          <Accordion key={notes.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className="old-notes-text">
                {context.t("Note")} {index + 1}: written by -{" "}
                <strong>{notes.author_email}</strong> -{" "}
                {moment(notes.updated_at).fromNow()}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  width: "100%",
                  padding: 10,
                  border: "1px solid grey",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                }}
              >
                <Typography
                  variant="overline"
                  gutterBottom
                  style={{ fontStyle: "italic" }}
                >
                  {context.t("Updated At ")}
                  {moment(notes.updated_at).format("DD MMM, YYYY HH:mm")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <span className="text_headers">{text_header_1} </span>
                  {is_notes ? notes.value : notes.description}
                </Typography>
                {!is_notes ? (
                  <Typography variant="body2" gutterBottom>
                    <span className="text_headers">{text_header_2}</span>
                    {data.system_strengthenings[index].description}
                  </Typography>
                ) : null}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  } else {
    return <div></div>;
  }
};

PrevTextDataComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PrevTextDataComponent;
