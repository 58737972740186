import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "../../CustomInput/CustomInput";
import PrevTextDataComponent from "./PrevTextDataComponent";
import "./tabStyles.css";

const CommunicatingWithTheWorkers = ({
  context,
  data,
  onDataChange,
  handleOnClick,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "investigation_their_wellbeing" || name.includes("describe")) {
      let is_describe = name.includes("describe");
      let key_name = is_describe ? name.replace("_describe", "") : name;
      onDataChange({
        [key_name]: {
          value: is_describe ? data[key_name].value : value,
          description: is_describe ? value : data[key_name].description,
        },
      });
    } else if (name === "communication_notes") {
      onDataChange({
        [name]: {
          value: value,
        },
      });
    } else {
      onDataChange({
        [name]: value === "true",
      });
    }
  };

  return (
    <GridContainer>
      <GridItem lg={12}>
        <FormControl component="fieldset">
          {/* Question 1 */}
          <FormLabel component="legend">
            <span className="question">
              {context.t(
                "Throughout the investigation and upon its conclusion, have you stayed in touch with the worker to check on their well-being and whether the investigation has had any positive or negative impacts on them?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="investigation_their_wellbeing"
            value={data.investigation_their_wellbeing.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.investigation_their_wellbeing.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="investigation_their_wellbeing_describe"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.investigation_their_wellbeing.description}
              />
            )}
          </RadioGroup>

          {/* Question 2 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Do you need help with any more complex consequences arising from the worker-reported issue, such as psychosocial support for the worker, the need to quickly repatriate a worker, etc.?"
              )}
            </span>
          </FormLabel>
          <GridContainer>
            <GridItem xs={3} md={1}>
              <RadioGroup
                aria-labelledby="radio-buttons-group"
                name="need_help_with_complex_consequences"
                value={String(data.need_help_with_complex_consequences)}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("Yes")}</span>
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("No")}</span>
                  }
                />
              </RadioGroup>
            </GridItem>
            <GridItem xs={9} md={11}>
              {data.need_help_with_complex_consequences === true ? (
                <Alert
                  variant="outlined"
                  severity="info"
                  style={{ height: "90%" }}
                >
                  <span className="tooltip">
                    {context.t(
                      "The Issara team will be notified of this and will reach out to you soon! Issara Institute has trained trauma-informed counselors, teams working in the origin countries of the workers, and a network of trusted service providers, all which are happy to help with complex cases and service needs."
                    )}
                  </span>
                </Alert>
              ) : null}
            </GridItem>
          </GridContainer>

          <FormGroup className="next_question">
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              labelText={context.t("Additional Notes")}
              name="communication_notes"
              inputProps={{
                onChange: (e) => handleChange(e),
              }}
              value={
                data.communication_notes ? data.communication_notes.value : ""
              }
            />

            {/* Prev Data */}
            <PrevTextDataComponent
              context={context}
              data={
                data.list_of_text_fields
                  ? data.list_of_text_fields.communication_notes
                  : []
              }
              text_header_1={"Note: "}
              text_header_2={""}
              is_notes={true}
            />
          </FormGroup>
        </FormControl>
      </GridItem>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <Button color="rose" onClick={handleOnClick}>
            {context.t("Save as Draft")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

CommunicatingWithTheWorkers.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CommunicatingWithTheWorkers;
