import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Box from "@material-ui/core/Box";
export default function PartnerLegalDisclaimer(props, context) {
  return (
    <GridContainer>
      <div style={{ width: "100%", verticalAlign: "middle" }}>
        <Box
          component="span"
          display="block"
          p={1}
          m={1}
          bgcolor="background.paper"
        >
          <GridItem xs={12} md={12} lg={12}>
            <h4>{context.t("Legal Disclaimer")}</h4>
            <p>
              {context.t(
                "While we have made our best efforts to ensure the accuracy and reliability of the information collected and contained in this dashboard, Issara Institute is not responsible for any errors or omissions, or for the results obtained from the use of this information."
              )}
            </p>
            <p>
              {context.t(
                "All information in this dashboard is provided 'as is', with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. Nothing herein shall to any extent substitute for the independent investigation and the sound technical and business judgment of the reader."
              )}
            </p>
            <p>
              {context.t(
                "Any views, opinions and guidance set out in this dashboard are provided for information purposes only, and do not purport to be legal and/or professional advice or a definitive interpretation of any law. In no event will Issara Institute or its employees be liable to you or anyone else for any decision made or action taken in reliance on the information in this report or for any consequential, special or similar damages, even if advised of the possibility of such damages. The information contained in this dashboard is intended solely to provide general guidance on matters of interest for the use of the reader, who accepts full responsibility for its use."
              )}
            </p>
          </GridItem>
        </Box>
      </div>
    </GridContainer>
  );
}

PartnerLegalDisclaimer.contextTypes = {
  t: PropTypes.func.isRequired,
};
