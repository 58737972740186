import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "../../CustomInput/CustomInput";
import PrevTextDataComponent from "./PrevTextDataComponent";
import "./tabStyles.css";

const InformationCollection = ({
  context,
  data,
  onDataChange,
  handleOnClick,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "information_collection_notes") {
      onDataChange({
        [name]: {
          value: value,
        },
      });
      return;
    } else if (name === "checked_with_hr" || name === "repeat_issue") {
      onDataChange({
        [name]: value === "true",
      });
      return;
    }
    onDataChange({
      [name]: data[name] === value ? null : value,
    });
  };

  return (
    <GridContainer className="main_container">
      <GridItem lg={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <span className="question">
              {context.t(
                "Please review the information shared in ILM. Do you want to talk to Issara to get more context on the worker-reported issue?"
              )}
            </span>
          </FormLabel>

          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="issara_context"
            value={data.issara_context}
            onChange={handleChange}
          >
            <FormControlLabel
              value="no need"
              control={<Radio required={true} />}
              label={
                <span className="answer_checkbox">{context.t("No Need")}</span>
              }
            />
            <FormControlLabel
              value="wantMoreInfo"
              control={<Radio required={true} />}
              label={
                <span className="answer_checkbox">
                  {context.t(
                    "Yes, I want more information from my Issara focal"
                  )}
                </span>
              }
            />
            <FormControlLabel
              value="alreadyReachedOut"
              control={<Radio required={true} />}
              label={
                <span className="answer_checkbox">
                  {context.t("Yes, I already reached out to my Issara focal")}
                </span>
              }
            />
          </RadioGroup>

          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Have you checked with the HR team to see if similar worker-reported issues have been filed and/or solved recently?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="checked_with_hr"
            value={String(data.checked_with_hr)}
            onChange={handleChange}
          >
            <FormControlLabel
              value="true"
              control={<Radio required={true} />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="false"
              control={<Radio required={true} />}
              label={<span className="answer_checkbox">{context.t("No")}</span>}
            />
          </RadioGroup>
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Is this worker-reported issue a repeat issue, meaning that there might be a root cause that needs to be identified and addressed?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="repeat_issue"
            value={String(data.repeat_issue)}
            onChange={handleChange}
          >
            <FormControlLabel
              value="true"
              control={<Radio required={true} />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="false"
              control={<Radio required={true} />}
              label={<span className="answer_checkbox">{context.t("No")}</span>}
            />
          </RadioGroup>

          <FormGroup className="next_question">
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              labelText={context.t("Additional Notes")}
              name="information_collection_notes"
              inputProps={{
                onChange: (e) => handleChange(e),
              }}
              value={
                data.information_collection_notes
                  ? data.information_collection_notes.value
                  : ""
              }
            />

            {/* Prev Data */}
            <PrevTextDataComponent
              context={context}
              data={
                data.list_of_text_fields
                  ? data.list_of_text_fields.information_collection_notes
                  : []
              }
              text_header_1={"Note: "}
              text_header_2={""}
              is_notes={true}
            />
          </FormGroup>
        </FormControl>
      </GridItem>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <Button color="rose" onClick={handleOnClick}>
            {context.t("Save as Draft")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

InformationCollection.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InformationCollection;
