import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, FormGroup } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import PrevTextDataComponent from "./PrevTextDataComponent";
import CustomInput from "../../CustomInput/CustomInput";
import "./tabStyles.css";

const MonitoringAndCommunication = ({
  context,
  data,
  onDataChange,
  handleOnClick,
  handlePreview,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onDataChange({
      [name]: {
        ...(data[name].id !== null && { id: data[name].id }),
        description: value,
      },
    });
  };

  return (
    <GridContainer>
      <GridItem lg={12}>
        {/* Form Part */}
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <span className="question">
              {context.t(
                "Case updates entered here are time-stamped and viewable by your team, the Issara team, and your customers that are Strategic Partners in the ILM system. If there is any additional information that you would like to add regarding Remediation or Systems Strengthening and Continuous Improvement, please enter it here:"
              )}
            </span>
          </FormLabel>

          <FormGroup className="next_question">
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              labelText={context.t("Remediation")}
              placeholder={context.t("Please add notes on case remediation")}
              name="remediation"
              inputProps={{
                onChange: (e) => handleChange(e),
              }}
              value={data.remediation ? data.remediation.description : ""}
            />

            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              labelText={context.t(
                "Systems Strengthening and Continuous Improvement"
              )}
              placeholder={context.t(
                "Please add notes on Systems Strengthening and Continuous Improvement related to this worker-reported issue"
              )}
              name="system_strengthening"
              inputProps={{
                onChange: (e) => handleChange(e),
              }}
              value={
                data.system_strengthening
                  ? data.system_strengthening.description
                  : ""
              }
            />
          </FormGroup>
          {/* Prev Data */}
          <PrevTextDataComponent
            context={context}
            data={{
              remediations: data.list_of_text_fields
                ? data.list_of_text_fields.remediations
                : [],
              system_strengthenings: data.list_of_text_fields
                ? data.list_of_text_fields.system_strengthenings
                : [],
            }}
            text_header_1={"Remediation "}
            text_header_2={"Systems Strengthening and Continuous Improvement: "}
            is_notes={false}
          />
        </FormControl>
      </GridItem>

      <GridContainer justifyContent="flex-end">
        {data.list_of_text_fields?.remediations?.length > 0 ? (
          <GridItem>
            <Button color="rose" onClick={handlePreview}>
              {context.t("Preview & Submit")}
            </Button>
          </GridItem>
        ) : (
          <GridItem>
            <Button color="rose" onClick={handleOnClick}>
              {context.t("Save as Draft")}
            </Button>
          </GridItem>
        )}
      </GridContainer>
    </GridContainer>
  );
};

MonitoringAndCommunication.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MonitoringAndCommunication;
