import React from "react";
import InteractionCallView from "views/calls/InteractionCallView.js";
import InteractionCallList from "views/calls/InteractionCallList.js";
import KpiReferencesList from "views/references/KpiReferencesList.js";
import KpiLegalViolationList from "views/references/KpiLegalViolationList.js";

import EnhancedTeamTasksList from "views/teamtasks/EnhancedTeamTasksList.js";
import NonSupplyChainCaseList from "views/suppliers/NonSupplyChainCaseList.js";

// import EthicalRecruitmentMeetingsListView from "views/EthicalRecruitment/EthicalRecruitmentMeetingsListView";
// import EthicalRecruitmentMeetingForm from "views/EthicalRecruitment/NewEthicalRecruitmentMeetingWizardView";
import RecruiterForm from "views/recruiters/RecruiterForm";
import SupplierCRCResponseForm from "views/suppliers/SupplierCRCResponseForm.js";
import RecruiterCRCResponseForm from "views/recruiters/RecruiterCRCResponseForm.js";
import PartnerUserLoginsListV2 from "views/strategicpartners/PartnerUserLoginsListV2.js";
import SupplierKPIsList from "./views/suppliers/SupplierKPIsList.js";
import SupplierKpiBulkUpdate from "views/suppliers/SupplierKpiBulkUpdate.js";
import BusinessResponseForm from "views/suppliers/BusinessResponseForm.js";
import BusinessResponsesList from "views/suppliers/BusinessResponsesList.js";
import SupplierList from "views/suppliers/SupplierList.js";
import SupplierForm from "views/suppliers/SupplierForm.js";
import NonSupplyChainCaseForm from "views/suppliers/NonSupplyChainCaseForm.js";
import SupplierCRCList from "views/suppliers/SupplierCRCList.js";
import RecruiterCRCList from "views/recruiters/RecruiterCRCList.js";
import PasswordResetPage from "views/Pages/PasswordResetPage";
import ChangePasswordForm from "views/users/ChangePasswordForm.js";

import GrievanceMechanismsForm from "views/suppliers/GrievanceMechanismsForm.js";
import GrievanceMechanismsList from "views/suppliers/GrievanceMechanismsList.js";

import RecruiterList from "views/recruiters/RecruiterList.js";

import FieldworkActivityForm from "views/TeamActivity/FieldworkActivityForm.js";
import MonthlyWorkerVoiceForm from "views/TeamActivity/MonthlyWorkerVoiceForm.js";
import WV_and_ER_metrix from "views/TeamActivity/WV_and_ER_metrix.js";

import StrategicPartnerList from "views/strategicpartners/StrategicPartnerList.js";
// import StrategicPartnerResponseList from "views/strategicpartners/StrategicPartnerResponseList.js";
import StrategicPartnerForm from "views/strategicpartners/StrategicPartnerForm.js";
import SupplyChainForm from "views/strategicpartners/SupplyChainForm.js";
// import StrategicPartnerResponseForm from "views/strategicpartners/StrategicPartnerResponseForm.js";
import SupplyChainList from "views/strategicpartners/SupplyChainList.js";

import KpiReferencesListDash from "views/Dashboard/KpiReferencesList";

import IssaraActivityDashboard from "views/Dashboard/IssaraActivityDashboard.js";
import SupplierMouThaiDashboard from "views/Dashboard/SupplierMouThaiDashboard.js";
import SupplierMouThaiDashboardOriginal from "views/Dashboard/SupplierMouThaiDashboardOriginal.js";
import SupplyChainDashboardWorker from "views/Dashboard/SupplyChainDashboardWorker.js";
import SupplyChainDashboardKpi from "views/Dashboard/SupplyChainDashboardKpi.js";
import SupplierKpiDashboard from "views/Dashboard/SupplierKpiDashboard.js";
//import RecruiterDashboard from "views/Dashboard/RecruiterDashboard.js";
import RecruiterKpiDashboard from "views/Dashboard/RecruiterKpiDashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import CmsAdminPanel from "views/CmsAdmin/CmsAdminPanel.js";

import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import EnterCodePage from "views/Pages/EnterCodePage.js";
// import IssaraIcon from '@material-ui/icons/AssistantPhoto';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// @material-ui/icons
// import RecruitmentIcon from '@material-ui/icons/ContactMail';
import BusinessIcon from "@material-ui/icons/BusinessCenter";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// import CallIcon from '@material-ui/icons/Phone';
import DashboardIcon from "@material-ui/icons/Dashboard";
import DjangoIcon from "@material-ui/icons/AccountTree";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import HomeIcon from "@material-ui/icons/Home";
import ScoreIcon from "@material-ui/icons/Assessment";
import UpdateIcon from "@material-ui/icons/Update";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import GamepadIcon from "@material-ui/icons/SportsEsports";
import FactCheckIcon from "@material-ui/icons/CheckBoxOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ContactSupport from "@material-ui/icons/ContactPhone";
import SuppliersApartmentIcon from "@material-ui/icons/Apartment";
import SupplierIcon from "@material-ui/icons/Apartment";
import RecruitmentDashboardIcon from "@material-ui/icons/Contacts";
import BarChartOutlined from "@material-ui/icons/BarChartOutlined";
import TeamIcon from "@material-ui/icons/AccessibilityNew";
import BookIcon from "@material-ui/icons/Book";
import TeamTasksIcon from "@material-ui/icons/Assignment";
import TransportationIcon from "@material-ui/icons/EmojiTransportation";
import loginStore from "redux/stores/LoginStore";
import PartnerLandingPage from "views/strategicpartners/PartnerLandingPage";
import SupplierKpisTimelineView from "views/Playground/SupplierKpisTimelineView";
import TimelineRoundedIcon from "@material-ui/icons/TimelineRounded";
import TeamActivityDashboardIcon from "@material-ui/icons/NaturePeople";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PeopleSharpIcon from "@material-ui/icons/PeopleSharp";

import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import SPWorkPlaningForm from "./views/Dashboard/SPWorkPlanning/SPWorkPlaningForm.js";
import SPProgressDashboard from "./views/Dashboard/SPWorkPlanning/SPProgressDashboard.js";
import ActivitiesProgress from "./views/Dashboard/SPWorkPlanning/ActivitiesProgress.js";

import SupplierLandingPage from "views/suppliers/SupplierLandingPage.js";
import SupplierDashboard from "views/suppliers/SupplierKpiDashboard.js";
import SupplierProgressDashboard from "views/suppliers/SupplierProgressDashboard.js";
import SPSupplierProgress from "views/strategicpartners/SupplierProgressDashboard.js";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import BallotIcon from "@material-ui/icons/Ballot";
import SupplierUserLoginsList from "views/suppliers/SupplierUserLoginsList.js";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import SPProgressMetrics from "./views/Dashboard/SPWorkPlanning/SPProgressMetrics.js";
import CSOLoginList from "views/strategicpartners/CSOLoginList.js";
import { setMomentLocaleFromLocalStorage } from "languages/i18n/utilities/utilities.js";
import Diversity1Icon from "components/Icons/Diversity1Icon.js";
import InsightsIcon from "components/Icons/InsightsIcon.js";
import SupplierLegalDisclaimer from "views/suppliers/SupplierLegalDisclaimer.js";
import PartnerLegalDisclaimer from "views/strategicpartners/PartnerLegalDisclaimer.js";
import SupplierWelcomePage from "views/suppliers/SupplierWelcomePage.js";
import PartnerWelcomePage from "views/strategicpartners/PartnerWelcomePage.js";

var dashRoutes = {
  issaraGroupRoutes: [
    {
      collapse: true,
      name: "Playground",
      icon: GamepadIcon,
      state: "PlaygroundCollapse",
      views: [
        {
          path: "/playground-supplier-kpis-timeline-chart",
          name: "Supplier KPIs timeline",
          component: SupplierKpisTimelineView,
          layout: "/admin",
        },
      ],
      hidden: !loginStore.isDeveloper(),
    },
    {
      collapse: true,
      name: "Worker Voice",
      rtlName: "صفحات",
      icon: ContactSupport,
      state: "workerVoiceCollapse",
      views: [
        {
          path: "/new-call",
          name: "Add Call",
          icon: AddIcon,
          component: InteractionCallView,
          layout: "/admin",
        },
        {
          path: "/calls",
          name: "View Calls",
          icon: ListIcon,
          component: InteractionCallList,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Business Response",
      rtlName: "صفحات",
      icon: BusinessIcon,
      state: "businessCollapse",
      views: [
        {
          path: "/edit-business-response",
          name: "New Business Response",
          icon: AddIcon,
          component: BusinessResponseForm,
          layout: "/admin",
        },
        {
          path: "/business-responses",
          name: "View Business Responses",
          icon: ListIcon,
          component: BusinessResponsesList,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Team Activities",
      icon: TeamIcon,
      state: "TeamFormsCollapse",
      views: [
        {
          path: "/fieldwork-activity",
          name: "Add activity",
          icon: AddIcon,
          component: FieldworkActivityForm,
          layout: "/admin",
        },
        {
          path: "/monthly-worker-voice",
          name: "Add monthly WV metrics",
          icon: AddIcon,
          component: MonthlyWorkerVoiceForm,
          layout: "/admin",
        },
        {
          path: "/wv-metrics",
          name: "WV and ER metrics",
          icon: AddIcon,
          component: WV_and_ER_metrix,
          layout: "/admin",
        },
        {
          name: "Tasks",
          path: "/team-tasks",
          name: "Team Tasks",
          icon: TeamTasksIcon,
          component: EnhancedTeamTasksList,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Suppliers",
      icon: SupplierIcon,
      state: "SuppliersCollapse",
      views: [
        {
          path: "/edit-supplier",
          name: "Add / Edit Supplier",
          icon: AddIcon,
          component: SupplierForm,
          layout: "/admin",
          hidden: !loginStore.isIssaraManagement(),
        },
        {
          path: "/suppliers-list",
          name: "View Suppliers",
          icon: ListIcon,
          component: SupplierList,
          layout: "/admin",
        },
        {
          path: "/global-suppliers-dashboard",
          name: "Suppliers dashboard",
          icon: SuppliersApartmentIcon,
          component: SupplierKpiDashboard,
          layout: "/admin",
        },
        {
          path: "/supplier-login-activity",
          name: "Tracking Supplier Logins",
          icon: EmojiPeopleIcon,
          component: SupplierUserLoginsList,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Labour Recruitment",
      icon: AssignmentIndIcon,
      state: "LabourRecruitmentCollapse",
      views: [
        {
          path: "/edit-recruiter",
          name: "Add Recruiter",
          icon: AddIcon,
          component: RecruiterForm,
          layout: "/admin",
        },
        {
          path: "/recruiters",
          name: "View Recruiters",
          icon: ListIcon,
          component: RecruiterList,
          layout: "/admin",
        },
        {
          path: "/recruiter-dashboard",
          name: "Recruiter Dashboard",
          icon: RecruitmentDashboardIcon,
          component: RecruiterKpiDashboard,
          layout: "/admin",
        },
        {
          path: "/supplier-demand-dashboard-original",
          name: "MOU Data Dashboard",
          icon: TransportationIcon,
          component: SupplierMouThaiDashboardOriginal,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Strategic Partners",
      icon: PeopleSharpIcon,
      state: "StrategicPartnersCollapse",
      views: [
        {
          path: "/edit-strategic-partner",
          name: "Add Strategic Partner",
          icon: AddIcon,
          component: StrategicPartnerForm,
          layout: "/admin",
        },
        {
          path: "/strategicpartners-list",
          name: "View Partners",
          icon: ListIcon,
          component: StrategicPartnerList,
          layout: "/admin",
        },
        {
          path: "/edit-supply-chain",
          name: "Add Supply Chain",
          icon: AddIcon,
          component: SupplyChainForm,
          layout: "/admin",
        },
        {
          path: "/supplychains",
          name: "View Supply Chains",
          icon: ListIcon,
          component: SupplyChainList,
          layout: "/admin",
        },
        {
          path: "/partner-login-activity",
          name: "Tracking Partner Logins",
          icon: EmojiPeopleIcon,
          component: PartnerUserLoginsListV2,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "CSOs Partners",
      icon: PeopleSharpIcon,
      state: "CSOPartnersCollapse",
      views: [
        {
          path: "/edit-non-supply-chain-case",
          name: "Add / Edit Non Supply Chain Case Name",
          icon: AddIcon,
          component: NonSupplyChainCaseForm,
          layout: "/admin",
        },
        {
          path: "/nonsupplychaincases",
          name: "View Non-Supply Chain Case Names",
          icon: ListIcon,
          component: NonSupplyChainCaseList,
          layout: "/admin",
        },
        {
          path: "/cso-issara-dashboard",
          name: "ILM Action Network Activities Dashboard",
          icon: TeamActivityDashboardIcon,
          component: (props) => (
            <IssaraActivityDashboard {...props} csoDisplay={true} />
          ),
          layout: "/admin",
        },
        {
          path: "/cso-businesskpiupdates",
          name: "ILM Action Network KPIs Update",
          icon: UpdateIcon,
          component: (props) => (
            <SupplierKPIsList {...props} csoDisplay={true} />
          ),
          layout: "/admin",
        },
        {
          path: "/CSO-login-activity",
          name: "Tracking CSOs Logins",
          icon: EmojiPeopleIcon,
          component: CSOLoginList,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Internal Dashboards",
      icon: TimelineRoundedIcon,
      state: "InternalDashboardsCollapse",
      views: [
        {
          path: "/issara-dashboard",
          name: "Team Activity Dashboard",
          icon: TeamActivityDashboardIcon,
          component: IssaraActivityDashboard,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "ILM management",
      rtlName: "صفحات",
      icon: BusinessIcon,
      state: "managementCollapse",
      views: [
        {
          path: "/businesskpiupdates",
          name: "Kpi Updates",
          icon: UpdateIcon,
          component: SupplierKPIsList,
          layout: "/admin",
        },
        {
          path: "/supplier-kpi-bulk-update",
          name: "View Supplier KPIs bulk update",
          icon: UpdateIcon,
          component: SupplierKpiBulkUpdate,
          layout: "/admin",
          hidden: true,
        },
        {
          path: "/businesscrcscoring",
          name: "Add Supplier CRC",
          icon: ScoreIcon,
          component: SupplierCRCResponseForm,
          layout: "/admin",
        },
        {
          path: "/suppliercrclist",
          name: "View Supplier CRCs",
          icon: ListIcon,
          component: SupplierCRCList,
          layout: "/admin",
        },
        {
          path: "/recruitercrcscoring",
          name: "Add Recruiter CRC",
          icon: ScoreIcon,
          component: RecruiterCRCResponseForm,
          layout: "/admin",
        },
        {
          path: "/recruitercrclist",
          name: "View Recruiter CRCs",
          icon: ListIcon,
          component: RecruiterCRCList,
          layout: "/admin",
        },
        {
          path: "/grievancemechanismsadding",
          name: "Add Grievance",
          icon: ScoreIcon,
          component: GrievanceMechanismsForm,
          layout: "/admin",
        },
        {
          path: "/grievancemechanismslist",
          name: "View Grievance",
          icon: ListIcon,
          component: GrievanceMechanismsList,
          layout: "/admin",
        },
      ],
    },
    /*
    ,{
      collapse: true,
      name: "Ethical Recruitment",
      state: 'ethicalRecruitment',
      icon: RecruitmentIcon,
      views: [
        {
          path: "/new-ethical-recruitment-meeting",
          name: "Add Meeting",
          icon: AddIcon,
          component: EthicalRecruitmentMeetingForm,
          layout: "/admin"
        },
        {
          path: "/ethical-recruitment-meetings",
          name: "View Meetings",
          icon: ListIcon,
          component: EthicalRecruitmentMeetingsListView,
          layout: "/admin"
        }
      ]
    }*/
    {
      collapse: true,
      name: "References",
      rtlName: "صفحات",
      icon: BookIcon,
      state: "referencesCollapse",
      views: [
        {
          path: "/resources/coding-worker-issues",
          name: "Glossary: Labor Issue Categories",
          icon: ListIcon,
          component: KpiReferencesList,
          layout: "/admin",
        },
        {
          path: "/kpi_legal_violation_list",
          name: "Legal Violations",
          icon: ListIcon,
          component: KpiLegalViolationList,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "SP",
      rtlName: "صفحاتصفحات",
      icon: DashboardIcon,
      state: "spScreensDashboardCollapse",
      views: [
        {
          path: "/global-buyer-landing",
          name: "Partner Overview",
          icon: HomeIcon,
          component: PartnerLandingPage,
          layout: "/admin",
        },

        {
          path: "/update-activities-progress",
          name: "Workplan Activity Progress",
          icon: LocalActivityIcon,
          component: ActivitiesProgress,
          layout: "/admin",
          // hidden: !loginStore.isDirectors()
        },

        {
          path: "/sp-progress-metrics",
          name: "SP Progress Metrics",
          icon: LocalActivityIcon,
          component: SPProgressMetrics,
          layout: "/admin",
          // hidden: !loginStore.isDirectors()
        },

        {
          path: "/global-buyer-dashboard-workers",
          name: "Worker Demographics",
          icon: EmojiPeopleIcon,
          component: SupplyChainDashboardWorker,
          layout: "/admin",
        },
        {
          path: "/global-buyer-dashboard-kpi",
          name: "Worker-Reported Issues",
          icon: NotificationImportantIcon,
          component: SupplyChainDashboardKpi,
          layout: "/admin",
        },
        {
          path: "/partner-supplier-dashboard",
          name: "Suppliers",
          icon: BarChartOutlined,
          component: SupplierKpiDashboard,
          layout: "/admin",
        },

        {
          path: "/progress-dashboard",
          name: "Supplier Progress Dashboard",
          icon: BusinessIcon,
          component: SPSupplierProgress,
          layout: "/admin",
        },

        {
          path: "/supplier-recruitment-dashboard",
          name: "Recruitment",
          icon: TransportationIcon,
          component: SupplierMouThaiDashboard,
          layout: "/admin",
        },
        {
          path: "/resources/worker-reported-issues",
          name: "Glossary: Labor Issue Categories",
          icon: FactCheckIcon,
          component: KpiReferencesListDash,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Admin",
      rtlName: "صفحاتصفحات",
      icon: DashboardIcon,
      state: "partnerLoginScreensDashboardCollapse",
      views: [
        {
          name: "DJANGO CMS",
          icon: DjangoIcon,
          path: "/cmsadmin",
          name: "Database Admin",
          mini: "DA",
          component: CmsAdminPanel,
          layout: "/admin",
        },
        {
          path: "/change-password",
          icon: PersonIcon,
          name: "Change Password",
          mini: "CP",
          component: ChangePasswordForm,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/reset-password",
      name: "Password Reset",
      component: PasswordResetPage,
      layout: "/auth",
      hidden: true,
    },
    {
      path: "/login-page",
      name: "Login Page",
      component: LoginPage,
      layout: "/auth",
      hidden: true,
    },
    {
      path: "/enter_code",
      name: "Enter 2 Factor Code",
      component: EnterCodePage,
      layout: "/auth",
      hidden: true,
    },
    {
      path: "/lock-screen-page",
      name: "Lock Screen Page",
      component: LockScreenPage,
      layout: "/auth",
      hidden: true,
    },
    {
      path: "/error-page",
      name: "Error Page",
      component: ErrorPage,
      layout: "/auth",
      hidden: true,
    },
  ],

  getGlobalPartnerRoutes: function(context) {
    return [
      {
        path: "/partner-welcome-page",
        name: context.t("Welcome"),
        component: PartnerWelcomePage,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/partner-legal-disclaimer",
        name: context.t("Legal disclaimer"),
        component: PartnerLegalDisclaimer,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/global-buyer-landing",
        name: context.t("Partner Overview"),
        icon: HomeIcon,
        component: PartnerLandingPage,
        layout: "/admin",
      },
      {
        path: "/global-buyer-dashboard-workers",
        name: context.t("Worker Demographics"),
        icon: EmojiPeopleIcon,
        component: SupplyChainDashboardWorker,
        layout: "/admin",
      },
      {
        path: "/global-buyer-dashboard-kpi",
        name: context.t("Worker-Reported Issues"),
        icon: NotificationImportantIcon,
        component: SupplyChainDashboardKpi,
        layout: "/admin",
      },
      {
        path: "/partner-supplier-dashboard",
        name: context.t("Suppliers"),
        icon: BarChartOutlined,
        component: SupplierKpiDashboard,
        layout: "/admin",
      },
      {
        collapse: true,
        name: context.t("Workplanning"),
        icon: EventAvailableIcon,
        state: "managementCollapse",
        views: [
          {
            path: "/spworkplaningform",
            name: context.t("SP Workplanning Form"),
            icon: BallotIcon,
            component: SPWorkPlaningForm,
            layout: "/admin",
          },
          {
            path: "/spprogressdashboard",
            name: context.t("SP Progress Metrics"),
            icon: InsertChartIcon,
            component: SPProgressDashboard,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/progress-dashboard",
        name: context.t("Supplier Progress Dashboard"),
        icon: BusinessIcon,
        component: SPSupplierProgress,
        layout: "/admin",
      },
      {
        path: "/supplier-recruitment-dashboard",
        name: context.t("Recruitment"),
        icon: TransportationIcon,
        component: SupplierMouThaiDashboard,
        layout: "/admin",
      },
      {
        path: "/worker-reported-issues",
        name: context.t("Glossary: Labor Issue Categories"),
        icon: FactCheckIcon,
        component: KpiReferencesListDash,
        layout: "/admin",
      },
      {
        path: "/change-password",
        icon: PersonIcon,
        name: context.t("Change Password"),
        mini: "CP",
        component: ChangePasswordForm,
        layout: "/admin",
      },
      {
        path: "/reset-password",
        name: context.t("Password Reset"),
        component: PasswordResetPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/login-page",
        name: context.t("Login Page"),
        component: LoginPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/enter_code",
        name: context.t("Enter 2 Factor Code"),
        component: EnterCodePage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/lock-screen-page",
        name: context.t("Lock Screen Page"),
        component: LockScreenPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/error-page",
        name: context.t("Error Page"),
        component: ErrorPage,
        layout: "/auth",
        hidden: true,
      },
    ];
  },

  getSupplierRoutes: function(context) {
    return [
      {
        path: "/supplier-welcome-page",
        name: context.t("Welcome"),
        component: SupplierWelcomePage,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/supplier-legal-disclaimer",
        name: context.t("Legal Disclaimer"),
        component: SupplierLegalDisclaimer,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/supplier-overview",
        name: context.t("Supplier Overview"),
        icon: HomeIcon,
        component: SupplierLandingPage,
        layout: "/admin",
      },
      {
        path: "/progress-dashboard",
        name: context.t("Progress Dashboard"),
        icon: InsightsIcon,
        component: SupplierProgressDashboard,
        layout: "/admin",
      },
      {
        path: "/worker-reported-issues",
        name: context.t("Worker Reported Issues"),
        icon: Diversity1Icon,
        component: SupplierDashboard,
        layout: "/admin",
      },
      {
        path: "/kpi-references-list",
        name: context.t("Glossary: Labor Issue Categories"),
        icon: MenuBookIcon,
        component: KpiReferencesList,
        layout: "/admin",
      },
      {
        path: "/reset-password",
        name: context.t("Password Reset"),
        component: PasswordResetPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/login-page",
        name: context.t("Login Page"),
        component: LoginPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/enter_code",
        name: context.t("Enter 2 Factor Code"),
        component: EnterCodePage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/lock-screen-page",
        name: context.t("Lock Screen Page"),
        component: LockScreenPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/error-page",
        name: context.t("Error Page"),
        component: ErrorPage,
        layout: "/auth",
        hidden: true,
      },
    ];
  },
  // if this function is stable other similar obj will be removed
  getCSORoutes: function(context) {
    return [
      {
        collapse: true,
        name: context.t("Worker Voice"),
        rtlName: "صفحات",
        icon: ContactSupport,
        state: "workerVoiceCollapse",
        views: [
          {
            path: "/new-call",
            name: context.t("Add Call"),
            icon: AddIcon,
            component: InteractionCallView,
            layout: "/admin",
          },
          {
            path: "/calls",
            name: context.t("View Calls"),
            icon: ListIcon,
            component: InteractionCallList,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: context.t("Team Activities"),
        icon: TeamIcon,
        state: "TeamFormsCollapse",
        views: [
          {
            path: "/fieldwork-activity",
            name: context.t("Add activity"),
            icon: AddIcon,
            component: FieldworkActivityForm,
            layout: "/admin",
          },
          {
            path: "/monthly-worker-voice",
            name: context.t("Add monthly WV metrics"),
            icon: AddIcon,
            component: MonthlyWorkerVoiceForm,
            layout: "/admin",
          },
          {
            path: "/wv-metrics",
            name: context.t("WV and ER metrics"),
            icon: AddIcon,
            component: WV_and_ER_metrix,
            layout: "/admin",
          },
          {
            path: "/team-tasks",
            name: context.t("Team Tasks"),
            icon: TeamTasksIcon,
            component: EnhancedTeamTasksList,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: context.t("Internal Dashboards"),
        icon: TimelineRoundedIcon,
        state: "InternalDashboardsCollapse",
        views: [
          {
            path: "/issara-dashboard",
            name: context.t("Team Activity Dashboard"),
            icon: TeamActivityDashboardIcon,
            component: IssaraActivityDashboard,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: context.t("Case management"),
        rtlName: "صفحات",
        icon: BusinessIcon,
        state: "managementCollapse",
        views: [
          {
            path: "/businesskpiupdates",
            name: context.t("Kpi Updates"),
            icon: UpdateIcon,
            component: SupplierKPIsList,
            layout: "/admin",
          },
          {
            path: "/supplier-kpi-bulk-update",
            name: context.t("View Supplier KPIs bulk update"),
            icon: UpdateIcon,
            component: SupplierKpiBulkUpdate,
            layout: "/admin",
            hidden: true,
          },
        ],
      },
      {
        collapse: true,
        name: context.t("Suppliers"),
        icon: SupplierIcon,
        state: "SuppliersCollapse",
        views: [
          {
            path: "/suppliers-list",
            name: context.t("View Suppliers"),
            icon: ListIcon,
            component: SupplierList,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: context.t("References"),
        rtlName: "صفحات",
        icon: BookIcon,
        state: "referencesCollapse",
        views: [
          {
            path: "/kpi-references-list",
            name: context.t("Glossary: Labor Issue Categories"),
            icon: ListIcon,
            component: KpiReferencesList,
            layout: "/admin",
          },
          {
            path: "/kpi_legal_violation_list",
            name: context.t("Legal Violations"),
            icon: ListIcon,
            component: KpiLegalViolationList,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/change-password",
        icon: PersonIcon,
        name: context.t("Change Password"),
        mini: "CP",
        component: ChangePasswordForm,
        layout: "/admin",
      },
      {
        path: "/reset-password",
        name: context.t("Password Reset"),
        component: PasswordResetPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/login-page",
        name: context.t("Login Page"),
        component: LoginPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/enter_code",
        name: context.t("Enter 2 Factor Code"),
        component: EnterCodePage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/lock-screen-page",
        name: context.t("Lock Screen Page"),
        component: LockScreenPage,
        layout: "/auth",
        hidden: true,
      },
      {
        path: "/error-page",
        name: context.t("Error Page"),
        component: ErrorPage,
        layout: "/auth",
        hidden: true,
      },
    ];
  },

  getDefaultRedirect: function() {
    if (loginStore.isGlobalPartner() === true) {
      return "/admin/global-buyer-landing";
    } else if (loginStore.isSupplier()) {
      return "/admin/supplier-overview";
    } else if (loginStore.isCSO()) {
      return "/admin/issara-dashboard";
    } else {
      return "/admin/team-tasks";
    }
  },

  getDefaultRoutes: function(context) {
    if (loginStore.isGlobalPartner() === true) {
      setMomentLocaleFromLocalStorage("global_partner");
      return this.getGlobalPartnerRoutes(context);
    } else if (loginStore.isSupplier()) {
      setMomentLocaleFromLocalStorage("supplier");
      return this.getSupplierRoutes(context);
    } else if (loginStore.isCSO()) {
      setMomentLocaleFromLocalStorage("cso");
      return this.getCSORoutes(context);
    } else {
      return this.issaraGroupRoutes;
    }
  },
};

export default dashRoutes;
