import axios from "axios";
import swal from "@sweetalert/with-react";
import HttpService from "../../services/HttpService";

export const RETRIVE_WORKPLAN_REQUEST = "RETRIVE_WORKPLAN_REQUEST";
export const CREATE_WORKPLAN_SUCCESS = "CREATE_WORKPLAN_SUCCESS";
export const WORKPLAN_ERROR = "WORKPLAN_ERROR";

export const RETRIVE_WORKPLAN_DOC_REQUEST = "RETRIVE_WORKPLAN_DOC_REQUEST";
export const CREATE_WORKPLAN_DOC_SUCCESS = "CREATE_WORKPLAN_DOC_SUCCESS";
export const WORKPLAN_DOC_ERROR = "WORKPLAN_DOC_ERROR";

export const RETRIVE_WORKPLAN_DOC_TWO_REQUEST =
  "RETRIVE_WORKPLAN_DOC_TWO_REQUEST";
export const CREATE_WORKPLAN_DOC_TWO_SUCCESS =
  "CREATE_WORKPLAN_DOC_TWO_SUCCESS";
export const WORKPLAN_DOC_TWO_ERROR = "WORKPLAN_DOC_TWO_ERROR";

export const RETRIVE_WORKPLAN_DOC_THREE_REQUEST =
  "RETRIVE_WORKPLAN_DOC_THREE_REQUEST";
export const CREATE_WORKPLAN_DOC_THREE_SUCCESS =
  "CREATE_WORKPLAN_DOC_THREE_SUCCESS";
export const WORKPLAN_DOC_THREE_ERROR = "WORKPLAN_DOC_THREE_ERROR";

export const RETRIVE_WORKPLAN_DOC_FOUR_REQUEST =
  "RETRIVE_WORKPLAN_DOC_FOUR_REQUEST";
export const CREATE_WORKPLAN_DOC_FOUR_SUCCESS =
  "CREATE_WORKPLAN_DOC_FOUR_SUCCESS";
export const WORKPLAN_DOC_FOUR_ERROR = "WORKPLAN_DOC_FOUR_ERROR";

export const RETRIVE_WORKPLAN_DOC_FIVE_REQUEST =
  "RETRIVE_WORKPLAN_DOC_FIVE_REQUEST";
export const CREATE_WORKPLAN_DOC_FIVE_SUCCESS =
  "CREATE_WORKPLAN_DOC_FIVE_SUCCESS";
export const WORKPLAN_DOC_FIVE_ERROR = "WORKPLAN_DOC_FIVE_ERROR";

export const RETRIVE_WORKPLAN_DOC_SIX_REQUEST =
  "RETRIVE_WORKPLAN_DOC_SIX_REQUEST";
export const CREATE_WORKPLAN_DOC_SIX_SUCCESS =
  "CREATE_WORKPLAN_DOC_SIX_SUCCESS";
export const WORKPLAN_DOC_SIX_ERROR = "WORKPLAN_DOC_SIX_ERROR";

export const RETRIVE_WORKPLAN_DOC_SEVEN_REQUEST =
  "RETRIVE_WORKPLAN_DOC_SEVEN_REQUEST";
export const CREATE_WORKPLAN_DOC_SEVEN_SUCCESS =
  "CREATE_WORKPLAN_DOC_SEVEN_SUCCESS";
export const WORKPLAN_DOC_SEVEN_ERROR = "WORKPLAN_DOC_SEVEN_ERROR";

export const RETRIVE_WORKPLAN_MAIN_GOAL_REQUEST =
  "RETRIVE_WORKPLAN_MAIN_GOAL_REQUEST";
export const CREATE_WORKPLAN_MAIN_GOAL_SUCCESS =
  "CREATE_WORKPLAN_MAIN_GOAL_SUCCESS";
export const WORKPLAN_MAIN_GOAL_ERROR = "WORKPLAN_MAIN_GOAL_ERROR";

export const WORKPLAN_ISLOADING = "WORKPLAN_ISLOADING";
export const WORKPLAN_ISLOADING_SUCCESS = "WORKPLAN_ISLOADING_SUCCESS";

export const FETCH_WORKPLAN_DATA_START = "FETCH_WORKPLAN_DATA_START";
export const FETCH_WORKPLAN_DATA_SUCCESS = "FETCH_WORKPLAN_DATA_SUCCESS";
export const FETCH_WORKPLAN_DATA_FAIL = "FETCH_WORKPLAN_DATA_FAIL";

function workplanIsloading() {
  return {
    type: WORKPLAN_ISLOADING,
  };
}
export const workplanIsloadingSuccess = () => {
  return {
    type: WORKPLAN_ISLOADING_SUCCESS,
  };
};

function createWorkPlanRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_REQUEST,
    workplan: data,
  };
}
function createWorkPlanSuccess(data) {
  return {
    type: CREATE_WORKPLAN_SUCCESS,
    workplan: data,
  };
}

const createWorkPlanFailure = (error) => ({
  type: WORKPLAN_ERROR,
  error: error,
});

function createWorkPlanDocRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocFailure = (error) => ({
  type: WORKPLAN_DOC_ERROR,
  error: error,
});

function createWorkPlanDocTwoRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_TWO_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocTwoSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_TWO_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocTwoFailure = (error) => ({
  type: WORKPLAN_DOC_TWO_ERROR,
  error: error,
});

function createWorkPlanDocThreeRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_THREE_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocThreeSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_THREE_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocThreeFailure = (error) => ({
  type: WORKPLAN_DOC_THREE_ERROR,
  error: error,
});

function createWorkPlanDocFourRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_FOUR_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocFourSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_FOUR_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocFourFailure = (error) => ({
  type: WORKPLAN_DOC_FOUR_ERROR,
  error: error,
});

function createWorkPlanDocFiveRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_FIVE_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocFiveSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_FIVE_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocFiveFailure = (error) => ({
  type: WORKPLAN_DOC_FIVE_ERROR,
  error: error,
});

function createWorkPlanDocSixRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_SIX_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocSixSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_SIX_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocSixFailure = (error) => ({
  type: WORKPLAN_DOC_SIX_ERROR,
  error: error,
});

function createWorkPlanDocSevenRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_DOC_SEVEN_REQUEST,
    workplandoc: data,
  };
}
function createWorkPlanDocSevenSuccess(data) {
  return {
    type: CREATE_WORKPLAN_DOC_SEVEN_SUCCESS,
    workplandoc: data,
  };
}

const createWorkPlanDocSevenFailure = (error) => ({
  type: WORKPLAN_DOC_SEVEN_ERROR,
  error: error,
});

function createWorkPlanMainGoalRequest(data) {
  return {
    type: RETRIVE_WORKPLAN_MAIN_GOAL_REQUEST,
    workplanMainGoal: data,
  };
}
function createWorkPlanMainGoalSuccess(data) {
  return {
    type: CREATE_WORKPLAN_MAIN_GOAL_SUCCESS,
    workplanMainGoal: data,
  };
}
// const createWorkPlanMainGoalFailure = (error) => ({
//   type: WORKPLAN_ERROR,
//   error: error,
// });

const fetchWorkPlanDataStart = () => {
  return {
    type: FETCH_WORKPLAN_DATA_START,
  };
};

const fetchWorkPlanDataSuccess = (workplanData) => {
  return {
    type: FETCH_WORKPLAN_DATA_SUCCESS,
    workplanData: workplanData,
  };
};

const fetchWorkPlanDataFail = (error) => {
  return {
    type: FETCH_WORKPLAN_DATA_FAIL,
    error: error,
  };
};

// fetch work plan data from server
export const fetchWorkPlanData = () => {
  return (dispatch) => {
    dispatch(fetchWorkPlanDataStart());
    return axios
      .get(`${process.env.REACT_APP_API_URL}/sp_work_plan/`)
      .then((res) => {
        dispatch(fetchWorkPlanDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchWorkPlanDataFail(error));
        throw error;
      });
  };
};

export const refreshWorkPlanData = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/sp_work_plan/`)
      .then((res) => {
        dispatch(fetchWorkPlanDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchWorkPlanDataFail(error));
        throw error;
      });
  };
};

export function createWorkPlan(workplan, successMethod, errorMethod) {
  return (dispatch) => {
    dispatch(createWorkPlanRequest(workplan));
    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplans/`,
      workplan,
      (resp) => {
        dispatch(createWorkPlanSuccess(resp));
        let wp_id = localStorage.getItem("workplan_id");
        var textt = "";
        if (wp_id) {
          textt = "Work plan updated sucsessfully";
        } else {
          textt = "Work plan created sucsessfully";
        }
        swal({
          text: textt,
          icon: "success",
          buttons: false,
          timer: 2000,
        }).then(() => {
          dispatch(workplanIsloading());
        });
        if (resp.id) {
          localStorage.setItem("workplan_id", resp.id);
        }
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}
export function createWorkPlanDocOne(workplandoc, successMethod, errorMethod) {
  let docFormOne = new FormData();
  docFormOne.append("workplan", workplandoc.workplan);
  docFormOne.append("document_type", workplandoc.document_type);
  docFormOne.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocRequest(workplandoc));
    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormOne,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating workplan doc one: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function createWorkPlanDocTwo(workplandoc, successMethod, errorMethod) {
  let docFormTwo = new FormData();
  docFormTwo.append("workplan", workplandoc.workplan);
  docFormTwo.append("document_type", workplandoc.document_type);
  docFormTwo.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocTwoRequest(workplandoc));
    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormTwo,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocTwoSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan doc two: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function createWorkPlanDocThree(
  workplandoc,
  successMethod,
  errorMethod
) {
  let docFormThree = new FormData();
  docFormThree.append("workplan", workplandoc.workplan);
  docFormThree.append("document_type", workplandoc.document_type);
  docFormThree.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocThreeRequest(workplandoc));
    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormThree,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocThreeSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan doc three: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function createWorkPlanDocFour(workplandoc, successMethod, errorMethod) {
  let docFormFour = new FormData();
  docFormFour.append("workplan", workplandoc.workplan);
  docFormFour.append("document_type", workplandoc.document_type);
  docFormFour.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocFourRequest(workplandoc));

    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormFour,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocFourSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan doc four: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function createWorkPlanDocFive(workplandoc, successMethod, errorMethod) {
  let docFormFive = new FormData();
  docFormFive.append("workplan", workplandoc.workplan);
  docFormFive.append("document_type", workplandoc.document_type);
  docFormFive.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocFiveRequest(workplandoc));

    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormFive,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocFiveSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan doc five: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function createWorkPlanDocSix(workplandoc, successMethod, errorMethod) {
  let docFormSix = new FormData();
  docFormSix.append("workplan", workplandoc.workplan);
  docFormSix.append("document_type", workplandoc.document_type);
  docFormSix.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocSixRequest(workplandoc));

    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormSix,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocSixSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan doc six: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function createWorkPlanDocSeven(
  workplandoc,
  successMethod,
  errorMethod
) {
  let docFormSeven = new FormData();
  docFormSeven.append("workplan", workplandoc.workplan);
  docFormSeven.append("document_type", workplandoc.document_type);
  docFormSeven.append("document", workplandoc.document);
  return (dispatch) => {
    dispatch(createWorkPlanDocSevenRequest(workplandoc));

    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-docs/`,
      docFormSeven,
      (resp) => {
        swal({
          text: "Document uploaded!",
          buttons: false,
          timer: 2000,
        });
        dispatch(createWorkPlanDocSevenSuccess(resp));
        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        console.error("Error creating work plan doc seven: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}

export function mainGoalsAndPriority(
  workplanMainGoal,
  successMethod,
  errorMethod
) {
  return (dispatch) => {
    dispatch(createWorkPlanMainGoalRequest(workplanMainGoal));
    return HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-main-goals/`,
      workplanMainGoal,
      (resp) => {
        dispatch(createWorkPlanMainGoalSuccess(resp));
        swal({
          text: "Data saved sucsessfully",
          buttons: false,
          timer: 2000,
        });

        if (successMethod) {
          successMethod(resp);
        }
      },
      (err) => {
        swal({
          text: "Please create/update a workplan first!",
        });

        console.error("Error creating work plan main goal: ", err);
        dispatch({
          type: "WORKPLAN_ERROR",
          message: err.message,
        });
        if (errorMethod) {
          errorMethod(JSON.stringify(err.message));
        }
      }
    );
  };
}
